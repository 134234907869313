exports.getDivision = (grades, totalGrades) => {
	console.log("Grading = ", grades);
	let division = "X";
	// grades.map((gr) => {});
	if (grades.length >= 4) {
		if (totalGrades >= 4 && totalGrades <= 12) division = "I";
		else if (totalGrades >= 13 && totalGrades <= 24) division = "II";
		else if (totalGrades >= 25 && totalGrades <= 32) division = "III";
		else if (totalGrades >= 33 && totalGrades <= 35) division = "IV";
		else division = "U";
	}
	return division;
};
