import React, { useContext, useEffect, useState } from "react";
import "./css/teacher-reg.css";
import "./css/sm-popup.css";
import { CameraIcon, XIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
// import { selectClasses } from "../redux/slice";
import { failedAlert, loader, successAlert } from "./vanilla/Dialogues";
import { GlobalContext } from "../GlobalContext";

function RegTeacher(props) {
	// const token = localStorage.getItem("token");
	const [streams, setStreams] = useState([]);
	// const classes = useSelector(selectClasses);
	const { token, classes, myAxios, myAccount, schoolData, api_route } = useContext(GlobalContext);
	const initialState = {
		id: 0,
		fname: "",
		lname: "",
		email: "",
		phone: "",
		gender: "MALE",
		streamId: 0,
		classId: 0,
	};
	const [inputs, setInputs] = useState(initialState);
	const formHandler = (event) => {
		setInputs((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
		// console.log(inputs);
	};
	const saveTeacherRecord = (event) => {
		event.preventDefault();
		loader(1);
		myAxios
			.post("/teacher/register", inputs)
			.then((response) => {
				loader();
				let data = response.data;
				if (data.error) failedAlert(data.error);
				else {
					successAlert(data.msg);
					setInputs(initialState);
				}
			})
			.catch((error) => {
				loader();
				console.log(error);
				let data = error.response.data;
				if (data.error) failedAlert(data.error);
				else failedAlert("Request failed. Please try again.");
			});
	};
	useEffect(() => {
		// console.log(inputs);
		if (inputs.classId == 0) {
			setStreams([]);
			setInputs((prev) => ({ ...prev, streamId: 0 }));
			return;
		}
		for (let x = 0; x < classes.length; x++) if (classes[x].id == inputs.classId) setStreams(classes[x].streams);
		// loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		// myAxios
		// 	.post("/marksheets/classes/getStreams", { id: inputs.classId })
		// 	.then((response) => {
		// 		// console.log("Streams", response.data);
		// 		setStreams(response.data);
		// 		loader();
		// 		// if (!isNaN(inputs["id"])) successAlert(response.msg);
		// 	})
		// 	.catch((error) => {
		// 		loader();
		// 	});
	}, [inputs.classId]);
	useEffect(() => {
		let x = props.selectedRecord;
		// console.log("record", x);
		if (x === null) return;
		setInputs({
			id: x.id,
			fname: x.fname,
			lname: x.lname,
			email: x.email,
			phone: x.phone,
			gender: x.gender,
			streamId: x.streamId,
			classId: x.classId,
		});
	}, [props.selectedRecord]);
	return (
		<div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
			<div className={`relative popup-body w-[500px]`}>
				<form method="post" onSubmit={saveTeacherRecord} className="bg-white flex flex-col rounded-lg p-5 w-[500px] relative">
					<input type="hidden" value={inputs.id} name="id" />
					<XIcon
						className="absolute top-2 right-2 w-6 cursor-pointer text-red-600 bg-red-200 rounded-full border-red-400 p-1"
						onClick={() => {
							props.setTeacherPopup(false);
							setInputs(initialState);
						}}
					/>
					{schoolData?.logo && (
						<div className="relative flex items-center justify-center bg-[#fff] w-14 h-14 rounded-full overflow-hidden mx-auto">
							<img src={schoolData?.logo == null ? `${api_route}/images/icon-100.png` : `${api_route}/images/${schoolData?.logo}`} className="object-contain h-full w-full" />
						</div>
					)}
					<p className="text-center text-lg mb-2 font-semibold">{myAccount.schoolType == "university" ? "Staff Reg. Form" : "Teacher Registration"}</p>
					<div className="flex items-center gap-4">
						<div className="w-1/2">
							<p className="font-semibold">First Name</p>
							<input type="text" name="fname" className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1" onChange={formHandler} value={inputs.fname} required />
						</div>
						<div className="w-1/2">
							<p className="font-semibold">Other Name (s)</p>
							<input name="lname" type="text" className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1" onChange={formHandler} value={inputs.lname} required />
						</div>
					</div>
					<div className="flex items-center gap-4 mt-3">
						<div className="w-1/3">
							<p className="font-semibold">Email</p>
							<input name="email" type="text" className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1" onChange={formHandler} value={inputs["email"]} required />
						</div>
						<div className="w-1/3">
							<p className="font-semibold">Phone</p>
							<input name="phone" type="text" className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1" onChange={formHandler} value={inputs["phone"]} required />
						</div>
						<div className="w-1/3">
							<p className="font-semibold">Gender</p>
							<select name="gender" className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1" value={inputs.gender} onChange={formHandler}>
								<option value="MALE">Male</option>
								<option value="FEMALE">Female</option>
							</select>
						</div>
					</div>
					<div className="flex flex-wrap mt-4 border-t-[1px] border-t-[#eee]">
						<div className="flex flex-col flex-wrap mr-2">
							<p className="w-full text-md font-semibold mt-3">{myAccount.schoolType == "university" ? "Faculty Head" : "Class teacher"}</p>
							<div>
								<select
									name="classId"
									value={inputs.classId !== null ? inputs.classId : 0}
									onChange={(event) => {
										let str = [];
										for (let x = 0; x < classes.length; x++) if (classes[x].id == event.target.value) str = classes[x].streams;
										console.log("selected streams = ", str);
										setStreams(str);
										formHandler(event);
									}}
									className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] rounded-md mt-1 w-36 cursor-pointer"
								>
									<option value={0}>Select class</option>
									{classes.map((obj, key) => (
										<option key={`class-${obj.id}`} value={obj.id} title={obj.class}>
											{obj.alias}
										</option>
									))}
								</select>
							</div>
							<div className="flex items-center flex-wrap">
								{streams.map((obj, key) => (
									<label key={`stream-${obj.id}`} className={`custom-radio flex flex-row-reverse items-center px-3 py-2 font-semibold text-[#777]`}>
										<input
											type="radio"
											name="streamId"
											value={obj.id}
											onChange={formHandler}
											{...(inputs.streamId === obj.id && {
												checked: true,
											})}
										/>
										<p className="mr-auto">
											{obj.stream} ({obj.alias})
										</p>
										<span></span>
									</label>
								))}
							</div>
						</div>
						{myAccount.schoolType == "university" && (
							<div className="flex flex-col flex-wrap">
								<p className="w-full text-md font-semibold mt-3">Department Head</p>
								<div>
									<select
										name="classId"
										value={inputs.classId !== null ? inputs.classId : 0}
										onChange={(event) => {
											let str = [];
											for (let x = 0; x < classes.length; x++) if (classes[x].id == event.target.value) str = classes[x].streams;
											console.log("selected streams = ", str);
											setStreams(str);
											formHandler(event);
										}}
										className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] rounded-md mt-1 w-36 cursor-pointer"
									>
										<option value={0}>Select class</option>
										{classes.map((obj, key) => (
											<option key={`class-${obj.id}`} value={obj.id} title={obj.class}>
												{obj.alias}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
					</div>

					<button type="submit" className="bg-[#000] py-2 rounded-sm text-white mt-2 font-semibold">
						SAVE
					</button>
				</form>
			</div>
		</div>
	);
}

export default RegTeacher;
