import React, { useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import {
	customConfirm,
	failedAlert,
	loader,
	successAlert,
} from "./vanilla/Dialogues";

function UserAccountControl() {
	const { myAxios, api_route, accounts } = useContext(GlobalContext);
	console.log("Othe accounts", accounts);
	const changeAccount = (name, type, userId, email) => {
		console.log(email);
		customConfirm("Switch accounts?").then((x) => {
			loader(1);
			myAxios
				.post("/switchAccounts", {
					type,
					userId,
					email,
				})
				.then((response) => {
					loader(0);
					console.log(response.data);

					localStorage.setItem("token", response.data.accessToken);
					localStorage.setItem("user_id", response.data.user_id);
					// setting expiry for this token
					const remainingMilliseconds = 60 * 60 * 1000;
					const expiryDate = new Date(
						new Date().getTime() + remainingMilliseconds
					);
					localStorage.setItem(
						"expiryDate",
						expiryDate.toISOString()
					);

					successAlert(
						`Your account has been switched to <b>${name}</b>`,
						() => {
							loader(1);
							window.location.href = "/home/sm/students";
						}
					);
				})
				.catch((error) => {
					loader(0);
					failedAlert("Could not switch accounts. Please try again.");
				});
		});
	};
	if (accounts.length == 0) return <></>;
	return (
		<>
			<p className="pl-3 py-3 bg-gray-100 mt-3">Other accounts</p>
			<div className="flex flex-col">
				{accounts.map((account) => (
					<div
						onClick={() =>
							changeAccount(
								account.name,
								account.type,
								account.id,
								account.email
							)
						}
						className="flex items-center m-[5px] rounded-sm p-1 hover:bg-gray-100 cursor-pointer"
					>
						<div className="flex items-center">
							<div className="relative flex items-center justify-center rounded-full w-11 h-11 overflow-hidden mr-1 bg-white">
								<img
									className="min-w-full min-h-full object-cover"
									src={
										!account.school.logo
											? `${api_route}/images/icon-100.png`
											: `${api_route}/images/${account.school.logo}`
									}
								/>
							</div>
							<div className="flex flex-col">
								<p>{account.name}</p>
								<p className="text-[10px] opacity-50 font-bold truncate">
									{account.school.name.length > 25
										? account.school.name.substring(0, 25) +
										  " ..."
										: account.school.name}
								</p>
								<p className="text-[10px] ">
									<span className="font-bold">Account:</span>{" "}
									{account.type == "admin" ? (
										<span className="text-red-600">
											Administrator
										</span>
									) : (
										<span className="text-red-500">
											Teacher
										</span>
									)}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default UserAccountControl;
