import { PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import {
	customPrompt,
	failedAlert,
	loader,
	successAlert,
} from "../../../components/vanilla/Dialogues";
import { GlobalContext } from "../../../GlobalContext";
import GradingPopup from "./components/GradingPopup";
import { Link, Route, Routes } from "react-router-dom";

function Grading() {
	const { myAxios, token } = useContext(GlobalContext);
	const [grades, setGrades] = useState([]);
	const addGrade = (gradeId = null, text) => {
		let title = !isNaN(gradeId) ? "Edit this title" : "Add new grade";
		let value = !isNaN(gradeId) ? text : "";
		if (isNaN(gradeId)) gradeId = "null";
		customPrompt({ title, value }).then((grade) => {
			console.log(gradeId);
			let object = {
				title: grade,
				id: gradeId,
			};
			console.log(object);
			loader(1);
			myAxios
				.post("/grading/register", object)
				.then((response) => {
					console.log(response.data);
					// let data = response;
					successAlert("Request successful.", getAllGrades);
				})
				.catch((error) => {
					console.log(error);
					failedAlert("Request failed. Please try again.");
				})
				.finally((x) => {
					// console.log("Final = ", x);
					loader(0);
				});
		});
	};
	const getAllGrades = () => {
		loader(1);
		myAxios
			.get("/grading")
			.then((response) => {
				let data = response.data.data;
				setGrades(data);
				console.log(data);
			})
			.catch((error) => {
				failedAlert("Request failed. Please try again.");
			})
			.finally((x) => {
				loader(0);
			});
	};
	useEffect(() => {
		getAllGrades();
	}, []);
	return (
		<div className="h-full flex items-center justify-center overflow-auto">
			<div className="w-full mx-auto max-w-[650px]">
				<div className="flex items-center mb-2">
					<PlusIcon
						onClick={addGrade}
						className="p-2 w-8 h-8 bg-green-100 text-green-800 rounded-full cursor-pointer mr-3"
					/>
					<p className="text-xl py-2">Grading</p>
				</div>
				<div className="flex items-center flex-wrap">
					{grades.map((grade, index) => (
						<div
							className="w-[200px]"
							key={"grading-title-" + index}
						>
							<div className="bg-gray-50 mr-2 mb-2 rounded-md shadow-md shadow-gray-200 flex items-center overflow-hidden">
								<p
									onClick={() => {
										addGrade(grade.id, grade.title);
									}}
									className="py-2 pl-3 cursor-pointer flex-grow font-semibold text-gray-500"
								>
									{grade.title}
								</p>
								<Link to={`${grade.id}`}>
									<PencilAltIcon className="w-6 h-6 bg-gray-200 text-gray-500 rounded-full p-[5px] cursor-pointer mx-3 flex-shrink-0" />
								</Link>
							</div>
						</div>
					))}
				</div>
			</div>
			<Routes>
				<Route path=":id" element={<GradingPopup />} />
			</Routes>
		</div>
	);
}

export default Grading;
