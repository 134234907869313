import React from "react";

function Header2({ about, student, api_route }) {
	return (
		<div className="flex items-center z-30 relative">
			<div className="w-[80px] m-[20px] mr-[10px] flex justify-center items-center overflow-hidden">
				<img
					src={`${api_route}/images/${about.logo}`}
					className="object-fill"
				/>
			</div>
			<div className="flex flex-col flex-grow text-center">
				<p className="hidden">السلام معك ومع عائلتك</p>
				<p className="text-[24px] font-bold">{about.name}</p>
				<p className="">
					TEL: {about.phonecontact}, Email:
					{about.email}
				</p>
			</div>
			<div className="w-[80px] h-[100px] m-[20px] ml-[20px] flex justify-center items-center overflow-hidden">
				<img
					src={`${api_route}/images/${
						student?.picture ? student.picture : "icon-100.png"
					}`}
					className="object-fill"
				/>
			</div>
		</div>
	);
}

export default Header2;
