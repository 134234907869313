import React from "react";
import "./css/custom-radio.css";

function RadioButton({ className, name, value, text, selected, changeRadio }) {
    return (
        <label
            className={`custom-radio flex flex-row-reverse items-center px-3 py-2 ${className}`}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={`${selected === true ? 1 : 0}`}
                onChange={(event) => changeRadio(event.target.value)}
            />
            <p className="mr-auto">{text}</p>
            <span></span>
        </label>
    );
}

export default RadioButton;
