import React from "react";

function PrivacyPolicy() {
	return (
		<div className="max-h-screen overflow-y-auto">
			<div className="flex flex-col text-[#2f2f2f] max-w-[1000px] mx-auto px-7 py-20">
				<p className="text-2xl font-bold text-black">Schoolware Privacy Policy</p>
				<p className="mt-3 text-xs">Last Updated: 3rd November 2024</p>
				<p className="my-3 text-xl font-bold text-black">Introduction</p>
				As Schoolware, we value the privacy of our users ("you" or "your") and is committed to protecting the information we collect, use, and share about students, educators, and other users. This Privacy Policy explains how we collect, use, share, and safeguard information when you use our services.
				<p>By using Schoolware, you agree to the collection and use of information as outlined in this Privacy Policy.</p>
				<p className="text-xl font-bold text-black mt-2">1. Information We Collect</p>
				<p>
					1.1. <u>Personal Information.</u> We collect personal information directly from users, including: School and Student Information: Names, addresses, contact information, and other details to uniquely identify schools, students, staff, and parents/guardians. User Account Information: Name, email address, role (e.g., teacher, administrator), and login credentials for those accessing the system.
				</p>
				<p>
					1.2. <u>Financial and Academic Data.</u> To support school operations, we may collect: Financial Information: Tuition fees, payment history, and transaction details. Academic Information: Student grades, attendance records, performance data, and other academic records necessary for school operations.
				</p>
				<p>
					1.3. <u>Usage Data.</u> We automatically collect information on how the platform is accessed and used, including: Device Information: Device type, operating system, and unique device identifiers. Log Data: IP addresses, browser type, the pages visited on our service, and time spent on those pages.
				</p>
				<p className="text-xl font-bold text-black mt-2">2. How We Use Information.</p>
				<p>We use the information collected to: Facilitate school record-keeping and management of academic performance. Manage financial records, including tuition, fees, and payments. Enable secure access and maintain account functionality for users. Improve, personalize, and expand our services based on user needs and feedback. Analyze usage to enhance system security and performance.</p>
				<p className="text-xl font-bold text-black mt-2">3. Sharing and Disclosure of Information.</p>
				<p>We do not sell or share personal information for marketing purposes. We may share information in the following circumstances: With Schools and Educators: To ensure teachers, administrators, and authorized users have the data necessary for educational purposes. Service Providers: With trusted third-party service providers who perform services on our behalf, such as data storage, technical support, and payment processing, with strict confidentiality obligations. Legal Compliance: When required by law, regulation, or legal request, or to protect the rights, property, and safety of users or others.</p>
				<p className="text-xl font-bold text-black mt-2">4. Data Security.</p>
				<p>We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of personal information. However, please note that no electronic transmission or storage method is 100% secure, and we cannot guarantee absolute security.</p>
				<p className="text-xl font-bold text-black mt-2">5. Data Retention.</p>
				<p>We retain personal information for as long as it is necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. When data is no longer needed, we will securely delete or anonymize it.</p>
				<p className="text-xl font-bold text-black mt-2">6. User Rights and Choices.</p>
				<p>Depending on your location, you may have certain rights regarding your personal information, including: Access, correction, or deletion of personal information. Limiting our use of your information. Withdrawing consent, where processing is based on consent. To exercise any of these rights, please contact us using the details in the "Contact Us" section.</p>
				<p className="text-xl font-bold text-black mt-2">7. Children’s Privacy.</p>
				<p>Our services are designed to be used under the supervision of educational institutions. We rely on schools to obtain the necessary permissions for processing students' personal data, as required by applicable law.</p>
				<p className="text-xl font-bold text-black mt-2">8. International Data Transfers.</p>
				<p>Our services may be accessible from outside your country, and information may be stored or processed internationally, depending on our service providers' locations. We ensure compliance with relevant laws when transferring data internationally.</p>
				<p className="text-xl font-bold text-black mt-2">9. Changes to This Privacy Policy.</p>
				<p>We may update our Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify users of significant changes and encourage you to review this policy periodically.</p>
				<p className="text-xl font-bold text-black mt-4">10. Contact Us.</p>
				<p>
					For any questions or concerns regarding this Privacy Policy, please contact us at:<br></br> Email: neuralnetuganda@gmail.com<br></br> Phone: +256700778767
				</p>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
