import {
	ArrowDownIcon,
	ArrowRightIcon,
	ArrowUpIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	EyeIcon,
	EyeOffIcon,
	PencilIcon,
	PlusCircleIcon,
	SearchIcon,
	UserAddIcon,
	UserCircleIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NewStudent from "../../components/NewStudent";
import {
	customConfirm,
	loader,
	successAlert,
} from "../../components/vanilla/Dialogues";
// import { selectClasses, selectSubjects } from "../../redux/slice";
import { GlobalContext } from "../../GlobalContext";
import StudentDataUploadPreview from "../../components/StudentDataUploadPreview";
import StudentDownloadOptionPopup from "../../components/students/studentDownloadOptionPopup";

function Students(props) {
	const token = localStorage.getItem("token");
	// const [selectedClass, setSelectedClass] = useState(nu
	// const x = useSelector(selectClasses);
	// console.log(x);

	// const [subjects, setSubjects] = useState(useSelector(selectSubjects));
	const [showDownloadDialogue, setShowDownloadDialogue] = useState(false);
	const [uploadedExcelFile, setUploadedExcelFile] = useState(null);
	const [showBox, setShowBox] = useState(false);
	const [previewData, setPreviewData] = useState([]);
	const [students, setStudents] = useState([]);
	const [streams, setStreams] = useState([]);
	const [selectedClass, setSelectedClass] = useState(null);
	const {
		api_route,
		myAxios,
		subjects,
		setSubjects,
		classes,
		setClasses,
		schoolData,
		myAccount,
	} = useContext(GlobalContext);

	const [inputs, setInputs] = useState({
		query: "",
		classId: classes && classes.length ? classes[0].id : 0,
		streamId: 0,
		status: "1",
	});

	const [popup, setPopup] = useState(false);

	const [selectedStudent, setSelectedStudent] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const studentLimit = 60;

	useEffect(() => {
		console.log("Students", students);
	}, [popup, students]);

	const handleForm = (event) => {
		setInputs((data) => ({
			...data,
			[event.target.name]: event.target.value,
		}));
	};

	const getStudents = (event) => {
		event && event.preventDefault();
		setPageCount(1);
		setTotalPages(1);
		console.log("Inputs: ", inputs);
		if (inputs.classId == 0) return;
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/students/find", inputs)
			.then((response) => {
				let data = response.data;
				// console.log("Streams", response);
				// successAlert();
				loader();
				setStudents(data);
				if (data.length > studentLimit)
					setTotalPages(Math.ceil(data.length / studentLimit));
				else if (data.length == 0) setTotalPages(0);
			})
			.catch((error) => {
				console.log(error);
				loader();
			});
	};

	useEffect(() => {
		setPageCount(totalPages == 0 ? 0 : 1);
	}, [totalPages]);
	useEffect(() => {
		if (classes && classes.length) {
			setSelectedClass(classes[0].id);
			setInputs((prev) => ({ ...prev, classId: classes[0].id }));
			// setTimeout(() => {
			// 	console.log("Classes: ", classes, classes[0].id);
			// }, 3000);
			getStudents();
		}
	}, [classes]);

	const deactivateStudent = async (studentId, classId) => {
		customConfirm("Are you sure you want to deactivate this student?").then(
			() => {
				loader(1);
				// const url = `${process.env.REACT_APP_API_URL}`;
				myAxios
					.post("/students/deactivate", { classId, studentId })
					.then((response) => {
						let data = response.data;
						// console.log("Streams", data);
						// successAlert();
						successAlert(data.msg, () => {
							document.getElementById("search").click();
						});
						loader();
						// setStudents(data);
					})
					.catch((error) => {
						console.log(error);
						loader();
					});
			}
		);
	};
	// console.log("classes = " + typeof classes);
	const triggerSheetUpload = () => {
		let input = document.createElement("input");
		input.type = "file";
		input.setAttribute("accept", ".xlsx, .xls");
		input.onchange = function () {
			loader(1);
			// console.log(this);
			let file = this.files[0];
			let formData = new FormData();
			formData.append("tmp-file", file);
			myAxios
				.post("/students/uploadStudentList", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					// console.log("data = ", response.data.returnData);
					setUploadedExcelFile(response.data.data);
					setPreviewData(response.data.returnData);
					setShowBox(true);
				})
				.catch((error) => {
					console.log("Error ", error);
				})
				.finally((f) => {
					loader(0);
				});
		};
		input.click();
	};
	const downloadClass = () => {
		console.log(inputs.classId);
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL`};
		myAxios
			.get(`sheet/downloadClass?classId=${inputs.classId}&token=${token}`)
			.then((response) => {
				let data = response.data;
				// console.log("Streams", data);
				// successAlert();
				successAlert(data.msg);
				loader();
				// setStudents(data);
			})
			.catch((error) => {
				console.log(error);
				loader();
			});
		// alert(selectedClass);
	};

	const increasePage = (increment) => {
		if (increment < 0 && pageCount <= 1) return;
		if (increment > 0 && pageCount >= totalPages) return;
		setPageCount((prev) => prev + increment);
	};

	const closeDownloadPopup = () => {};
	return (
		<div className="h-full">
			<StudentDownloadOptionPopup
				closeDownloadPopup={closeDownloadPopup}
				showDownloadDialogue={showDownloadDialogue}
				setShowDownloadDialogue={setShowDownloadDialogue}
			/>
			<NewStudent
				stateChanger={setPopup}
				showWindow={popup}
				selectedStudent={selectedStudent}
				setSelectedStudent={setSelectedStudent}
				getStudents={getStudents}
			/>
			<StudentDataUploadPreview
				showBox={showBox}
				setShowBox={setShowBox}
				previewData={previewData}
				uploadedExcelFile={uploadedExcelFile}
				setPreviewData={setPreviewData}
			/>
			<div className="wd mx-auto h-full">
				<div className="flex-col flex h-full">
					<div className="flex items-center py-2 pl-2">
						<div
							onClick={() => setShowDownloadDialogue(true)}
							className="bg-green-50 rounded-md text-xs font-semibold py-[5px] px-2 flex items-center cursor-pointer mr-2"
							style={{ userSelect: "none" }}
						>
							<ArrowDownIcon className="w-5 h-5 bg-green-200 text-green-600 p-[4px] rounded-full mr-2" />
							Download sheet
						</div>
						{myAccount.type == "admin" && (
							<div
								className="bg-green-50 rounded-md text-xs font-semibold py-[5px] px-2 flex items-center cursor-pointer"
								style={{ userSelect: "none" }}
								onClick={triggerSheetUpload}
							>
								<ArrowUpIcon className="w-5 h-5 bg-green-200 text-green-600 p-[4px] rounded-full mr-2" />
								<span>Upload list</span>
							</div>
						)}
						<form
							onSubmit={getStudents}
							className="flex items-center bg-[#eee] ml-auto mr-2 rounded-full overflow-hidden"
						>
							<input
								placeholder="Search record ..."
								className="py-2 bg-transparent px-3"
								style={{ width: "180px" }}
								name="query"
								value={inputs.query}
								onChange={handleForm}
							/>
							<select
								name="classId"
								className="py-2 px-1 cursor-pointer bg-transparent max-w-[70px] text-[11px]"
								value={inputs.classId}
								onChange={handleForm}
							>
								<option value={0}>Class</option>
								{classes ? (
									classes.map((obj, index) => (
										<option
											key={"class" + index}
											value={obj.id}
											{...(selectedClass == obj.id && {
												selected: true,
											})}
										>
											{obj.alias}
										</option>
									))
								) : (
									<></>
								)}
							</select>
							<select
								name="streamId"
								className="py-2 px-1 cursor-pointer bg-transparent max-w-[50px] text-[11px]"
								value={inputs.streamId}
								onChange={handleForm}
							>
								<option value={0}>Stream</option>
								{inputs.classId > 0 ? (
									classes.map((obj, index) =>
										obj.id == inputs.classId ? (
											obj.streams.map((str, id) => (
												<option key={id} value={str.id}>
													{str.stream}
												</option>
											))
										) : (
											<></>
										)
									)
								) : (
									<></>
								)}
							</select>
							<select
								name="status"
								className="py-2 px-1 cursor-pointer bg-transparent max-w-[80px] text-[11px]"
								value={inputs.status}
								onChange={handleForm}
							>
								<option value="1">ACTIVE</option>
								<option value="0">INACTIVE</option>
							</select>
							<button type="submit" id="search">
								<ArrowRightIcon className="bg-black text-white w-7 p-2 mr-1 rounded-full" />
							</button>
						</form>
						{myAccount.type == "admin" && (
							<div
								className="flex items-center cursor-pointer mr-2 text-[#1f6446] font-semibold"
								onClick={() => {
									setSelectedStudent(null);
									setPopup(true);
								}}
							>
								<UserCircleIcon className="text-[#188556] bg-[#d2ffeb] p-[5px] w-[30px] rounded-full mr-2" />
								New student
							</div>
						)}
					</div>
					<div className="flex-grow overflow-x-hidden overflow-y-auto">
						<table className="table-light w-full text-[12px]">
							<tbody>
								<tr className="thead text-left select-none sticky top-0">
									<th>ID</th>
									<th>FIRST NAME</th>
									<th>OTHER NAMES</th>
									<th>FULL NAME</th>
									<th>CLASS</th>
									<th>STREAM</th>
									<th>GENDER</th>
									{myAccount.type == "admin" && (
										<>
											<th className="w-[15px]"></th>
											<th className="w-[15px]"></th>
										</>
									)}
								</tr>
								{students &&
									students.map((obj, index) => {
										if (
											index >=
												(pageCount - 1) *
													studentLimit &&
											index <=
												(pageCount - 1) * studentLimit -
													1 +
													studentLimit
										)
											return (
												<tr
													key={index}
													data-obj={JSON.stringify(
														obj
													)}
												>
													<td>{obj.id}</td>
													<td>{obj.firstName}</td>
													<td>{obj.otherName}</td>
													<td>{obj.fullName}</td>
													<td>{obj?.class?.alias}</td>
													<td>
														{obj?.stream?.stream}
													</td>
													<td>{obj.gender}</td>
													{myAccount.type ==
														"admin" && (
														<>
															<td className="relative">
																<PencilIcon className="text-[#1e754f] bg-[#c0f7df] rounded-full w-5 p-1 border-[1px] border-[#53c292]" />
																<div
																	className="inset-0 absolute"
																	onClick={(
																		event
																	) => {
																		let retrievedUser =
																			event
																				.target
																				.parentNode
																				.parentNode
																				.dataset
																				.obj;
																		setSelectedStudent(
																			retrievedUser
																		);
																		setPopup(
																			true
																		);
																	}}
																></div>
															</td>
															<td className="relative">
																{obj.active ==
																0 ? (
																	<EyeOffIcon className="text-black rounded-full w-5 p-[2px]" />
																) : (
																	<EyeIcon className="text-black rounded-full w-5 p-[2px]" />
																)}
																{/* <EyeIcon className="text-black rounded-full w-5 p-[2px]" /> */}
																<div
																	className="inset-0 absolute"
																	onClick={(
																		event
																	) => {
																		let obj =
																			JSON.parse(
																				event
																					.target
																					.parentNode
																					.parentNode
																					.dataset
																					.obj
																			);
																		deactivateStudent(
																			obj.id,
																			obj.classId
																		);
																	}}
																></div>
															</td>
														</>
													)}
												</tr>
											);
									})}
							</tbody>
						</table>
					</div>
					<div className="flex items-center p-2 bg-gray-100 border-t-[1px] border-t-[#ccc] select-none">
						<div className="font-semibold">
							Showing{" "}
							<span className="text-red-500 font-bold">
								{students.length}
							</span>{" "}
							students
						</div>
						<ChevronLeftIcon
							onClick={() => increasePage(-1)}
							className="w-5 h-5 bg-black rounded-full p-1 cursor-pointer text-white ml-auto"
						/>
						<span className="mx-4">
							{pageCount} / {totalPages}
						</span>
						<ChevronRightIcon
							onClick={() => increasePage(1)}
							className="w-5 h-5 bg-black rounded-full p-1 cursor-pointer text-white"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Students;
