import styles from "../styles/Home.module.css";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faHomeAlt, faBookAtlas, faEarthAfrica } from "@fortawesome/free-solid-svg-icons";
import { HomeIcon, FolderIcon, CurrencyDollarIcon, CogIcon, LogoutIcon, ClockIcon } from "@heroicons/react/outline";

import { Link, BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import StudentMan from "./Students";
import FileManager from "./FileManager";
import Elearning from "./Elearning";
import Finance from "./Finance";
import Settings from "./Settings";
import axios from "axios";
import { failedAlert, loader } from "../components/vanilla/Dialogues";
import { selectCls, selectSubjects, setClasses, setSchooldata } from "../redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../GlobalContext";
import UserAccounts from "../components/UserAccountControl";
import UserAccountControl from "../components/UserAccountControl";
import TermChanger from "../components/TermChanger";
import SemesterChanger from "../components/SemesterChanger";

export default function Home() {
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const { subjects, setSubjects, classes, setClasses, schoolData, setSchoolData, loadData, myAccount, reportMode } = useContext(GlobalContext);
	// const active_subpage = "Home";
	// let classes = useSelector(selectCls);
	// let subjects = useSelector(selectSubjects);
	let token = localStorage.getItem("token");

	useEffect(() => {
		loadData();
	}, []);
	// console.log(token);
	if (!token) {
		window.location.href = "/";
		return;
	}
	return (
		<div className="min-h-full">
			{myAccount.schoolType == "university" ? <SemesterChanger /> : <TermChanger />}
			<div className="h-screen w-full justify-center items-center hidden">
				<p>Loading ...</p>
			</div>
			<main className={`${reportMode ? "min-h-screen overflow-y-auto" : "h-screen overflow-hidden"} flex`}>
				{/* class will change when printing reports */}
				<div className="w-[250px] border-r border-gray-200 flex flex-col flex-shrink-0">
					<div className="flex items-center border-b border-gray-200 p-[6px]">
						<img src="/icon-100.png" className="w-[35px]" />
						<div className="flex flex-col leading-0 text-sm">
							<p className="font-bold">Schoolware</p>
							<p className="text-[10px] text-gray-500" style={{ marginTop: "-10px" }}>
								Your school software
							</p>
						</div>
					</div>
					<div className="flex items-center border-b border-gray-200 p-[6px] bg-[#0009] text-white font-semibold">{myAccount.name} (Logged in)</div>
					<div className="flex flex-col flex-grow overflow-x-hidden overflow-y-auto">
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<HomeIcon className="text-gray-700 w-5 mr-2" />
							<Link to="/home/sm/students" className="block flex-grow" onClick={loadData}>
								Students & teachers
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<FolderIcon className="text-gray-700 w-5 mr-2" />
							<Link to="fm" className="flex-grow flex items-center" onClick={loadData}>
								<span>File management</span>
								<span className="text-green-600 bg-green-50 py-0 px-1 rounded-sm ml-auto flex items-center">
									<ClockIcon className="text-green-500 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<FA icon={faBookAtlas} color="#374151" className="w-4 mr-3" />
							<Link to="el/start" className="flex-grow flex items-center" onClick={loadData}>
								<span>E-lerning</span>
								<span className="text-green-600 bg-green-50 py-0 px-1 rounded-sm ml-auto flex items-center">
									<ClockIcon className="text-green-500 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 items-center hidden hover:bg-[#eee]">
							<FA icon={faEarthAfrica} color="#374151" className="w-4 mr-3" />
							<Link to="/" className="flex-grow flex items-center" onClick={loadData}>
								<span>School Profile</span>
								<span className="text-green-600 bg-green-50 py-0 px-1 rounded-sm ml-auto flex items-center">
									<ClockIcon className="text-green-500 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<CurrencyDollarIcon className="text-gray-700 w-5 mr-2" />
							<Link to="fc/fees" className="flex-grow flex items-center" onClick={loadData}>
								<span>Finance</span>
								<span className="text-green-600 bg-green-50 py-0 px-1 rounded-sm ml-auto flex items-center">
									<ClockIcon className="text-green-500 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<CogIcon className="text-gray-700 w-5 mr-2" />
							<Link to="settings" className="text-xs flex-grow" onClick={loadData}>
								Settings
							</Link>
						</div>
						<div className="p-2 border-b border-gray-100 flex items-center hover:bg-[#eee]">
							<LogoutIcon className="text-red-500 w-5 mr-2" />
							<Link
								to="/"
								className="text-xs flex-grow"
								onClick={(event) => {
									event.preventDefault();
									localStorage.removeItem("token");
									// window.location.href = "/";
									navigate("/", { replace: true });
								}}
							>
								Logout
							</Link>
						</div>
						<UserAccountControl />
					</div>
				</div>
				<div className="flex flex-col flex-grow overflow-hidden">
					<Routes>
						<Route path="/sm/*" element={<StudentMan />} />
						<Route path="/fm/*" element={<FileManager />} />
						<Route path="/el/*" element={<Elearning />} />
						<Route path="/fc/*" element={<Finance />} />
						<Route path="/settings/*" element={<Settings />} />
					</Routes>
				</div>
			</main>
		</div>
	);
}
