import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Classes: [],
    subjects: [],
    schooldata: null,
    userData: {
        name: "",
        userId: null,
        schoolName: "",
        token: "",
    },
};

export const navSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setClasses: (state, action) => {
            state.Classes = action.payload;
        },
        setSubjects: (state, action) => {
            state.subjects = action.payload;
        },
        setSchooldata: (state, action) => {
            state.schooldata = action.payload;
        },
    },
});

export const { setUserData, setClasses, setSubjects, setSchooldata } =
    navSlice.actions;

// console.log(state.nav.userData);
// selectors
export const selectClasses = (state) => state.nav.Classes;
export const selectUserData = (state) => state.nav.userData;
export const selectSubjects = (state) => state.nav.subjects;
export const selectSchooldata = (state) => state.nav.schooldata;

export default navSlice.reducer;
