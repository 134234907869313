import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Classes from "./Marksheets/Classes";
import "./css/marksheets.css";
import Subjects from "./Marksheets/Subjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	AdjustmentsIcon,
	BookOpenIcon,
	DocumentDuplicateIcon,
	TableIcon,
} from "@heroicons/react/outline";
import { faDoorOpen, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import Sheets from "./Marksheets/Sheet";
import Grading from "./Marksheets/Grading";
import { GlobalContext } from "../../GlobalContext";

function Marksheets() {
	const { myAccount } = useContext(GlobalContext);
	const nav = [
		{
			ref: "classes",
			title: "CLASSES",
		},
		{
			ref: "subjects",
			title: "SUBJECTS",
		},
		{
			ref: "grading",
			title: "GRADING",
		},
		{
			ref: "sheets",
			title: "SHEETS",
		},
	];
	const [activeNav, setActiveNav] = useState("CLASSES");

	const handleNav = (event) => {
		setActiveNav(event.target.innerHTML);
	};
	useEffect(() => {}, []);
	return (
		<div className="h-full flex">
			<div className="flex-grow h-full overflow-y-auto">
				<Routes>
					<Route path="classes" element={<Classes />} />
					<Route path="subjects" element={<Subjects />} />
					<Route path="sheets" element={<Sheets />} />
					<Route path="grading/*" element={<Grading />} />
				</Routes>
			</div>
			{myAccount.type == "admin" && (
				<div
					className="flex-shrink-0 px-5 h-full flex align-center flex-col overflow-y-auto"
					style={{ borderLeft: "1px solid #ccc" }}
				>
					<Link
						to="classes"
						onClick={() => setActiveNav("classes")}
						className="flex flex-col mt-10"
					>
						<FontAwesomeIcon
							icon={faDoorOpen}
							className={`w-3 h-3 p-3 rounded-full cursor-pointer mb-1 mx-auto  ${
								activeNav === "classes"
									? "bg-blue-800 text-white"
									: "bg-[#ffe8e8] text-[#b11c1c]"
							}`}
						/>
						<span className="text-[10px] font-semibold mb-6 text-center">
							CLASSES
						</span>
					</Link>
					<Link
						to="subjects"
						onClick={() => setActiveNav("subjects")}
						className={`flex flex-col`}
					>
						<BookOpenIcon
							className={`w-9 p-2 rounded-full cursor-pointer mb-2 mx-auto ${
								activeNav === "subjects"
									? "bg-blue-800 text-white"
									: "bg-[#fde6ff] text-[#5c0064]"
							}`}
						/>
						<span className="text-[10px] font-semibold mb-6 text-center">
							SUBJECTS
						</span>
					</Link>
					<Link
						to="grading"
						onClick={() => setActiveNav("grading")}
						className={`flex flex-col`}
					>
						<DocumentDuplicateIcon
							className={`w-9 p-2 rounded-full cursor-pointer mb-2 mx-auto ${
								activeNav === "grading"
									? "bg-blue-800 text-white"
									: "bg-[#ffede0] text-[#f60]"
							}`}
						/>
						<span className="text-[10px] font-semibold mb-6 text-center">
							GRADING
						</span>
					</Link>
					<Link
						to="sheets"
						onClick={() => setActiveNav("sheets")}
						className={`flex flex-col`}
					>
						<FontAwesomeIcon
							icon={faFileExcel}
							className={`w-3 h-3 p-3 rounded-full cursor-pointer mb-2 mx-auto ${
								activeNav === "sheets"
									? "bg-blue-800 text-white"
									: "bg-[#e4fff3] text-[#1d694a]"
							}`}
						/>
						<span className="text-[10px] font-semibold mb-6 text-center">
							SHEETS
						</span>
					</Link>
				</div>
			)}
		</div>
	);
}

export default Marksheets;
