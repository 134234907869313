import { XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import CheckBox from "./CheckBox";

function TeacherPicker(props) {
    const [teachersList, setTeachersList] = useState([]);
    useEffect(() => {}, [teachersList]);
    return (
        <div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
            <div className={`relative popup-body w-[500px] h-[80vh]`}>
                <XIcon
                    className="bg-[#f7e3e3] text-[#f00] p-1 w-[20px] border-[#fdbcbc] border-[1px] rounded-full absolute top-[-10px] right-[-10px] cursor-pointer"
                    onClick={() => props.setTPopup(false)}
                />
                <div className="px-2 pb-3 flex flex-col h-full">
                    <p className="font-bold text-[#222] mt-2 text-lg pb-2">
                        Teachers
                    </p>
                    <div className="flex-grow overflow-y-auto">
                        <table className="bare-table border-1 w-full table-checked">
                            <thead>
                                <tr>
                                    <th className="w-[20px]"></th>
                                    <th className="relative text-left">NAME</th>
                                    <th className="relative text-left">
                                        GENDER
                                    </th>
                                    <th className="relative text-left">
                                        CLASS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="odd:bg-[#eee]">
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] text-center font-semibold text-[#333] w-[15px]">
                                        <CheckBox name="hello" />
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        MUGWANYA DANIEL
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        Male
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        S.6
                                    </td>
                                </tr>
                                <tr className="odd:bg-[#eee]">
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] text-center font-semibold text-[#333] w-[15px]">
                                        <CheckBox name="hello" />
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        MUGWANYA DANIEL
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        Male
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        S.6
                                    </td>
                                </tr>
                                <tr className="odd:bg-[#eee]">
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] text-center font-semibold text-[#333] w-[15px]">
                                        <CheckBox name="hello" />
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        MUGWANYA DANIEL
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        Male
                                    </td>
                                    <td className="odd:bg-transparent even:bg-[#e4e4e4] font-semibold text-[#333]">
                                        S.6
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherPicker;
