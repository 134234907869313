import { ArrowRightIcon, XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import "./css/sm-popup.css";
import { failedAlert } from "./vanilla/Dialogues";

function TimeInterval(props) {
    const convertTo12Hour = (time24) => {
        // Split the input string into hours and minutes
        var timeParts = time24.split(":");
        var hours = parseInt(timeParts[0]);
        var minutes = timeParts[1];

        // Determine whether the time is AM or PM
        var ampm = hours >= 12 ? "PM" : "AM";

        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // handle midnight (00:00) and noon (12:00)

        // Construct the output string
        var time12 = hours + ":" + minutes + " " + ampm;

        return time12;
    };
    // console.log(props);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    // useEffect(() => {
    //     console.log(startTime, endTime);
    // }, [startTime, endTime]);
    const submitInterval = () => {
        if (startTime.length === 0) failedAlert("Please select start time");
        else if (endTime.length === 0)
            failedAlert("Please select the end time");
        else {
            // interval has been selected
            props.onchange(
                `${convertTo12Hour(startTime)} - ${convertTo12Hour(endTime)}`
            );
            props.changeStatus(false);
            setStartTime("");
            setEndTime("");
        }
    };

    return (
        <div className={`popup-sm ${props.show ? "show" : ""}`}>
            <div className={`relative popup-body w-[260px]`}>
                <XIcon
                    onClick={() => props.changeStatus(false)}
                    className="bg-[#ffe7e7] text-[#f00] w-[25px] p-1 absolute top-[5px] cursor-pointer right-[5px] rounded-full"
                />
                <p className="pt-2 px-3 font-bold text-lg text-[14px] text-center">
                    Select an intertval
                </p>
                <div className="px-3 flex items-end">
                    <div className="flex flex-col overflow-hidden w-[100px] py-1 pr-2">
                        <label className="text-md text-left font-semibold">
                            Start
                        </label>
                        <input
                            placeholder="10"
                            className="p-[4px] text-center w-full bg-[#eee] rounded-lg text-[12px]"
                            type="time"
                            id="fl-1"
                            min="09:00"
                            max="18:00"
                            value={startTime}
                            onChange={(event) =>
                                setStartTime(event.target.value)
                            }
                        />
                    </div>
                    <div className="flex flex-col overflow-hidden w-[100px] py-1">
                        <label className="text-md text-left font-semibold">
                            End
                        </label>
                        <input
                            placeholder="10"
                            className="p-[4px] text-center w-full bg-[#eee] rounded-lg text-[12px]"
                            type="time"
                            id="fl-1"
                            min="09:00"
                            max="18:00"
                            value={endTime}
                            onChange={(event) => setEndTime(event.target.value)}
                        />
                    </div>
                    <ArrowRightIcon
                        className="text-white bg-[#000] rounded-full w-[30px] p-2 mb-1 ml-2 cursor-pointer"
                        onClick={submitInterval}
                    />
                </div>
                <p className="text-[12px] pb-2 px-3 text-center text-[#777] w-[85%] mx-auto">
                    Select the start and end time for a complete interval
                </p>
            </div>
        </div>
    );
}

export default TimeInterval;
