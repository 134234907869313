import React from "react";

function ReportWatermark({ data, api_route, about }) {
	return (
		<div className="absolute top-0 left-0 w-full h-full opacity-[0.1] flex justify-center items-center z-0">
			<img
				src={`${api_route}/images/${about.logo}`}
				className="object-fill w-[60%]"
			/>
		</div>
	);
}

export default ReportWatermark;
