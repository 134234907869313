import {
	ArrowRightIcon,
	CalendarIcon,
	DownloadIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import UnderConstruction from "../../components/UnderConstruction";

function Attendance() {
	const activities = [];
	for (let i = 0; i < 40; i++) {
		activities.push({
			id: i + 2,
			date: "2nd June 2023",
			name: "SSEMAGANDA FREDERICK",
			class: "P.2",
			stream: "WEST",
			activity: "Mathematics",
			staff: "Adekunle",
		});
	}
	const [showDatePicker, setShowDatePicker] = useState(true);
	const [selectionRange, setSelectionRange] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	return <UnderConstruction />;
	return (
		<div className="h-full">
			<MyDateRangePicker
				setSelectionRange={setSelectionRange}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				changeStatus={setShowDatePicker}
				showWindow={showDatePicker}
			/>
			<div className="wd mx-auto px-2 h-full">
				<div className="flex-col flex h-full">
					<div className="flex items-center py-2">
						<p className="font-semibold text-lg">
							Attendance sheet{" "}
							<span className="text-red-500 text-sm">
								(
								{selectionRange
									? selectionRange
											.replace(/-/g, "/")
											.replace(/:/g, " - ")
									: null}
								)
							</span>
						</p>
						<form className="flex items-center bg-[#eee] ml-auto mr-2 rounded-full overflow-hidden">
							<input
								placeholder="Find student ..."
								className="py-2 bg-transparent px-3"
								style={{ width: "250px" }}
							/>
							<CalendarIcon
								className="w-7 p-1 mr-1 cursor-pointer text-[#227a54]"
								onClick={() => setShowDatePicker(true)}
							/>
							<select className="py-2 px-1 cursor-pointer bg-transparent">
								<option>S.1</option>
								<option>S.2</option>
								<option>S.3</option>
							</select>
							<button>
								<ArrowRightIcon className="bg-black text-white w-7 p-2 mr-1 rounded-full" />
							</button>
						</form>
						<DownloadIcon className="bg-[#185a3e] text-white w-8 p-[7px] mr-1 rounded-full" />
					</div>
					<div className="flex-grow overflow-x-hidden overflow-y-auto">
						<table className="table-light w-full text-[12px]">
							<tbody>
								<tr>
									<th colSpan={6} className="text-lg">
										MONDAY 1st June 2019
									</th>
								</tr>
								<tr className="text-left thead">
									<th>DATE</th>
									<th>STUDENT</th>
									<th>CLASS</th>
									<th>STREAM</th>
									<th>ACTIVITY</th>
									<th>STAFF</th>
								</tr>
								{activities.map((obj, index) => (
									<tr key={index}>
										<td>{obj.date}</td>
										<td>{obj.name}</td>
										<td>{obj.class}</td>
										<td>{obj.stream}</td>
										<td>{obj.activity}</td>
										<td>{obj.staff}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Attendance;
