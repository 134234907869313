import React from "react";

function PageNotAccessible() {
	return (
		<div className="bg-gray-200 h-full flex flex-col justify-center items-center">
			<img className="w-[40%] opacity-5 absolute" src="/logo512.png" />
			<p className="text-2xl font-semibold text-red-600">
				Access denied!!
			</p>
			<p className="font-semibold text-gray-600">
				This page is restricted for your account type.
			</p>
		</div>
	);
}

export default PageNotAccessible;
