import { CheckIcon, XIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import { failedAlert, loader, successAlert } from "./vanilla/Dialogues";

function TeacherSubjects(props) {
	const { myAxios, api_route } = useContext(GlobalContext);
	const [subjects, setSubjects] = useState(null);
	const [selection, setSelection] = useState(props.subjectSelection);
	const [password, setPassword] = useState("");
	const saveAccessControl = (event) => {
		event.preventDefault();
		if (selection.length == 0) failedAlert("No item selected");
		else {
			loader(1);
			myAxios
				.post("/marksheets/subjects/saveAccessControl", {
					subjects: selection,
					teacherId: props.selectedRecord.id,
					password,
				})
				.then((response) => {
					successAlert("Your selection has been saved.");
					console.log(response);
					let subjects = response.data;
					props.setTeacherPopup(false);
					setSelection([]);
					loader(0);
				})
				.catch((error) => {
					console.log(error);
					failedAlert("Operation failed. Please try again.");
					loader(0);
				});
		}
	};
	const getAllSubjects = () => {
		loader(1);
		myAxios
			.post("/marksheets/subjects/findAll")
			.then((response) => {
				let subjects = response.data;
				console.log("All subjects ", subjects);
				setSubjects(subjects);
				loader(0);
			})
			.catch((error) => {
				failedAlert("Subjects not listed. There was a problem.");
				loader(0);
			});
	};
	const addSelection = (event) => {
		let input = event.target;
		let subjectId = input.value;
		if (input.checked) {
			setSelection((prev) => [...prev, subjectId]);
		} else {
			// remove id from selection
			setSelection(selection.filter((s) => s != subjectId));
		}
	};
	useEffect(() => {
		console.log("Selection changed ", props.subjectSelection);
		setSelection(props.subjectSelection);
	}, [props.subjectSelection]);
	useEffect(() => {
		if (subjects) return;
		getAllSubjects();
	}, [subjects]);
	return (
		<div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
			<div
				className={`relative popup-body overflow-hidden rounded-lg h-full max-h-[95%] bg-transparent`}
			>
				<form
					method="post"
					onSubmit={saveAccessControl}
					className="bg-white overflow-hidden flex flex-col h-full w-[500px] relative"
				>
					<input
						type="hidden"
						value={props?.selectedRecord?.id}
						name="id"
					/>
					<XIcon
						className="absolute top-2 right-2 w-6 cursor-pointer text-red-600 bg-red-200 rounded-full border-red-400 p-1"
						onClick={() => {
							setSelection([]);
							props.setTeacherPopup(false);
							setPassword("");
							// setInputs(initialState);
						}}
					/>
					<div className="p-3 flex flex-col">
						<p className="text-center font-semibold text-gray-400">
							(
							{props?.selectedRecord?.fname +
								" " +
								props?.selectedRecord?.lname}
							)
						</p>
						<p className="text-center text-lg font-semibold mb-2">
							Teachers' Access control
						</p>
						<p className="font-semibold">Account password</p>
						<input
							type="text"
							placeholder="e.g @$hU(@Jkm"
							style={{ boxShadow: "0px 3px 5px #eee" }}
							className="bg-gray-50 rounded-md py-2 px-3 mt-2"
							value={password}
							onChange={(event) => {
								setPassword(event.target.value);
							}}
						/>
					</div>
					<div
						className="flex-grow overflow-y-scroll overflow-x-hidden"
						style={{ borderBottom: "1px solid #eee" }}
					>
						{subjects &&
							subjects.map((cls) => (
								<div
									className="p-2 px-4"
									style={{ borderTop: "1px solid #eee" }}
								>
									<p className="font-semibold pb-2">
										{cls.alias} SUBJECTS
									</p>
									<div className="flex items-center flex-wrap">
										{cls.subjects.map((subject) => (
											<label
												className={`custom-radio flex flex-row-reverse items-center  text-black relative mb-2 mr-2 max-w-[180px] bg-gray-50 p-1 px-2 rounded-md`}
												key={"subject-" + subject.id}
											>
												<input
													type="checkbox"
													name="assessmentId"
													value={subject.id}
													{...(selection.includes(
														subject.id
													) && { checked: true })}
													onChange={addSelection}
												/>
												<p className="mr-auto flex-shrink-0 text-gray-500 text-[10px] font-semibold">
													{subject.subject.length > 10
														? subject.alias
														: subject.subject}
												</p>
												<span className="flex-shrink-0">
													<CheckIcon className="w-full text-white" />
												</span>
												<div className="absolute inset-0"></div>
											</label>
										))}
									</div>
								</div>
							))}
					</div>
					<button
						type="submit"
						className="bg-black text-white py-2 rounded-md m-3"
					>
						save
					</button>
				</form>
			</div>
		</div>
	);
}

export default TeacherSubjects;
