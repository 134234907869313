import React, { useContext, useEffect, useState } from "react";

import PageNotAccessible from "../../components/PageNotAccessible";
import ReportRequest from "./Reports/ReportRequest";
import ReportTemplates from "./Reports/ReportTemplates";
import { GlobalContext } from "../../GlobalContext";

function Reports() {
	const { myAccount, chosenTemplate, setChosenTemplate } =
		useContext(GlobalContext);
	const [openTemplates, setOpenTemplates] = useState(false);

	// showPrompt();
	const token = localStorage.getItem("token");

	// useEffect(() => {
	// 	console.log("Chosen Template = ", chosenTemplate);
	// }, [chosenTemplate]);
	if (myAccount.type != "admin") return <PageNotAccessible />;

	return (
		<div className="max-w-[900px] mx-auto w-full min-h-full flex flex-col">
			<ReportTemplates
				setOpenTemplates={setOpenTemplates}
				openTemplates={openTemplates}
			/>
			{/* {openTemplates && (
			)}
			{!openTemplates && (
			)} */}
			<ReportRequest
				openTemplates={openTemplates}
				setOpenTemplates={setOpenTemplates}
				setChosenTemplate={setChosenTemplate}
				chosenTemplate={chosenTemplate}
			/>
		</div>
	);
}

export default Reports;
