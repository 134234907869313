import {
	PencilIcon,
	PlusCircleIcon,
	PlusIcon,
	XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import {
	customConfirm,
	customPrompt,
	failedAlert,
	loader,
	successAlert,
	warningAlert,
} from "./vanilla/Dialogues";

function TermChanger(props) {
	// const [inputs, setInputs
	const [selectedTerm, setSelectedTerm] = useState(null);
	const {
		myAxios,
		myAccount,
		schoolData,
		term,
		setTermPopup,
		termPopup,
		loadData,
	} = useContext(GlobalContext);
	let dragElement = null;
	let placeholder = document.createElement("div");
	placeholder.className = "assessment-placeholder";
	const configDrug = (event) => {
		let e = event.target.parentNode;
		e.addEventListener("dragstart", dragStart);
		e.addEventListener("dragend", dragEnd);
		e.addEventListener("dragover", dragOver);
		e.addEventListener("dragenter", dragEnter);
		e.addEventListener("dragleave", dragLeave);
		e.addEventListener("drop", dragDrop);
	};
	const dragStart = (event) => {
		// dragElement = event.target.parentNode;
		dragElement = event.target;

		// console.log(dragElement);
	};
	const dragEnd = () => {
		// dragElement.parentNode.removeChild(placeholder);
		dragElement = null;
	};

	const dragEnter = () => {
		// this.className = "dragover";
	};
	const dragOver = (event) => {
		event.preventDefault();
		// this.className = "dragover";
	};
	const dragLeave = () => {
		// this.className = "";
	};
	const dragDrop = (event) => {
		if (dragElement == event.target.parentNode) {
			// warningAlert("Jangu mpola");
		} else {
			dragElement.parentNode.insertBefore(
				dragElement,
				event.target.parentNode
			);
		}
		// console.log("Destination = ", event.target);

		// if (this.dataset.file == dragElement.dataset.file) {
		// 	failedAlert("Can't move this folder to destination.");
		// 	return;
		// }
		// this.className = "";
		// // console.log(dragElement);
		// // console.log(this);
		// let moveTo = this.dataset.file;
		// let file = dragElement.dataset.file;
		// dragElement.parentNode.removeChild(dragElement);
		// dragElement = null;
	};

	const newAssessment = (event) => {
		let text = "Enter value below";
		let id = parseInt(event.target.dataset.id);
		if (id > 0) text = "Update this value";
		customPrompt({ title: text, value: event.target.dataset.value })
			.then((title) => {
				loader(1);
				myAxios
					.post("/newAssessment", {
						title,
						termId: schoolData.term.id,
						id,
					})
					.then((response) => {
						let data = response.data;
						console.log("Streams", data);
						loader();
						if (data.error !== undefined) failedAlert(data.error);
						else if (data.errors !== undefined)
							failedAlert(data.errors[0].msg);
						else {
							successAlert(data.msg, () => {
								// loader(1);
								window.location = "/";
							});
						}
					})
					.catch((error) => {
						console.log(error);
						loader();
					});
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (schoolData?.term?.term) {
			setSelectedTerm(parseInt(schoolData.term.term));
			console.log("My term =", schoolData.term.term);
		}
	}, [schoolData]);
	useEffect(() => {
		if (selectedTerm == schoolData.term?.term || !selectedTerm) return;
		console.log("Changing term", selectedTerm, schoolData.term);
		loader(1);
		myAxios
			.post("/updateTerm", {
				term: selectedTerm,
			})
			.then((response) => {
				let rp = response.data;
				console.log("Term Response = ", rp);
				if (rp.error !== undefined) {
					loader();
					failedAlert(rp.error);
				} else {
					if (rp.msg !== undefined) {
						// localStorage.setItem("token", rp.msg);
						// token = rp.msg;
						// redo the login to include the term in the token
						// return;
						myAxios
							.post("/switchAccounts", {
								userId: myAccount.id,
								type: myAccount.type,
								email: myAccount.email,
							})
							.then((response) => {
								let data = response.data;
								console.log(data);
								// localStorage.setItem("token", data.key);
								loader(1);
								window.location = "/";
							})
							.catch((error) => {
								console.log(error);
								failedAlert(
									"An error occured. Please try again",
									() => {
										loader(1);
										window.location = "/home/settings";
									}
								);
								loader();
							});
					} else if (rp.error !== undefined) failedAlert(rp.error);
				}
			})
			.catch((error) => {
				console.log(error);
				loader();
				failedAlert(
					`There was an error. Cant' switch to term ${selectedTerm}.`,
					() => {
						setSelectedTerm(parseInt(schoolData?.term?.term));
					}
				);
				// reject(false);
			});
	}, [selectedTerm]);
	return (
		<div className={`popup-sm ${termPopup ? "show" : ""}`}>
			<div className={`relative popup-body w-[500px]`}>
				<div method="post" className="flex flex-col relative">
					<div className="flex items-center px-4 py-3 mb-2 border-b-[1px] border-[#ccc]">
						<p className="font-bold">Your current term? </p>
						{schoolData.term?.term && (
							<XIcon
								className="absolute top-2 right-2 w-6 cursor-pointer text-red-600 bg-red-200 rounded-full border-red-400 p-1"
								onClick={() => {
									setTermPopup(false);
								}}
							/>
						)}
					</div>
					<div className="px-4 pb-4">
						<div className="flex-shrink-0 pb-2 flex items-center mb-2">
							<label
								className={`custom-radio flex flex-row-reverse items-center pr-3 py-2 font-semibold text-[#777] relative`}
							>
								<input
									type="radio"
									name="term"
									value={1}
									{...(term?.term == 1 && { checked: true })}
									onChange={(event) => {
										customConfirm(
											"Are you sure you want to continue?"
										)
											.then((x) => {
												setSelectedTerm(
													event.target.value
												);
											})
											.catch((error) => {
												setSelectedTerm(
													parseInt(
														schoolData?.term?.term
													)
												);
											});
									}}
								/>
								<p className="mr-auto">TERM ONE</p>
								<span className="flex-shrink-0"></span>
								<div className="absolute inset-0"></div>
							</label>
							<label
								className={`custom-radio flex flex-row-reverse items-center pr-3 py-2 font-semibold text-[#777] relative`}
							>
								<input
									type="radio"
									name="term"
									value={2}
									{...(term?.term == 2 && { checked: true })}
									onChange={(event) => {
										customConfirm(
											"Are you sure you want to continue?"
										)
											.then((x) => {
												setSelectedTerm(
													event.target.value
												);
											})
											.catch((error) => {
												setSelectedTerm(
													parseInt(
														schoolData?.term?.term
													)
												);
											});
									}}
								/>
								<p className="mr-auto">TERM TWO</p>
								<span className="flex-shrink-0"></span>
								<div className="absolute inset-0"></div>
							</label>
							<label
								className={`custom-radio flex flex-row-reverse items-center pr-3 py-2 font-semibold text-[#777] relative`}
							>
								<input
									type="radio"
									name="term"
									value={3}
									{...(term?.term == 3 && { checked: true })}
									onChange={(event) => {
										let input = event.target;
										// console.log(event.target);
										customConfirm(
											"Are you sure you want to continue?"
										)
											.then((x) => {
												setSelectedTerm(
													event.target.value
												);
											})
											.catch((error) => {
												setSelectedTerm(
													parseInt(
														schoolData?.term?.term
													)
												);
											});
									}}
								/>
								<p className="mr-auto">TERM THREE</p>
								<span className="flex-shrink-0"></span>
								<div className="absolute inset-0"></div>
							</label>
						</div>
						{schoolData.term?.term && (
							<>
								<div className="font-semibold flex items-center mb-3">
									<div className="relative">
										<PlusIcon className="mr-2 w-5 p-1 rounded-full bg-[#cfffea] border-[1px] border-[#3fbe87] text-[#196444]" />
										<div
											className="absolute inset-0 cursor-pointer"
											onClick={newAssessment}
											data-id="0"
											data-value=""
										></div>
									</div>
									<p>ASSESSMENTS</p>
								</div>
								<div className="w-full flex flex-wrap items-center pb-3">
									{schoolData?.assessments?.map(
										(obj, index) => (
											<div
												onMouseOver={configDrug}
												draggable
												className="flex items-center overflow-hidden rounded-md bg-[#eee] text-black font-semibold hover:scale-[1.05] relative select-none mr-2"
											>
												<div className="py-1 px-2 pl-3">
													{obj.title}
												</div>
												<PencilIcon className="w-7 p-[8px] bg-[#dfdfdf]" />
												<div
													className="absolute inset-0 cursor-pointer"
													onClick={newAssessment}
													data-id={obj.id}
													data-value={obj.title}
												></div>
											</div>
										)
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TermChanger;
