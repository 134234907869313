import { PlusCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import NewScheme from "./NewScheme";

function Schemes() {
	return (
		<>
			<div className="wd mx-auto h-full">
				<div className="flex items-center flex-wrap">
					<div>
						<Link to={"newScheme"} className="m-[10px] mr-0 border-[2px] border-[#4ba26a] bg-[#effdf4] text-[#4ba26a] p-[20px] rounded-[10px] h-[120px] w-[250px] flex jic flex-col">
							<PlusCircleIcon className="w-7" />
							<p className="text-[12px] mt-2 font-bold">New Scheme</p>
						</Link>
					</div>
					<div>
						<div className="m-[10px] mr-0 border-[2px] border-[#367c4f] bg-[#4ba26a] text-[#fff] p-[20px] rounded-[10px] h-[120px] w-[250px] flex justify-center flex-col">
							<p className="text-[26px] font-bold">Private</p>
							<p className="text-[18px] font-bold">UGX 500,000/=</p>
							<p>10 students</p>
						</div>
					</div>
				</div>
			</div>
			<Routes>
				<Route path="newScheme" element={<NewScheme />} />
			</Routes>
		</>
	);
}

export default Schemes;
