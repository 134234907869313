import React, { useContext } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

function PrintPreview(props) {
	const navigate = useNavigate();
	const { setReportMode } = useContext(GlobalContext);
	const closePrintPreview = (event) => {
		setReportMode(false);
		if (event.target?.className?.match(/printPreview/gi)?.length > 0) {
			navigate("../", { replace: true });
			return <></>;
		}
	};
	setReportMode(true);

	return (
		<div
			onClick={closePrintPreview}
			className="absolute top-0 left-0 w-full min-h-full z-50 bg-[#0009] cursor-pointer printPreview"
		>
			<div className="bg-white max-w-[900px] mx-auto min-h-[300%] overflow-y-auto overflow-x-hidden">
				{props.children}
			</div>
		</div>
	);
}

export default PrintPreview;
