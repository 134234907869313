import {
	ArrowRightIcon,
	PencilAltIcon,
	PencilIcon,
	PlusIcon,
	SearchIcon,
	TrashIcon,
} from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import "../css/marksheets.css";
import {
	failedAlert,
	successAlert,
	loader,
	warningAlert,
} from "../../../components/vanilla/Dialogues";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { faAlignRight } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../../GlobalContext";

function Classes() {
	// const dispatch = useDispatch();
	const token = localStorage.getItem("token");
	const initialState = { title: "", alias: "", id: null };
	const [loadClasses, setLoadClasses] = useState(true);
	const [selectedClass, setSelectedClass] = useState(0);
	const [streams, setStreams] = useState([]);

	const { classes, setClasses, myAxios } = useContext(GlobalContext);
	const [streamValue, setStreamValue] = useState({
		title: "",
		alias: "",
		class: 0,
		id: null,
	});
	const [cls, setCls] = useState(initialState);

	useEffect(() => {
		if (selectedClass == 0) return;
		console.log("Starting loader in classes.js");
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/classes/getStreams", { id: selectedClass })
			.then((response) => {
				let data = response.data;
				console.log("Streams", data);
				loader();
				setStreams(data.data);
			})
			.catch((error) => {
				loader();
			});
	}, [selectedClass]);

	const handleClsChange = (event) => {
		setCls((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};
	const saveClass = (event) => {
		event.preventDefault();

		if (cls["title"].replace(/ /gi).length == 0) {
			failedAlert("Please include title");
			setCls({ title: "", alias: cls["alias"] });
		} else if (cls["alias"].replace(/ /gi).length == 0) {
			failedAlert("Alias is required.");
			setCls({ title: cls["title"], alias: "" });
		} else {
			console.log("Starting loader in classes.js 2");
			loader(1);
			// const url = `${process.env.REACT_APP_API_URL}`;
			myAxios
				.post("/marksheets/classes/register", cls)
				.then((response) => {
					let data = response.data;
					console.log("response", data);
					// console.log(data, classes);
					// add classe to the array to update the interface
					const newClass = {
						class: data.result[0].class,
						alias: data.result[0].alias,
						id: data.result[0].id,
					};
					setClasses(
						classes.filter((value) => {
							if (value.id != newClass.id) return newClass;
						})
					);
					// setClasses((values) => [...values, newClass]);
					loader();
					successAlert("Request successful");
					// setCls({ title: "", alias: "", id: null });
					setLoadClasses(true); // force react to load all classes and store them in redux
					setCls(initialState);
				})
				.catch((error) => {
					loader();
					failedAlert(`Request faile. Please try again. ${error}`);
				});
		}
		console.log("Class", cls);
	};
	const saveStream = (event) => {
		event.preventDefault();
		// console.log("Stream", streamValue);
		if (streamValue["class"] == 0) {
			warningAlert("Please select a Class");
			return;
		}
		console.log("Starting loader in classes.js 3");
		loader(1);
		setSelectedClass(0);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/classes/registerStream", streamValue)
			.then((response) => {
				let data = response.data;
				console.log("response", data);
				if (data.errors) warningAlert(data.errors[0].msg);
				else if (data.error) warningAlert(data.error);
				else {
					// setSelectedClass(0);
					setSelectedClass(streamValue["class"]);
					// alert(streamValue["class"]);
					successAlert("Success");
					setStreamValue({
						title: "",
						alias: "",
						class: streamValue["class"],
						id: null,
					});
				}
				loader();
			})
			.catch((error) => {
				failedAlert("Failed to process request. Please try again.");
				loader();
			});
		// if()
	};
	useEffect(() => {
		if (!loadClasses) return;
		console.log("Starting loader in classes.js 4");
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.get("/marksheets/classes")
			.then((response) => response.json())
			.then((response) => {
				let data = response.data;
				console.log(data);
				loader();
				setClasses(data.data);
				setClasses(data.data);
			})
			.catch((error) => {
				// failedAlert("A problem occured.");
				console.log("error");
				loader();
			});
		setLoadClasses(false);
	}, [loadClasses]);

	const handleStreamChange = (event) => {
		setStreamValue((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
		// console.log(streamValue);
	};
	// useEffect(() => {
	//     if (classes.length === 0) {
	//         console.log("There you go");
	//         return;
	//     }
	//     console.log(classes);
	//     dispatch(setClasses(classes));
	// }, [classes]);
	return (
		<div className="h-full pt-10">
			<div className="flex max-w-[950px] mx-auto ">
				<div className="flex-grow w-1/2 px-3">
					<p className="text-xl pb-3 flex items-center">CLASSES</p>
					<form
						action=""
						method="post"
						className="flex items-center w-full mb-2"
						onSubmit={saveClass}
					>
						<input
							type="text"
							placeholder="e.g SENIOR ONE"
							className="input-cls"
							name="title"
							value={cls["title"]}
							onChange={handleClsChange}
							required
						/>
						<input
							type="text"
							placeholder="e.g S.1"
							className="input-cls ml-2"
							name="alias"
							value={cls["alias"]}
							onChange={handleClsChange}
							required
						/>
						<button type="submit">
							<ArrowRightIcon className="w-6 p-1 rounded-full bg-green-100 text-green-800 border-green-800 border-[1px] ml-2" />
						</button>
					</form>
					<table
						className="list-table"
						cellPadding="3"
						cellSpacing="0"
					>
						<tbody>
							<tr className="head">
								<td>TITLE</td>
								<td>ALIAS</td>
								<td className="w-[20px]"></td>
								{/* <td className="w-[20px]"></td> */}
							</tr>
							{classes.length
								? classes.map((element) => (
										<tr key={element.id}>
											<td>{element.class}</td>
											<td>{element.alias}</td>
											<td>
												<PencilIcon
													onClick={() => {
														setCls({
															title: element.class,
															alias: element.alias,
															id: element.id,
														});
													}}
													className="w-5 bg-green-100 rounded-full p-1 text-green-800 cursor-pointer border-[1px] border-green-800"
												/>
											</td>
											{/* <td>
												<TrashIcon className="w-5 bg-red-100 rounded-full p-1 text-red-500 cursor-pointer border-[1px] border-red-400" />
											</td> */}
										</tr>
								  ))
								: null}
						</tbody>
					</table>
				</div>
				<div className="flex-shrink-0 w-1/2 px-3">
					<p className="text-xl pb-3 flex items-center">STREAMS</p>
					<form
						action=""
						method="post"
						className="flex items-center w-full mb-2"
						onSubmit={saveStream}
					>
						<input
							type="text"
							placeholder="Stream e.g WEST"
							className="input-cls"
							name="title"
							onChange={handleStreamChange}
							value={streamValue["title"]}
							required
						/>
						<input
							type="text"
							placeholder="Alias e.g W"
							className="input-cls ml-2"
							name="alias"
							onChange={handleStreamChange}
							value={streamValue["alias"]}
							required
						/>
						<select
							onChange={(event) => {
								handleStreamChange(event);
								setSelectedClass(event.target.value);
							}}
							name="class"
							className="border-2 px-3 py-1 rounded-md ml-2"
						>
							<option value="0">Select class</option>
							{classes.length &&
								classes.map((element) => (
									<option
										title={element.class}
										key={element.id}
										value={element.id}
									>
										{element.alias}
									</option>
								))}
						</select>
						<button type="submit">
							<ArrowRightIcon className="w-6 p-1 rounded-full bg-green-100 text-green-800 border-green-800 border-[1px] ml-2" />
						</button>
					</form>
					<table
						className="list-table"
						cellPadding="3"
						cellSpacing="0"
					>
						<tbody>
							<tr className="head">
								<td>CLASS</td>
								<td>STREAM</td>
								<td>ALIAS</td>
								<td className="w-[20px]"></td>
								<td className="w-[20px]"></td>
							</tr>
							{streams.length
								? streams.map((stream) => (
										<tr key={stream.id}>
											<td>{stream.class.alias}</td>
											<td>{stream.stream}</td>
											<td>{stream.alias}</td>
											<td>
												<PencilIcon
													onClick={() => {
														setStreamValue({
															title: stream.stream,
															alias: stream.alias,
															class: stream.class
																.id,
															id: stream.id,
														});
													}}
													className="w-5 bg-green-100 rounded-full p-1 text-green-800 cursor-pointer border-[1px] border-green-800"
												/>
											</td>
											<td>
												<TrashIcon className="w-5 bg-red-100 rounded-full p-1 text-red-500 cursor-pointer border-[1px] border-red-400" />
											</td>
										</tr>
								  ))
								: null}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default Classes;
