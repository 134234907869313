import React, { useEffect, useState } from "react";
import { failedAlert, loader } from "../../../../components/vanilla/Dialogues";
import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";
import { useSelector } from "react-redux";
import { selectSchooldata } from "../../../../redux/slice";

function OldVersion(props) {
    const token = localStorage.getItem("token");
    const [sheetData, setSheetData] = useState([]);
    const [data, setData] = useState(null);
    const schoolData = useSelector(selectSchooldata);
    useEffect(() => {
        setSheetData(props.sheetData);
        setData(props.data);
        console.log("dxx", props.data);
        console.log("sheet", sheetData);
    }, [props.data]);

    return (
        <>
            {sheetData.students?.map((student) => {
                let subject_ids = props.attemptedSubjects(
                    sheetData.scores,
                    student.id
                );
                return (
                    <div className="mx-auto max-w-[790px] bg-white my-2 h-[842px] c-shadow-lg rounded-[4px] p-6 flex flex-col">
                        <MainHeader schoolData={schoolData} />
                        <div className="flex-grow flex items-center justify-center relative">
                            <div className="absolute h-2/3 w-2/3 flex items-center justify-center">
                                <img
                                    src="/logo512.png"
                                    className="object-cover opacity-[0.15] min-w-[100%] min-h-[100%] max-w-[140%]"
                                    alt="school badge"
                                />
                            </div>
                            <div className="absolute inset-0 h-full w-full overflow-hidden flex flex-col pb-2">
                                <div className="flex-grow">
                                    <div className="flex items-center flex-wrap">
                                        <div className="w-full">
                                            <p className="text-lg text-center font-bold text-red-400">
                                                TERMLY ASSESSMENT REPORT CARD
                                            </p>
                                        </div>
                                        <div className="w-1/3">
                                            <p>
                                                <b>NAME: {student.fullName}</b>
                                            </p>
                                        </div>
                                        <div className="flex-grow text-right">
                                            <p>
                                                <b>
                                                    CLASS: {student.class.alias}{" "}
                                                    {student.stream != undefined
                                                        ? student.stream.stream.toUpperCase()
                                                        : ""}
                                                </b>
                                            </p>
                                        </div>
                                        <table className="bare-table noborder isolated-borders w-full text-[11px] mt-1">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        rowSpan={2}
                                                        style={{
                                                            borderTop: "none",
                                                            borderBottom:
                                                                "none",
                                                            borderLeft: "none",
                                                        }}
                                                    ></td>
                                                    <th
                                                        colSpan={
                                                            3 +
                                                            data.assessmentId
                                                                .length
                                                        }
                                                        className="bg-gray-200 text-[14px]"
                                                    >
                                                        TERM{" "}
                                                        {sheetData
                                                            .assessments[0].term
                                                            .term +
                                                            " " +
                                                            sheetData
                                                                .assessments[0]
                                                                .term.year}
                                                    </th>
                                                </tr>
                                                <tr className="report-darker-cell">
                                                    <th
                                                        colSpan={
                                                            data.assessmentId
                                                                .length
                                                        }
                                                        rowSpan={2}
                                                    >
                                                        ASSESSMENT
                                                    </th>
                                                </tr>
                                                <tr className="report-darker-cell">
                                                    <th
                                                        className="w-[150px] text-left bg-white"
                                                        style={{
                                                            border: "none",
                                                        }}
                                                        colSpan={1}
                                                        rowSpan={2}
                                                    ></th>

                                                    <th
                                                        rowSpan={19}
                                                        style={{
                                                            width: 5,
                                                            border: "none",
                                                        }}
                                                        className="bg-white"
                                                    ></th>

                                                    <th rowSpan={2}>
                                                        Final Score
                                                    </th>
                                                    <th
                                                        className="w-[50px]"
                                                        colSpan={2}
                                                    >
                                                        Rank
                                                    </th>
                                                    <th
                                                        className="bg-white"
                                                        style={{
                                                            border: "none",
                                                        }}
                                                        rowSpan={2}
                                                    >
                                                        Remark
                                                    </th>
                                                </tr>
                                                <tr className="report-darker-cell">
                                                    {sheetData.assessments.map(
                                                        (assessment) => (
                                                            <th className="w-[35px]">
                                                                {
                                                                    assessment.title
                                                                }
                                                            </th>
                                                        )
                                                    )}
                                                    <th>STREAM</th>
                                                    <th>CLASS</th>
                                                </tr>
                                                {sheetData.subjects.map(
                                                    (subject, index) => {
                                                        if (
                                                            subject_ids.includes(
                                                                subject.id
                                                            )
                                                        ) {
                                                            if (
                                                                subject
                                                                    .subject_units
                                                                    .length > 0
                                                            ) {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="bg-transparent"
                                                                        >
                                                                            <th
                                                                                className="text-left"
                                                                                rowSpan={
                                                                                    subject
                                                                                        .subject_units
                                                                                        .length
                                                                                }
                                                                            >
                                                                                {subject
                                                                                    .subject
                                                                                    .length >
                                                                                14
                                                                                    ? subject.alias
                                                                                    : subject.subject}
                                                                            </th>

                                                                            {sheetData.assessments.map(
                                                                                (
                                                                                    ass
                                                                                ) => (
                                                                                    <th
                                                                                        key={
                                                                                            "score-" +
                                                                                            ass.id +
                                                                                            "-" +
                                                                                            student.id +
                                                                                            "-" +
                                                                                            subject.id +
                                                                                            "-unit" +
                                                                                            subject
                                                                                                .subject_units[0]
                                                                                                .id
                                                                                        }
                                                                                    >
                                                                                        {props.findScore(
                                                                                            sheetData.scores,
                                                                                            ass.id,
                                                                                            student.id,
                                                                                            subject.id,
                                                                                            subject
                                                                                                .subject_units[0]
                                                                                                .id
                                                                                        )}
                                                                                    </th>
                                                                                )
                                                                            )}

                                                                            {sheetData.ranks.map(
                                                                                (
                                                                                    rank
                                                                                ) =>
                                                                                    rank.studentId ==
                                                                                        student.id &&
                                                                                    rank.subjectId ==
                                                                                        subject.id ? (
                                                                                        <>
                                                                                            <th
                                                                                                rowSpan={
                                                                                                    subject
                                                                                                        .subject_units
                                                                                                        .length
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    rank.total
                                                                                                }{" "}
                                                                                                <small className="text-red-500">
                                                                                                    D1
                                                                                                </small>
                                                                                            </th>
                                                                                            <th>
                                                                                                {rank.stream_pos +
                                                                                                    "/" +
                                                                                                    rank.stream_total}
                                                                                            </th>
                                                                                            <th>
                                                                                                {rank.pos +
                                                                                                    "/" +
                                                                                                    rank.b}
                                                                                            </th>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )
                                                                            )}
                                                                            <th
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        "nowrap",
                                                                                }}
                                                                                className="text-left"
                                                                            >
                                                                                Very
                                                                                Good
                                                                                performance
                                                                                performance{" "}
                                                                                <small className="text-red-500">
                                                                                    (IN)
                                                                                </small>
                                                                            </th>
                                                                        </tr>
                                                                        {subject.subject_units.map(
                                                                            (
                                                                                unit,
                                                                                index
                                                                            ) => {
                                                                                if (
                                                                                    index >
                                                                                    0
                                                                                ) {
                                                                                    return (
                                                                                        <tr
                                                                                            key={
                                                                                                "row-" +
                                                                                                index
                                                                                            }
                                                                                            className="bg-transparent"
                                                                                        >
                                                                                            {sheetData.assessments.map(
                                                                                                (
                                                                                                    ass
                                                                                                ) => (
                                                                                                    <th
                                                                                                        key={
                                                                                                            "score-" +
                                                                                                            ass.id +
                                                                                                            "-" +
                                                                                                            student.id +
                                                                                                            "-" +
                                                                                                            subject.id +
                                                                                                            "-unit" +
                                                                                                            unit.id
                                                                                                        }
                                                                                                    >
                                                                                                        {props.findScore(
                                                                                                            sheetData.scores,
                                                                                                            ass.id,
                                                                                                            student.id,
                                                                                                            subject.id,
                                                                                                            unit.id
                                                                                                        )}
                                                                                                    </th>
                                                                                                )
                                                                                            )}

                                                                                            {sheetData.ranks.map(
                                                                                                (
                                                                                                    rank
                                                                                                ) =>
                                                                                                    rank.studentId ==
                                                                                                        student.id &&
                                                                                                    rank.subjectId ==
                                                                                                        subject.id ? (
                                                                                                        <>
                                                                                                            <th>
                                                                                                                {rank.stream_pos +
                                                                                                                    "/" +
                                                                                                                    rank.stream_total}
                                                                                                            </th>
                                                                                                            <th>
                                                                                                                {rank.pos +
                                                                                                                    "/" +
                                                                                                                    rank.b}
                                                                                                            </th>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                        </>
                                                                                                    )
                                                                                            )}
                                                                                            <th
                                                                                                style={{
                                                                                                    whiteSpace:
                                                                                                        "nowrap",
                                                                                                }}
                                                                                                className="text-left"
                                                                                            >
                                                                                                Very
                                                                                                Good
                                                                                                performance
                                                                                                performance{" "}
                                                                                                <small className="text-red-500">
                                                                                                    (IN)
                                                                                                </small>
                                                                                            </th>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </>
                                                                );
                                                            } else {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="bg-transparent"
                                                                    >
                                                                        <th className="text-left">
                                                                            {subject
                                                                                .subject
                                                                                .length >
                                                                            14
                                                                                ? subject.alias
                                                                                : subject.subject}
                                                                        </th>

                                                                        {sheetData.assessments.map(
                                                                            (
                                                                                ass
                                                                            ) => (
                                                                                <th
                                                                                    key={
                                                                                        "score-" +
                                                                                        ass.id +
                                                                                        "-" +
                                                                                        student.id +
                                                                                        "-" +
                                                                                        subject.id
                                                                                    }
                                                                                >
                                                                                    {props.findScore(
                                                                                        sheetData.scores,
                                                                                        ass.id,
                                                                                        student.id,
                                                                                        subject.id
                                                                                    )}
                                                                                </th>
                                                                            )
                                                                        )}

                                                                        {sheetData.ranks.map(
                                                                            (
                                                                                rank
                                                                            ) =>
                                                                                rank.studentId ==
                                                                                    student.id &&
                                                                                rank.subjectId ==
                                                                                    subject.id ? (
                                                                                    <>
                                                                                        <th>
                                                                                            {
                                                                                                rank.total
                                                                                            }{" "}
                                                                                            <small className="text-red-500">
                                                                                                D1
                                                                                            </small>
                                                                                        </th>
                                                                                        <th>
                                                                                            {rank.stream_pos +
                                                                                                "/" +
                                                                                                rank.stream_total}
                                                                                        </th>
                                                                                        <th>
                                                                                            {rank.pos +
                                                                                                "/" +
                                                                                                rank.b}
                                                                                        </th>
                                                                                    </>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )
                                                                        )}
                                                                        <th
                                                                            style={{
                                                                                whiteSpace:
                                                                                    "nowrap",
                                                                            }}
                                                                            className="text-left"
                                                                        >
                                                                            Very
                                                                            Good
                                                                            performance
                                                                            performance{" "}
                                                                            <small className="text-red-500">
                                                                                (IN)
                                                                            </small>
                                                                        </th>
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    }
                                                )}
                                                <tr className="bg-[#eee]">
                                                    <th
                                                        colSpan={2}
                                                        style={{
                                                            border: "none",
                                                        }}
                                                        className="bg-white text-green-700"
                                                    >
                                                        STREAM POSITION: 1/46
                                                    </th>
                                                    <th>86</th>
                                                    <th
                                                        colSpan={2}
                                                        style={{
                                                            border: "none",
                                                        }}
                                                        className="bg-white text-green-700"
                                                    >
                                                        CLASS POSITION: 1/250
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <GradingScale />
                            </div>
                        </div>
                        <MainFooter />
                    </div>
                );
            })}
        </>
    );
}

export default OldVersion;
