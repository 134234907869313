import React from "react";

function GradingScale(props) {
	const { grading } = props;
	// console.log("Here is my grading ", grading);
	if (!grading) return <></>;
	return (
		<table className="bare-table w-full text-[12px]">
			<tr className="bg-[#eee]">
				<th>SCORE</th>
				{grading.map((grd) => (
					<th>{`${grd.min} - ${grd.max}`}</th>
				))}
			</tr>
			<tr>
				<th>GRADE</th>
				{grading.map((grd) => (
					<th>{`${grd.symbol}`}</th>
				))}
			</tr>
		</table>
	);
}

export default GradingScale;
