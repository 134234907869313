import React, { useContext, useState } from "react";
import ReportWatermark from "./ReportWatermark";
import Header2 from "../ReportHeaders/Header2";
import MainFooter from "../ReportFooters/MainFooter";
import { GlobalContext } from "../../../../GlobalContext";
import GradintTable from "../ReportFooters/GradingTable";

function PrimaryOneAssessment({ data }) {
	const students = data?.students;
	const term = data?.term;
	const subjects = data?.subjects;
	const initials = data?.initials;
	const grading = data?.grading;
	const assessments = data?.assessments;
	const cls = data?.class;
	let totalGrades = 0;
	let totalScores = 0;
	let allGrades = [];

	console.log("This is my grading ", grading);
	const { schoolData, api_route } = useContext(GlobalContext);
	// const [allGrades, setAllGrades] = useState([]);
	// const [totalGrades, setTotalGrades] = useState(0);

	const getScore = (subjectId) => {
		// return new Promise((resolve) => {
		// 	resolve("Finale");
		// 	return;
		// });
		return "x";
	};

	return students.map((student) => (
		<div className="max-w-[800px] w-full mx-auto" key={student.studentId}>
			<div className="h-[1072px] m-[30px] border-[2px] border-[#dddddd] bg-white relative rounded-[5px] overflow-hidden flex flex-col">
				<ReportWatermark about={schoolData} api_route={api_route} />
				<div className="relative z-10">
					<div className="border-b-[1px] border-[#dddddd] bg-[#f4f4f4]">
						<Header2
							about={schoolData}
							api_route={api_route}
							student={student}
						/>
					</div>
					<div className="border-b-[1px] border-[#dddddd] border-dashed p-[15px]">
						<div className="flex items-center">
							<p className="px-2 flex-grow">
								<span className="font-bold">NAME:</span>{" "}
								{student.fullName}
							</p>
							<p className="px-2 w-[100px]">
								<span className="font-bold">CLASS:</span>{" "}
								{cls.alias}
							</p>
							<p className="px-2 w-[70px]">
								<span className="font-bold">TERM:</span>{" "}
								{term.term}
							</p>
							<p className="px-2 w-[100px]">
								<span className="font-bold">YEAR:</span>{" "}
								{term.year}
							</p>
							<p className="px-2 w-[100px] hidden">
								<span className="font-bold">STREAM:</span>{" "}
								BRIGHT
							</p>
						</div>
					</div>
					<p className="py-8 text-center font-bold text-[18px] text-[#000]">
						<span className="text-[#f00] mr-2">
							{assessments[0].title}
						</span>
						<u>
							TERM {term.term}, {term.year}
						</u>{" "}
						STUDENT'S PERFORMANCE REPORT CARD.
					</p>
				</div>
				<div className="z-10 flex-grow">
					<div className="mx-3 rounded-[5px] overflow-hidden border-[1px] border-[#ccc]">
						<table className="pad6 w-full border-[2px] px-2 border-[#000] table-light font-semibold text-[13px]">
							<tr className="text-left thead">
								<th>SUBJECTS</th>
								<th className="text-center p-3">TTL.MKS</th>
								<th className="text-center w-[60px] p-3">
									SCORE
								</th>
								<th className="text-center w-[60px] p-3">
									WEIGHT
								</th>
								<th className="text-center w-[60px] p-3">
									AGG
								</th>
								<th className="p-3">REMARKS</th>
								<th className="w-[80px] p-3">INITIALS</th>
							</tr>
							{subjects.map((subject) => {
								// const score = await getScore(subject.id);
								let grade;
								let subject_teacher = null;
								if (
									subject.teacher_subjects &&
									subject.teacher_subjects?.length > 0
								)
									subject_teacher =
										subject.teacher_subjects[0].teacher;
								let score;
								const scores = student.assessments[0].scores;
								scores.map((sc) => {
									if (sc.subjectId == subject.id) {
										score = sc;
										totalScores += sc.score;
									}
								});
								if (score) {
									grading.map((g) => {
										if (
											score.score >= g.min &&
											score.score <= g.max
										) {
											grade = g;
											allGrades.push(g);
											totalGrades += g.value;
										}
									});
								}
								return (
									<tr
										key={
											student.studentId + "-" + subject.id
										}
										className="text-[#000]"
									>
										<td>{subject.subject}</td>
										<td className="text-center text-[#00f]">
											{100}
										</td>
										<td className="text-[#1a754d] text-center">
											{score.score}
										</td>
										<td className="text-center text-[11px]">
											{score.subject_rank_class && (
												<div className="flex items-center justify-center">
													<div className="w-[25px]">
														{
															score.subject_rank_class
														}
													</div>
													<span className="text-[#c33530] font-bold">
														/
													</span>
													<div className="w-[25px]">
														{
															score.subject_class_total
														}
													</div>
												</div>
											)}
										</td>
										<td className="text-center">
											{grade && grade.symbol}
										</td>
										<td>{grade && grade.remark}</td>
										<td className="text-[#f00]">
											{subject_teacher &&
												`${subject_teacher.fname.substring(
													0,
													1
												)}${subject_teacher.lname.substring(
													0,
													1
												)}`}
										</td>
									</tr>
								);
							})}
							<tr className="">
								<th colSpan={2}>TOTAL</th>
								<th>{totalScores}</th>
								<th></th>
								<th>{totalGrades}</th>
								<th colSpan={2}></th>
							</tr>
							{subjects.map(() => {
								totalGrades = 0;
								totalScores = 0;
								return;
							})}
						</table>
					</div>
					<div className="border-t-[1px] border-b-[1px] border-[#b7b7b7] my-8 flex font-bold">
						<div className="py-4 pl-4 pr-2 border-r-[1px] border-[#b7b7b7]">
							Position:
						</div>
						<div className="p-4 border-r-[1px] border-[#b7b7b7] text-[#f00]">
							{student.classRank}
						</div>
						<div className="py-4 pl-4 pr-2 border-r-[1px] border-[#b7b7b7]">
							Out Of:
						</div>
						<div className="p-4 text-[#f00]">
							{student.classTotal}
						</div>
					</div>
				</div>
				<div className="z-10 m-4 rounded-[7px] overflow-hidden border-[1px] border-[#000]">
					<GradintTable grading={grading} />
				</div>
				<div className="z-10 border-t-[1px] border-[#ddd] bg-[#fbfbfb] flex flex-col">
					<MainFooter cls={data.class} student={student} />
					<p className="text-center text-[#999] pb-2 pt-1 italic">
						This report card should carry a stamp.
					</p>
				</div>
			</div>
		</div>
	));
}

export default PrimaryOneAssessment;
