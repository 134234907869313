import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./css/checkbox.css";

function CheckBox(props) {
    return (
        <label
            className={`flex items-center custom-check flex-row-reverse cursor-pointer`}
        >
            <input type="checkbox" name={props.name} />
            <p className="mr-auto">{props.text}</p>

            <span
                className={`w-[15px] h-[15px] mr-1 rounded-[3px] flex items-center justify-center`}
            >
                <FontAwesomeIcon icon={faCheck} />
            </span>
        </label>
    );
}

export default CheckBox;
