import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
	faHomeAlt,
	faBookAtlas,
	faEarthAfrica,
} from "@fortawesome/free-solid-svg-icons";
import {
	HomeIcon,
	FolderIcon,
	CurrencyDollarIcon,
	CogIcon,
} from "@heroicons/react/outline";

import { BadgeCheckIcon } from "@heroicons/react/outline";
import {
	failedAlert,
	loader,
	successAlert,
} from "../components/vanilla/Dialogues";
import { GlobalContext } from "../GlobalContext";

let setSuccess, success;
function Signup() {
	// [success, setSuccess] = useState(false);
	// return success ? <SignupSuccess /> : <SignupForm />;
}

const SignupForm = () => {
	const { myAxios } = useContext(GlobalContext);
	const navigate = useNavigate();
	const initialState = {
		name: "",
		email: "",
		phonecontact: "",
		postal_address: "",
		physical_address: "",
		contact_person_name: "",
		contact_person_phone: "",
		motto: "",
		password: "",
		type: "",
	};
	const [formData, setFormData] = useState(initialState);
	// loader(1);
	const handleData = (event) => {
		setFormData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};
	useEffect(() => {
		console.log(formData);
	}, [formData]);
	const submitData = (event) => {
		event.preventDefault();
		if (formData.type == "") failedAlert("Please select your school type.");
		else {
			loader(1);
			myAxios
				.post("/registerSchool", formData)
				.then((response) => {
					let data = response.data;
					console.log("data", data);
					successAlert(data.message);
					navigate("../", { replace: true });
				})
				.catch((error) => {
					let data = error.response.data;
					if (data?.errors?.length > 0) {
						let errors = data.errors;
						let error_message = errors[0].msg;
						failedAlert(error_message);
					} else if (data.message) {
						failedAlert(data.message);
					} else failedAlert("There was an error. Please try again.");
					console.log("Error response ", data);
				})
				.finally(() => {
					loader(0);
				});
		}
	};
	return (
		<div className="min-h-screen bg-white flex items-center flex-col overflow-y-auto">
			<div className="text-center min-h-screen">
				<form
					onSubmit={submitData}
					className="w-[400px] rounded-lg p-5 text-left flex flex-col justify-start"
				>
					<img
						src="./android-chrome-512x512.png"
						className="mx-auto w-[100px]"
					/>
					<p className="text-xl mb-2 font-semibold text-center">
						Create your account today
					</p>
					<div className="flex-grow flex flex-wrap">
						<div className="w-1/2 flex-shrink-0 pr-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								School name
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g Good Will Secondary School"
								name="name"
								value={formData.name}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pl-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								Official Email
							</p>
							<input
								type="email"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g admin@goodwill.com"
								name="email"
								value={formData.email}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pr-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								Phone Contact
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g +256777733557"
								name="phonecontact"
								value={formData.phonecontact}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pl-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								Postal Address
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g P.O Box 2378 Wakiso"
								name="postal_address"
								value={formData.postal_address}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pr-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								Physical Address
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g Wakiso Town"
								name="physical_address"
								value={formData.physical_address}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pl-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								School Motto
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g Wakiso Town"
								name="motto"
								value={formData.motto}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-full my-3">
							<p
								className="text-lg font-bold pb-2"
								style={{ borderBottom: "1px solid #e6e6e6" }}
							>
								Contact person
							</p>
						</div>
						<div className="w-1/2 flex-shrink-0 pr-2 mt-2">
							<p className="text-xs mb-1 font-semibold">Name</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g Tamale Joseph"
								name="contact_person_name"
								value={formData.contact_person_name}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-1/2 flex-shrink-0 pl-2 mt-2">
							<p className="text-xs mb-1 font-semibold">
								Phone Contact
							</p>
							<input
								type="text"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g +256777733557"
								name="contact_person_phone"
								value={formData.contact_person_phone}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-full mt-5">
							<p className="font-semibold ">
								Choose your account password
							</p>
						</div>
						<div className="w-full mt-1">
							<input
								type="password"
								style={{
									boxShadow: "0 2px 3px #eee",
									border: "1px solid #eee",
								}}
								className="text-xs rounded-md p-2 w-full"
								placeholder="e.g G&XF#$H"
								name="password"
								value={formData.password}
								onChange={handleData}
								required
							/>
						</div>
						<div className="w-full mt-2 mb-2">
							<p
								className="text-lg font-bold pb-2"
								style={{ borderBottom: "1px solid #e6e6e6" }}
							>
								Choose your account type
							</p>
						</div>
						<label
							className={`w-1/2 custom-radio flex flex-row-reverse items-center font-semibold text-black relative`}
						>
							<input
								type="radio"
								name="type"
								value="primary"
								{...(formData.type == "primary" && {
									checked: true,
								})}
								onChange={(event) => {
									let type = parseInt(event.target.value);
									setFormData((prev) => ({
										...prev,
										type,
									}));
								}}
							/>
							<p className="mr-auto text-xs">PRIMARY SCHOOL</p>
							<span className="flex-shrink-0"></span>
							<div className="absolute inset-0"></div>
						</label>
						<label
							className={`w-1/2 custom-radio flex flex-row-reverse items-center font-semibold text-black relative`}
						>
							<input
								type="radio"
								name="type"
								value="secondary"
								{...(formData.type == "secondary" && {
									checked: true,
								})}
								onChange={(event) => {
									let type = parseInt(event.target.value);
									setFormData((prev) => ({
										...prev,
										type,
									}));
								}}
							/>
							<p className="mr-auto  text-xs">SECONDARY SCHOOL</p>
							<span className="flex-shrink-0"></span>
							<div className="absolute inset-0"></div>
						</label>
					</div>
					<div>
						<button className="text-xs bg-[#000] rounded-lg text-center py-3 font-semibold text-white mt-4 w-full">
							Submit
						</button>
						<Link
							to="/"
							className="text-gray-600 font-semibold hover:underline text-center mt-4 w-full block"
						>
							Or login to your account
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
};

const SignupSuccess = () => {
	return (
		<div className="h-screen flex items-center justify-center">
			<div className="shadow-2xl p-6 rounded-xl flex flex-col items-center max-w-[400px]">
				<BadgeCheckIcon className="text-green-700 w-12 h-12 mb-4" />
				<p className="font-semibold mb-4">
					Registration was successful.
				</p>
				<p className="text-sm mb-2">
					We shall reach out to you with more details about your newly
					created account.
				</p>
				<p className="text-left w-full text-xs">
					Thank you for being part of us.
				</p>
				<Link
					to="/"
					className="text-white bg-orange-500 text-sm px-2 py-1 rounded-sm mr-auto mt-3"
				>
					Back to Home
				</Link>
			</div>
		</div>
	);
};

export default SignupForm;
