import {
	ArrowRightIcon,
	PencilIcon,
	TrashIcon,
	XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext";
import {
	customConfirm,
	failedAlert,
	loader,
	successAlert,
} from "../../../../components/vanilla/Dialogues";

function GradingPopup() {
	const { myAxios, classes, loadClasses } = useContext(GlobalContext);
	let { id } = useParams();
	let gradeId = id;
	// console.log("Grade ID ", id);
	const initialState = {
		id: 0,
		min: "",
		max: "",
		symbol: "",
		value: "",
		remark: "",
		gradeId: id,
	};
	const [data, setData] = useState(initialState);
	const [rows, setRows] = useState([]);
	const handleForm = (event) => {
		setData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};
	const saveGradingDetails = (event) => {
		event.preventDefault();
		myAxios
			.post("/grading/saveDetails", data)
			.then((response) => {
				// let data = ;
				// successAlert("Request successful");
				getGradeDetails();
				setData(initialState);
			})
			.catch((error) => {
				// console.log();
				failedAlert("Request failed. Please try again.");
			});
	};
	const getGradeDetails = () => {
		loader(1);
		myAxios
			.post("/grading/getRows", { id })
			.then((response) => {
				loader(0);
				let data = response.data.data;
				setRows(data);
				console.log(data);
			})
			.catch((error) => {
				loader(0);
				failedAlert("Records not retrieved. Please try again.");
			});
	};
	const deleteRow = (id) => {
		loader(1);
		myAxios
			.post("/grading/deleteRow", { id })
			.then((response) => {
				getGradeDetails();
			})
			.catch((error) => {
				failedAlert("Request failed. Please try again.");
			})
			.finally((x) => {
				loader(0);
			});
	};
	const attachGradingToClass = (event) => {
		let classId = event.target.value;
		let action = event.target.checked ? "add" : "remove";
		console.log(action);
		if (action == "add") {
			event.target.parentNode.classList.add("bg-green-300");
		} else {
			console.log("removing highlisgth");
			event.target.parentNode.classList.remove("bg-green-300");
		}
		myAxios
			.post("/grading/attachGradingToClass", {
				classId,
				action,
				gradeId,
			})
			.then((response) => {
				console.log(response);
				loadClasses();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		getGradeDetails();
	}, []);
	return (
		<div className="bg-[#0003] fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center">
			<div className="bg-white w-full max-w-[550px] rounded-md overflow-hidden max-h-[90%] flex flex-col">
				<div className="flex items-center border-b-[1px] border-b-gray-200">
					<p className="text-lg text-center py-2 flex-grow">
						Grade specificity
					</p>
					<Link to="../">
						<XIcon className="w-7 h-7 rounded-full bg-red-100 text-red-500 p-2 cursor-pointer mr-3" />
					</Link>
				</div>
				<div className="p-5 border-b-[1px] border-b-gray-200">
					<form
						onSubmit={saveGradingDetails}
						className="flex items-center"
					>
						<input type="hidden" name="hidden" value={data["id"]} />
						<div className="w-[70px]">
							<div className="flex flex-col pr-2">
								<p>Min</p>
								<input
									type="number"
									name="min"
									className="rounded-md p-[5px] bg-gray-100"
									onChange={handleForm}
									value={data["min"]}
									required
								/>
							</div>
						</div>
						<div className="w-[70px]">
							<div className="flex flex-col pr-2">
								<p>Max</p>
								<input
									type="number"
									name="max"
									className="rounded-md p-[5px] bg-gray-100"
									onChange={handleForm}
									value={data["max"]}
									required
								/>
							</div>
						</div>
						<div className="w-[100px]">
							<div className="flex flex-col pr-2">
								<p>Grade symbol</p>
								<input
									type="text"
									name="symbol"
									className="rounded-md p-[5px] bg-gray-100"
									onChange={handleForm}
									value={data["symbol"]}
									required
								/>
							</div>
						</div>
						<div className="w-[70px]">
							<div className="flex flex-col pr-2">
								<p>Value</p>
								<input
									type="text"
									name="value"
									className="rounded-md p-[5px] bg-gray-100"
									onChange={handleForm}
									value={data["value"]}
									required
								/>
							</div>
						</div>
						<div className="w-[150px]">
							<div className="flex flex-col pr-2">
								<p>General remark</p>
								<input
									type="text"
									name="remark"
									className="rounded-md p-[5px] bg-gray-100"
									onChange={handleForm}
									value={data["remark"]}
									required
								/>
							</div>
						</div>
						<div>
							<div className="flex flex-col pr-2">
								<p className="opacity-0">--</p>
								<button>
									<ArrowRightIcon className="w-8 h-8 p-2 cursor-pointer text-white bg-black rounded-full" />
								</button>
							</div>
						</div>
					</form>
				</div>
				<div className="m-3 flex-grow overflow-x-hidden overflow-y-auto">
					<table className="w-full list-table" cellPadding={5}>
						<thead className="bg-gray-100">
							<tr className="text-left">
								<th>Range</th>
								<th>Symbol</th>
								<th>Value</th>
								<th>Remark</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								<tr key={"row-grading-" + row.id}>
									<td>{row.min + "-" + row.max}</td>
									<td>{row.symbol}</td>
									<td>{row.value}</td>
									<td>{row.remark}</td>
									<td className="cursor-pointer">
										<PencilIcon
											onClick={() => {
												setData((prev) => ({
													id: row.id,
													min: row.min,
													max: row.max,
													symbol: row.symbol,
													value: row.value,
													remark: row.remark,
													gradeId: prev.gradeId,
												}));
											}}
											className="w-5 h-5 mx-auto rounded-full bg-green-100 text-green-500 p-[4px]"
										/>
									</td>
									<td className="cursor-pointer">
										<TrashIcon
											onClick={() => {
												customConfirm(
													"Are you sure to delete?"
												).then((x) => {
													deleteRow(row.id);
												});
											}}
											className="w-5 h-5 mx-auto rounded-full bg-red-100 text-red-500 p-[4px]"
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="border-t-[1px] border-t-gray-200 p-2 bg-gray-100 select-none">
					<p className="font-semibold text-center">
						Select Classes below
					</p>
					<div className="flex items-center justify-center flex-wrap pt-3 pb-1">
						{classes.map((cls) => (
							<label
								key={cls.id}
								className="mr-4 mb-2 cursor-pointer"
							>
								<div
									className={`flex items-center px-2 py-1 rounded-md ${
										cls.gradingId != null &&
										cls.gradingId == gradeId
											? "bg-green-300"
											: ""
									}`}
								>
									<input
										type="checkbox"
										value={cls.id}
										onChange={attachGradingToClass}
										className="h-0 w-0 opacity-0"
									/>
									<span>{cls.alias}</span>
								</div>
							</label>
						))}
					</div>
					<p className="text-center text-xs text-gray-400">
						The classes you select will take up the grading above
					</p>
				</div>
			</div>
		</div>
	);
}

export default GradingPopup;
