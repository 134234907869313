import {
	ArrowRightIcon,
	EyeIcon,
	EyeOffIcon,
	PencilIcon,
	XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { failedAlert, loader } from "../../../../components/vanilla/Dialogues";
import { GlobalContext } from "../../../../GlobalContext";

function SubjectPapers(props) {
	const token = localStorage.getItem("token");
	const { myAxios } = useContext(GlobalContext);
	const subjectData = props.subjectData;
	const [unit, setUnit] = useState({
		id: 0,
		unit: "",
		subject: 0,
	});
	const [unitList, setUnitList] = useState([]);
	useEffect(() => {
		if (props.subjectData === null) return;
		setUnit((prev) => ({ ...prev, subject: subjectData }));
		loadUnits();
	}, [props.subjectData]);
	const loadUnits = () => {
		return;
		const subjectId = unit.subject.id;
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/subjects/findUnit", { subjectId })
			.then((response) => {
				let data = response.data;
				loader();
				if (data.error) failedAlert(data.error);
				else {
					setUnitList(data);
				}
				console.log("response -- ", data);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request failed. Please try again.`);
			});
	};
	const saveunit = (event) => {
		event.preventDefault();
		// console.log(unit);
		// return;
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/subjects/registerUnit", unit)
			.then((response) => {
				// loader();
				let data = response.data;
				if (data.error) failedAlert(data.error);
				else {
					setUnit((prev) => ({ ...prev, unit: "", id: 0 }));
					// loadUnits();
					props.loadSubjects();
				}
				// console.log("response -- ", data);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request failed. Please try again. ${error}`);
			});
	};
	const toggleUnitStatus = (obj) => {
		loader(1);
		const id = obj.id;
		const status = parseInt(obj.active) === 1 ? "0" : "1";
		const data = { id, status };
		console.log("data = ", data);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/subjects/toggleUnitStatus", data)
			.then((response) => {
				let data = response.data;
				console.log(data);
				// loader();
				if (data.error) failedAlert(data.error);
				else {
					// setUnit((prev) => ({ ...prev, unit: "", id: 0 }));
					props.loadSubjects();
				}
				// console.log("response -- ", data);
			})
			.catch((error) => {
				loader();
				failedAlert(`Request failed. Please try again. ${error}`);
			});
	};
	return (
		<div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
			<div className={`relative popup-body w-[300px]`}>
				<XIcon
					className="bg-[#f7e3e3] text-[#f00] p-1 w-[20px] border-[#fdbcbc] border-[1px] rounded-full absolute top-[-10px] right-[-10px] cursor-pointer"
					onClick={() => props.setTPopup(false)}
				/>
				<p className="px-3 pt-2 font-semibold">
					Sub units for{" "}
					<span className="underline text-red-500">
						{`${subjectData?.subject} / ${subjectData?.alias}`}
					</span>
				</p>
				<p className="text-gray-400 px-3 pb-2">
					Value must be a number
				</p>
				<form
					className="flex items-center px-3 pb-3"
					onSubmit={saveunit}
				>
					<input type="hidden" name="id" value={unit.id} />
					<input
						type="number"
						name="unit"
						pattern="[0-9]"
						maxLength={2}
						className="rounded-lg w-1/2 py-1 px-2 bg-gray-100 border-2"
						placeholder="e.g 2"
						value={unit.unit}
						onChange={(event) =>
							setUnit((prev) => ({
								...prev,
								unit: event.target.value,
							}))
						}
						required
					/>
					<button>
						<ArrowRightIcon className="bg-gray-900 text-white w-7 p-2 ml-2 rounded-full" />
					</button>
				</form>
				<div className="px-3 pb-3">
					<table className="list-table text-xs w-full">
						<tbody>
							<tr className="text-left thead">
								<th>SUBJECT</th>
								<th>UNIT</th>
								<td className="w-[20px]"></td>
								<td className="w-[20px]"></td>
							</tr>
							{subjectData !== null &&
							subjectData.subject_units.length > 0 ? (
								subjectData.subject_units.map((obj) => (
									<tr data-id={obj.id} key={"unit-" + obj.id}>
										<td>{subjectData.subject}</td>
										<td>
											{subjectData.alias + " " + obj.unit}
										</td>
										<td className="relative">
											<PencilIcon className="w-5 bg-green-100 rounded-full p-1 text-green-800 border-[1px] border-green-800" />
											<div
												onClick={() => {
													setUnit((prev) => ({
														...prev,
														id: obj.id,
														unit: obj.unit,
													}));
												}}
												className="bg-transparent absolute w-full h-full cursor-pointer top-0 left-0"
											></div>
										</td>
										<td className="relative">
											{parseInt(obj.active) === 1 ? (
												<EyeIcon className="w-5 rounded-full p-[2px] textblack" />
											) : (
												<EyeOffIcon className="w-5 rounded-full p-[2px] textblack" />
											)}
											<div
												onClick={() =>
													toggleUnitStatus(obj)
												}
												className="bg-transparent absolute w-full h-full cursor-pointer top-0 left-0"
											></div>
										</td>
									</tr>
								))
							) : (
								<></>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default SubjectPapers;
