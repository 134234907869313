import React, { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import { XIcon } from "@heroicons/react/outline";

function StudentDownloadOptionPopup(props) {
	const {
		closeDownloadPopup,
		showDownloadDialogue,
		setShowDownloadDialogue,
	} = props;
	const { classes, token, api_route } = useContext(GlobalContext);

	return (
		<div
			className={`z-50 fixed w-full h-full top-0 left-0 ${
				showDownloadDialogue ? "flex" : "hidden"
			} justify-center items-center`}
			style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
		>
			<div className="wd w-full p-5 h-full" style={{ maxWidth: "440px" }}>
				<div className="bg-white w-full max-h-[90%] rounded-md flex flex-col overflow-hidden">
					<div className="flex items-center text-center font-bold px-3 py-2 border-b-[1px] border-c-[#ccc]">
						<p className="flex-grow">
							Choose a worksheet to download
						</p>
						<XIcon
							className="w-6 h-6 bg-red-100 text-red-500 rounded-full cursor-pointer p-1"
							onClick={() => setShowDownloadDialogue(false)}
						/>
					</div>
					<div className="overflow-auto flex-grow">
						<div className="flex px-5 py-2 items-center flex-wrap bg-gray-50">
							<p className="w-full flex-shrink-0 text-center pb-2 font-semibold">
								ENTIRE CLASS
							</p>
							{classes.map((cls, index) => (
								<a
									href={`${api_route}/sheet/downloadClass?classId=${cls.id}&token=${token}`}
									target="_blank"
									key={`class-opt-${index}`}
									className="w-[20%] flex flex-col items-center font-semibold py-2 hover:bg-gray-200 rounded-sm"
								>
									<img src="/img/xlsx.png" className="w-8" />
									{cls.alias}
								</a>
							))}
						</div>
						<div className="flex px-5 py-2 items-center flex-wrap bg-red-50">
							<p className="w-full flex-shrink-0 text-center pb-2 font-semibold">
								ENTIRE STREAM
							</p>
							{classes.map((cls, index) =>
								cls.streams.map((stream, index) => (
									<a
										href={`${api_route}/sheet/downloadClass?streamId=${stream.id}&token=${token}`}
										target="_blank"
										key={`class-opt-${index}`}
										className="hover:bg-red-200 font-semibold w-[20%] flex flex-col items-center text-center py-2 rounded-sm"
									>
										<img
											src="/img/xlsx.png"
											className="w-8"
										/>
										{`${cls.alias} ${stream.alias}`}
									</a>
								))
							)}
						</div>
						<div className="flex px-5 py-2 items-center flex-col flex-wrap">
							<p className="w-full flex-shrink-0 text-center pb-2 font-semibold">
								ENTIRE SCHOOL
							</p>
							<a
								href={`${api_route}/sheet/downloadClass?token=${token}`}
								target="_blank"
								className="hover:bg-blue-200 font-semibold flex flex-col items-center text-center p-2 rounded-sm"
							>
								<img src="/img/xlsx.png" className="w-8" />
								Workbook for all classes
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentDownloadOptionPopup;
