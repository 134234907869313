import React, { useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";

function MainHeader(props) {
	const { api_route, schoolData } = useContext(GlobalContext);
	// console.log(props, schoolData);
	// return <></>;
	return (
		<div className="border-b-2 flex items-center flex-wrap flex-shrink-0">
			<div className="flex-shrink-0 rounded-lg flex items-center justify-center w-[100px] h-[100px]">
				<img
					src={`${api_route}/images/${
						!schoolData.logo ? "icon-100.png" : schoolData.logo
					}`}
					className="object-cover min-w-[100%] min-h-[100%] max-w-[130%]"
					alt="school badge"
				/>
			</div>
			<div className="flex-grow flex flex-col text-center">
				<p className="text-[11px] font-bold text-[#444] pb-[-5px]">
					{props.schoolData.topper}
				</p>
				<p className="text-[20px] font-bold p-0">
					{props.schoolData.name}
				</p>
				<p className="text-[13px] font-bold">
					{props.schoolData.subtitle}
				</p>
				<p className="text-[12px] font-semibold text-[#444]">
					{props.schoolData.postal_address +
						" - " +
						props.schoolData.phonecontact}
				</p>
				{/* <p className="italic">"Ogutateganya"</p> */}
			</div>
			<div className="bg-gray-200 overflow-hidden flex-shrink-0 rounded-lg flex items-center justify-center w-[100px] h-[100px]">
				{props.photo ? (
					<img
						src={`${api_route}/images/${props.photo}`}
						className="object-cover min-w-[100%] min-h-[100%] max-w-[130%]"
						alt="student photo"
					/>
				) : (
					<p className="text-center text-md font-bold text-gray-400">
						NO PHOTO {props.photo}
					</p>
				)}
			</div>
			<p className="border-b-2 w-full pt-2 mb-[2px]"></p>
		</div>
	);
}

export default MainHeader;
