import React, { useContext } from "react";
import QRCode from "react-qr-code";
import { GlobalContext } from "../../../../GlobalContext";

function MainFooter(props) {
	const { api_route, schoolData } = useContext(GlobalContext);
	const { student, cls } = props;
	let class_teacher = "";
	if (cls.teachers && cls.teachers.length > 0) {
		let tr = cls.teachers[0];
		class_teacher =
			(tr.gender == "MALE" ? "MR. " : "MS. ") +
			tr.fname.toUpperCase() +
			" " +
			tr.lname.toUpperCase();
	}
	return (
		<div className="flex text-[10px] px-4 mt-8">
			<div className="w-1/2 pr-4 pb-4">
				<p className="flex items-center flex-wrap font-bold text-gray-800 border-b border-[#eee] pb-1 mb-1">
					<span className="mr-2">CLASS TEACHER'S COMMENT</span>
					<span className="text-[10px]">{class_teacher}</span>
				</p>
				<p>
					{/* Muyanja needs to do better. This performence isn't giving. */}
				</p>
			</div>
			<div className="px-4 pb-4 flex-grow flex-shrink-0">
				<p className="font-bold text-gray-800 border-b border-[#eee] pb-1 mb-1">
					HEAD TEACHER'S COMMENT
				</p>
				<p>
					{/* Muyanja needs to do better. This performence isn't giving. */}
				</p>
			</div>
			<div className="w-[80px] h-[80px] flex-shrink-0 overflow-hidden border-2 border-gray-300 rounded-lg flex items-center justify-center">
				{student && (
					<QRCode
						className="rounded-[4px]"
						value={`${api_route}/reports/${student.studentId}`}
						size={70}
					/>
				)}
			</div>
		</div>
	);
}

export default MainFooter;
