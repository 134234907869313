import {
	UserCircleIcon,
	UserIcon,
	XIcon,
	CameraIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { selectClasses } from "../redux/slice";
import { loader, successAlert } from "./vanilla/Dialogues";
import { GlobalContext } from "../GlobalContext";

function NewStudent(props, stateChanger) {
	const initialState = {
		id: "NULL",
		firstName: "",
		otherName: "",
		fullName: "",
		dob: "2023-01-01",
		email: "",
		_class: 0,
		stream: 0,
		gender: "MALE",
		image: null,
	};
	const [inputs, setInputs] = useState(initialState);
	const [profileImage, setProfileImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);

	const token = localStorage.getItem("token");
	// let classes = useSelector(selectClasses);
	const { classes, myAxios, api_route } = useContext(GlobalContext);
	const [selectedClass, setSelectedClass] = useState(0);
	const [selectedStream, setSelectedStream] = useState(null);
	const [studentImage, setStudentImage] = useState(null);

	const [streams, setStreams] = useState([]);

	// const [status, setStatus] = useState(
	//     props.className ? props.className : "hidden"
	// );
	const handleImage = (event) => {
		let image = event.target.files[0];
		if (image) {
			console.log(image);
			// var file = this.refs.file.files[0];
			var reader = new FileReader();
			var url = reader.readAsDataURL(image);

			reader.onloadend = function (e) {
				setImagePreview(reader.result);
				// console.log(topic);
			}.bind(this);
			// document.getElementById("image-preview").src =
			// 	window.URL.createObjectURL(image);
		}
	};

	const handleChange = (event) => {
		// console.log(event.target);
		setInputs((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};
	const saveStudentRecord = (event) => {
		event.preventDefault();
		console.log(inputs);
		loader(1);
		// constructing form data
		const fd = new FormData();
		for (const key in inputs) {
			fd.append(key, inputs[key]);
		}
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/students/register", inputs, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				// if()
				console.log("register - data - ", response.data);
				// setStreams(response.data);
				props.getStudents();
				setStudentImage(null);
				setInputs(initialState);
				loader();
			})
			.catch((error) => {
				console.log(error);
				loader();
			});
	};

	// console.log(props.selectedStudent);
	useEffect(() => {
		if (!props.showWindow) setStudentImage(null);
		console.log("selected student", props.selectedStudent);
		if (
			props.selectedStudent !== null &&
			props.selectedStudent !== undefined
		) {
			console.log(JSON.parse(props.selectedStudent));
			let data = JSON.parse(props.selectedStudent);
			setInputs({
				id: data.id,
				firstName: data.firstName,
				otherName: data.otherName,
				fullName: data.fullName,
				dob: data.dob,
				email: data.email,
				_class: data.class === null ? 0 : data.class.id,
				stream: data.stream === null ? 0 : data.stream.id,
				// stream: data.stream?.id,
				gender: data.gender,
			});
			setSelectedStream(data.stream === null ? 0 : data.stream.id);
			setSelectedClass(data.class === null ? 0 : data.class.id);
			setInputs((prev) => ({ ...prev, image: null }));
			setStudentImage(data.picture);
		} else {
			console.log("The else part");
			setStudentImage(null);
			setInputs(initialState);
		}
	}, [props.showWindow]);
	useEffect(() => {
		if (selectedClass === 0) return;
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/marksheets/classes/getStreams", { id: selectedClass })
			.then((results) => {
				// console.log("Streams", response);
				let response = results.data;
				setStreams(response.data);
				loader();
				// if (!isNaN(inputs["id"])) successAlert(response.msg);
			})
			.catch((error) => {
				loader();
			});
		console.log("Class", selectedClass);
	}, [selectedClass]);
	useEffect(() => {
		console.log(inputs);
	}, [inputs]);
	return (
		<div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
			<div className={`relative popup-body w-[500px]`}>
				<form
					method="post"
					onSubmit={saveStudentRecord}
					className="flex flex-col p-3 relative"
				>
					<input type="hidden" name="id" value={inputs["id"]} />
					<XIcon
						className="absolute top-2 right-2 w-6 cursor-pointer text-red-600 bg-red-200 rounded-full border-red-400 p-1"
						onClick={() => {
							// successAlert();
							setStudentImage(null);
							setInputs(initialState);
							props.stateChanger(false);
						}}
					/>
					<div className="mx-auto w-[120px] h-[120px] bg-gray-300 rounded-full mt-4 mb-2 overflow-hidden flex justify-center items-center relative">
						<img
							src={
								!studentImage
									? `/android-chrome-512x512.png`
									: `${api_route}/images/${studentImage}`
							}
							className="absolute max-w-[120%] min-w-[100%] min-h-[100%]"
						/>
						<CameraIcon className="w-14 text-center text-white mx-auto bg-black rounded-full p-3 opacity-[0.5]" />
						<input
							type="file"
							name="image"
							className="absolute w-full h-full inset-0 cursor-pointer opacity-0"
							onChange={(event) => {
								event.target.parentNode.children[0].src =
									window.URL.createObjectURL(
										event.target.files[0]
									);
								setInputs((prev) => ({
									...prev,
									image: event.target.files[0],
								}));
							}}
							accept="image/*"
						/>
					</div>
					<p className="text-center text-lg mb-2">
						Student Registration
					</p>
					<div className="flex items-center gap-4">
						<div className="w-1/3">
							<p className="font-semibold">First Name</p>
							<input
								type="text"
								name="firstName"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["firstName"]}
								required
							/>
						</div>
						<div className="w-1/3">
							<p className="font-semibold">Other Name (s)</p>
							<input
								name="otherName"
								type="text"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["otherName"]}
								required
							/>
						</div>
						<div className="w-1/3">
							<p className="font-semibold">
								Full name
								<span className="text-[#ccc]">
									(proper order)
								</span>
							</p>
							<input
								name="fullName"
								type="text"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["fullName"]}
								required
							/>
						</div>
					</div>
					<div className="flex items-center gap-4 mt-3">
						<div className="w-1/2">
							<p className="font-semibold">Date of Birth</p>
							<input
								type="date"
								name="dob"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["dob"]}
							/>
						</div>
						<div className="w-1/2">
							<p className="font-semibold">Email</p>
							<input
								name="email"
								type="text"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["email"]}
							/>
						</div>
					</div>
					<div className="flex items-center gap-4 mt-3">
						<div className="w-1/3">
							<p className="font-semibold">Class</p>
							<select
								value={selectedClass}
								onChange={(event) => {
									setSelectedClass(event.target.value);
									handleChange(event);
								}}
								name="_class"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
							>
								{classes.map((obj, key) => (
									<option
										key={`class-${key}`}
										value={obj.id}
										title={obj.title}
										{...(selectedClass === obj.classId && {
											selected: true,
										})}
									>
										{obj.alias}
									</option>
								))}
							</select>
						</div>
						<div className="w-1/3">
							<p className="font-semibold">Stream</p>
							<select
								value={selectedStream}
								onChange={(event) => {
									setSelectedStream(event.target.value);
									handleChange(event);
								}}
								name="stream"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
							>
								<option value={0}>Select Stream</option>
								{streams.map((obj, key) => (
									<option
										value={obj.id}
										key={`stream-${obj.id}`}
									>
										{obj.stream}
									</option>
								))}
							</select>
						</div>
						<div className="w-1/3">
							<p className="font-semibold">Gender</p>
							<select
								name="gender"
								className="p-2 py-1 border-[1px] border-[#eee] bg-[#f7f7f7] w-full rounded-md mt-1"
								onChange={handleChange}
								value={inputs["gender"]}
							>
								<option value="MALE">Male</option>
								<option value="FEMALE">Female</option>
							</select>
						</div>
					</div>
					<button
						type="submit"
						className="bg-[#1e8357] py-2 rounded-md text-white mt-2 font-bold"
					>
						SAVE
					</button>
				</form>
			</div>
		</div>
	);
}

export default NewStudent;
