import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
	faCaretRight,
	faAngleRight,
	faFolder,
} from "@fortawesome/free-solid-svg-icons";
import ContextMenu from "./components/ContextMenu";
import UnderConstruction from "../../components/UnderConstruction";

function FileManager() {
	const files = [];
	for (let i = 0; i < 3; i++) {
		files.push({ name: Math.random() });
	}
	const [showWindow, setShowWindow] = useState(false);
	const [cmenuX, setcmenuX] = useState(0);
	const [cmenuY, setcmenuY] = useState(0);
	useEffect(() => {
		// document
		// 	.getElementById("fmCanvas")
		// 	.children[0].addEventListener("contextmenu", function (event) {
		// 		setcmenuX(event.pageX);
		// 		setcmenuY(event.pageY);
		// 		event.preventDefault();
		// 		setShowWindow(true);
		// 	});
	}, []);
	return <UnderConstruction />;
	return (
		<div className="h-full flex flex-col">
			<ContextMenu
				showWindow={showWindow}
				setShowWindow={setShowWindow}
				x={cmenuX}
				y={cmenuY}
			/>
			<div className="border-b border-gray-200">
				<div className="mx-auto px-4 pt-3 flex flex-col">
					<p className="font-semibold pb-2">
						Filemanager (2GB total space)
					</p>
				</div>
				<div className="w-[300px] bg-gray-300 ml-4 mb-4 mt-2 flex opacity-70">
					<div className="h-1 bg-red-500 w-[30%] relative rounded-l-full">
						<p className="absolute b-2 top-[-16px] text-[10px] font-semibold">
							Used (0.6GB)
						</p>
					</div>
					<div className="h-1 bg-green-500 w-[70%] relative rounded-l-full">
						<p className="absolute right-0 top-[-16px] text-[10px] font-semibold text-gray-700">
							Available (1.4GB)
						</p>
					</div>
				</div>
			</div>
			<div className="overflow-hidden flex-grow flex flex-col">
				<div className="border-b border-gray-200 p-2 flex items-center">
					<Link to="file/3" className="text-xs">
						root
					</Link>
					<FA
						icon={faAngleRight}
						className="mx-2 w-1 h-2 text-gray-800"
					/>
					<Link to="file/3" className="text-xs">
						New folder
					</Link>
				</div>
				<div id="fmCanvas" className="flex-grow overflow-y-auto">
					<div className="flex flex-wrap min-h-full">
						{files.map((file, index) => (
							<div
								key={index}
								className="py-3 m-1 px-1 pb-3 w-[80px] h-[80px] overflow-hidden bg-transparent relative rounded-md cursor-pointer"
								title="Hello there"
							>
								<FA
									icon={faFolder}
									className="w-[40px] h-[40px] block mx-auto text-[#777]"
								/>
								<p className="text-[11px] select-none mb-2 text-center block">
									{file.name}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default FileManager;
