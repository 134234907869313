import {
    PencilAltIcon,
    PencilIcon,
    PlusCircleIcon,
    UserIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import TeacherPicker from "../../../components/TeacherPicker";
import TimeInterval from "../../../components/TimeInterval";
import {
    loader,
    customConfirm,
    failedAlert,
    customPrompt,
} from "../../../components/vanilla/Dialogues";

function NewTimetable() {
    const [intervals, setIntervals] = useState([
        { interval: "11:00 AM - 1:00 PM", id: 21 },
    ]);
    const [selectedInterval, setSelectedInterval] = useState(0);
    const [popupstatus, setPopupStatus] = useState(false);
    const [newInterval, setNewInterval] = useState("");
    useEffect(() => {
        if (newInterval.length == 0) {
            loader(0);
            return;
        }
        // store this in the database
        loader(1);
        setIntervals((prev) => [
            ...prev,
            { interval: newInterval, id: Math.random() },
        ]);
        window.setInterval(() => {
            setNewInterval("");
        }, 2000);
    }, [newInterval]);
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const [activities, setActivities] = useState([]);
    useEffect(() => {
        console.log(activities);
    }, [activities]);

    const [tPopup, setTPopup] = useState(true);
    return (
        <>
            <TeacherPicker showWindow={tPopup} setTPopup={setTPopup} />
            <TimeInterval
                onchange={setNewInterval}
                show={popupstatus}
                changeStatus={setPopupStatus}
            />
            <p className="font-bold">Time Intervals</p>
            <div className="flex flex-wrap gap-2 mt-2">
                <PlusCircleIcon
                    onClick={() => setPopupStatus(true)}
                    className="w-[30px] h-[30px] text-[#0361b8] bg-[#eee] rounded-full p-1 cursor-pointer"
                />
                {intervals.map((obj, index) => (
                    <div
                        key={index}
                        className={`${
                            selectedInterval == index
                                ? "bg-[#0361b8] text-white"
                                : "bg-[#eee] text-black"
                        }  px-2 py-1 rounded-md cursor-pointer`}
                        onClick={() => {
                            // customConfirm("Are you alright?");
                            failedAlert("Are you alright?");
                            // customPrompt({ title: "Activity" });
                        }}
                    >
                        {obj.interval}
                    </div>
                ))}
            </div>
            {/* <p className="font-bold text-[#222] mt-6 mb-2">Time Intervals</p> */}
            <p className="font-bold text-[#222] mt-6 ">
                Lessons / Activities for{" "}
                <span className="text-red-500">(11:30 - 12:00)</span>{" "}
            </p>
            <p className="font-semibold text-[#888] mb-2">
                Click a day to start editing
            </p>
            <div className="flex flex-wrap gap-4 mt-2"></div>
            <table className="bare-table white-borders border-2 w-full table-checked">
                <thead>
                    <tr>
                        {days.map((day, index) => (
                            <th className="relative" key={index}>
                                {day}
                                <div className="absolute top-[-12px] flex items-center justify-center gap-2 w-full">
                                    <PencilIcon
                                        data-day={index}
                                        onClick={async (event) => {
                                            let day = event.target.dataset.day;
                                            console.log(day);
                                            let x = await customPrompt({
                                                title: "Enter activity below.",
                                            })
                                                .then((title) => {
                                                    if (
                                                        title.replace(/ /gi, "")
                                                            .length > 0
                                                    ) {
                                                        setActivities(
                                                            (prev) => [
                                                                ...prev,
                                                                {
                                                                    title,
                                                                    id: Math.random(),
                                                                    day,
                                                                },
                                                            ]
                                                        );
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log("Here");
                                                });
                                            // console.log(x);
                                        }}
                                        className="text-white bg-[#000] rounded-full p-1 w-[20px]"
                                    />
                                    <UserIcon
                                        onClick={() => setTPopup(true)}
                                        className="text-white bg-[#df4c08] rounded-full p-1 w-[20px]"
                                    />
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="odd:bg-[#eee]">
                        {days.map((day, index) => (
                            <td
                                key={`data${index}`}
                                className="odd:bg-transparent even:bg-[#e4e4e4] text-center font-semibold text-[#333] min-h-[100px]"
                            >
                                {activities.map((activity) =>
                                    activity.day == index ? (
                                        <div
                                            key={`activity-${activity.day}-${activity.id}`}
                                        >
                                            {activity.title}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default NewTimetable;
