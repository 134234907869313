import {
	ArrowCircleRightIcon,
	EyeIcon,
	PencilAltIcon,
	PlusIcon,
	XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { customConfirm, loader } from "../../../components/vanilla/Dialogues";
import { GlobalContext } from "../../../GlobalContext";

function ReportTemplates({
	setChosenTemplate,
	chosenTemplate,
	setOpenTemplates,
	openTemplates,
}) {
	const { api_route, token, myAxios } = useContext(GlobalContext);
	const [showPreview, setShowPreview] = useState(false);
	const [previewSrc, setPreviewSrc] = useState("/favicon-16x16.png");
	const [templates, setTemplates] = useState({});
	const previewTemplate = (src) => {
		loader(1);
		const img = document.createElement("img");
		img.onload = () => {
			setPreviewSrc(src);
			loader();
		};
		img.src = src;
		setShowPreview(true);
	};

	const editTemplate = (template) => {
		customConfirm(
			"You're going to be redirected to the report maker. Proceed with action??"
		).then(() => {
			window.location = template;
		});
	};

	useEffect(() => {
		loader(1);
		myAxios
			.post("/reportMaker/getAllTemplates")
			.then((response) => {
				setTemplates(response.data);
				// console.log(response.data);
				loader(0);
			})
			.catch((error) => {
				console.log(error);
				loader(0);
			});
	}, []);

	return (
		<div className={`h-full ${openTemplates ? "flex" : "hidden"} flex-col`}>
			<div className="flex-grow overflow-y-auto overflow-x-hidden">
				<p className="text-center py-5 text-2xl text-[#444] underline">
					Choose a template and proceed
				</p>
				<div className="flex flex-wrap">
					<div className="w-[130px]">
						<div
							className="h-[180px] m-2 rounded-[4px] flex flex-col overflow-hidden shadow-lg cursor-pointer hover:scale-[1.05]"
							onClick={() =>
								editTemplate(
									`${api_route}/reportMaker/make?token=${token}`
								)
							}
						>
							<div className="flex-grow overflow-hidden p-1 flex flex-col">
								<div className="border-dashed border-[2px] rounded-[10px] border-[#ccc] flex-grow flex flex-col items-center justify-center">
									<PlusIcon className="w-[40px]" />
								</div>
							</div>
							<div className="py-2 px-3">
								<p className="text-[11px] text-center font-bold">
									New template
								</p>
							</div>
						</div>
					</div>
					{templates?.data &&
						templates.data.map((template) => (
							<div className="w-[130px]" key={template.template}>
								<div
									className={`h-[180px] cursor-pointer hover:scale-[1.05] m-2 rounded-[4px] flex flex-col overflow-hidden shadow-lg report-template-thumb ${
										chosenTemplate?.template ==
										template.template
											? "shadow-[#673463]"
											: ""
									}`}
								>
									<div className="action-buttons flex flex-col justify-center items-center">
										<div
											className="flex items-center text-white bg-[#00a2ff93] px-2 rounded-[3px] py-[2px] mb-1 w-[90px] cursor-pointer"
											onClick={() =>
												previewTemplate(
													`${api_route}/${template.screenshot}`
												)
											}
										>
											<EyeIcon className="w-4 text-white" />
											<p className="ml-2 text-[11px]">
												Preview
											</p>
										</div>
										<div
											className="flex items-center text-white bg-[#036b6293] px-2 rounded-[3px] py-[2px] mb-1 w-[90px] cursor-pointer"
											onClick={() =>
												editTemplate(
													`${api_route}/reportMaker/make/${template.template}?templateId=${template.template}&token=${token}`
												)
											}
										>
											<PencilAltIcon className="w-4 text-white" />
											<p className="ml-2 text-[11px]">
												Edit
											</p>
										</div>
										<div
											onClick={() => {
												setChosenTemplate(template);
											}}
											className="flex items-center text-white bg-[#00000093] px-2 rounded-[3px] py-[2px] mb-1 w-[90px] cursor-pointer"
										>
											<ArrowCircleRightIcon className="w-4 text-white" />
											<p className="ml-2 text-[11px]">
												choose
											</p>
										</div>
									</div>
									<div className="flex-grow overflow-hidden">
										<img
											src={`${api_route}/${template.screenshot}`}
											className="object-cover w-full h-full"
										/>
									</div>
									<div className="py-2 px-3">
										<p className="font-bold text-[11px] text-center">
											{template.template}
										</p>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
			<div className="py-6 flex flex-col border-t-[1px] border-[#ccc]">
				<div className="flex items-center justify-center">
					<a
						href="#"
						className="rounded-[4px] bg-[#2279ec] text-white py-3 px-6 block w-fit active:scale-[1.05]"
					>
						Get a free template
					</a>
					{chosenTemplate && (
						<div
							onClick={() => {
								setOpenTemplates(false);
							}}
							className="rounded-[4px] cursor-pointer bg-[#050c16] text-white py-3 px-6 ml-3 block w-fit active:scale-[1.05]"
						>
							Proceed with template
						</div>
					)}
				</div>
				<p className="max-w-[600px] text-[#777] text-[11px] font-bold mt-2 mx-auto text-center">
					You can preview our collection and choose a template of your
					choice. These templates are designed by our technicians and
					are free of charge.
				</p>
			</div>

			{/* POPUP */}
			<div
				className={`reportTemplatePreviewOv flex justify-center items-center z-50 ${
					showPreview ? "" : "hidden"
				}`}
			>
				<div className="relative max-w-[350px] max-h-[90%] bg-white overflow-hidden">
					<XIcon
						className="z-50 w-[25px] h-[25px] p-[5px] bg-[#ffe4e4] text-[#f00] rounded-full absolute right-[10px] top-[10px] cursor-pointer"
						onClick={() => setShowPreview(false)}
					/>
					<img src={previewSrc} className="object-contain h-full" />
				</div>
			</div>
		</div>
	);
}

export default ReportTemplates;
