import React, { useState } from "react";
import { Link, Routes, Route } from "react-router-dom";

import PageSummary from "../Students/PageSummary";
import Marksheets from "../Students/Marksheets";
import Reports from "../Students/Reports";
import Teachers from "../Students/Teachers";
import Students from "../Students/Students";
import Payroll from "./Payroll";
import Fees from "./Fees";
import UnderConstruction from "../../components/UnderConstruction";
import Schemes from "./Schemes";

function StudentMan() {
	const [active_subpage, setActive_subpage] = useState("Home");
	const changeNav = (e) => {
		setActive_subpage(e.target.innerText);
	};
	// return <UnderConstruction />;
	return (
		<>
			<div className="border-b border-gray-200">
				<div className="mx-auto max-w-[1300px] px-4 pt-3 flex flex-col">
					<p className="font-semibold pb-2">Finance management</p>
					<nav className="text-sm flex">
						<div
							className={`pb-1 border-b-2 ${
								active_subpage == "Home"
									? "border-[#f60]"
									: "border-transparent"
							} mx-3 px-1`}
						>
							<Link to="schemes" onClick={changeNav}>
								Schemes
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Payroll"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="payroll" onClick={changeNav}>
								Payroll
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Payroll"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="payroll" onClick={changeNav}>
								All staff
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Payroll"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="payroll" onClick={changeNav}>
								Cashflow
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Fees Payment"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="fees" onClick={changeNav}>
								Fees clearance
							</Link>
						</div>
					</nav>
				</div>
			</div>
			<div className="overflow-y-auto flex-grow">
				<Routes>
					<Route path="schemes" element={<Schemes />} />
					<Route path="payroll" element={<Payroll />} />
					<Route path="fees" element={<Fees />} />
				</Routes>
			</div>
		</>
	);
}

export default StudentMan;
