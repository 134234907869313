import React, { useContext } from "react";
import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";
import PrintPreview from "../PrintPreview";
import { GlobalContext } from "../../../../GlobalContext";
import { useParams } from "react-router-dom";

function PrimaryTwoSets(props) {
	const { sheetData } = props;
	const { schoolData, api_route } = useContext(GlobalContext);
	const { page, perpage } = useParams();
	console.log("sheetData");
	console.log(sheetData);
	const cls = sheetData.class;
	const subjects = sheetData.subjects;
	const term = sheetData.term;
	const students = sheetData.students;
	const assessments = sheetData.assessments;
	const grading = sheetData.grading;
	const initials = sheetData.initials;

	const RowComponent = (props) => {
		const { subject, score, grd } = props;
		let teacher_initials = "";

		if (!score) return <></>;

		initials.map((tr) => {
			if (tr.subjectId == subject.id) teacher_initials = tr.initials;
		});
		let showGrade = score.score == null ? false : true;

		return (
			<tr className="text-xs text-center">
				<th align="left">
					{subject.subject.length > 10
						? subject.alias
						: subject.subject}
				</th>
				<td>{score.score != null ? score.score : "-"}</td>
				<td>{showGrade ? grd?.symbol : "-"}</td>
				<td className="text-xs text-left">
					{showGrade ? grd?.remark : "-"}
				</td>
				<td className="text-xs text-[#f00] text-center">
					{teacher_initials}
				</td>
			</tr>
		);
	};

	const data = [];
	for (let x = 0; x < 7; x++) {
		let d = [
			{ score: 29, grade: "F9" },
			{ score: 56, grade: "C6" },
			{ score: 68, grade: "C3" },
			{ score: 99, grade: "D1" },
		];
		data.push(d);
	}
	return (
		<PrintPreview>
			{students.map((student, index) => {
				if (
					index + 1 >= page * perpage - 10 &&
					index + 1 <= page * perpage
				) {
					let total_score = 0;
					let total_grade = 0;
					return (
						<div className="mx-auto report-a4-height rounded-[4px] p-6 flex flex-col">
							<MainHeader
								photo={student.picture}
								schoolData={props.schoolData}
							/>
							<div className="flex-grow flex items-center justify-center relative">
								<div className="absolute h-2/3 w-2/3 flex items-center justify-center">
									<img
										draggable={false}
										src={`${api_route}/images/${
											!props.schoolData.logo
												? "icon-100.png"
												: props.schoolData.logo
										}`}
										className="object-cover opacity-[0.09] min-w-[100%] min-h-[100%] max-w-[140%]"
										alt="school badge"
									/>
								</div>
								<div className="absolute inset-0 h-full w-full flex flex-col pb-2">
									<div className="flex-grow">
										<div className="flex items-center flex-wrap">
											<div className="w-full">
												<p className="text-lg text-center font-bold my-2 text-red-400">
													TERM{" "}
													{term.term == 1
														? "ONE"
														: term.term == 2
														? "TWO"
														: "THREE"}{" "}
													{" " + term.year}{" "}
													CUMMULATIVE REPORT CARD
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>
														NAME: {student.fullName}
													</b>
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>CLASS: </b> {cls.class} (
													{cls.alias})
												</p>
											</div>
											<div className="w-1/3">
												<p>
													<b>
														TERM{" "}
														{term.term == 1
															? "ONE"
															: term.term == 2
															? "TWO"
															: "THREE"}{" "}
														{" " + term.year}
													</b>
												</p>
											</div>
										</div>
										<div className="flex">
											<div className="w-1/2 flex flex-col">
												<p className="mt-3 mb-2 mr-2 text-center font-bold text-red-400">
													{assessments[0].title?.toUpperCase()}{" "}
													ASSESSMENT
												</p>
												<table className="bare-table isolated-borders text-[#222] text-[12px] mr-3 mb-1">
													<thead>
														<tr className="bg-[#eee]">
															<th className="border-[0px] text-left">
																SUBJECT
															</th>
															<th>SCORE</th>
															<th>GRADE</th>
															<th>REMARKS</th>
															<th>INITIALS</th>
														</tr>
													</thead>
													<tbody>
														{subjects.map(
															(
																subject,
																index
															) => {
																let scores =
																	student
																		.assessments[0]
																		.scores;
																let score, grd;
																scores.map(
																	(sc) => {
																		if (
																			sc.subjectId ==
																			subject.id
																		)
																			score =
																				sc;
																	}
																);
																if (score)
																	total_score +=
																		score.score;
																if (
																	grading &&
																	score
																) {
																	grading.map(
																		(g) => {
																			if (
																				score.score >=
																					g.min &&
																				score.score <=
																					g.max &&
																				score.score !=
																					null
																			) {
																				grd =
																					g;
																				total_grade +=
																					g.value;
																			}
																		}
																	);
																}
																return (
																	<RowComponent
																		score={
																			score
																		}
																		grd={
																			grd
																		}
																		subject={
																			subject
																		}
																	/>
																);
															}
														)}
														<tr>
															<th align="left">
																TOTAL
															</th>
															<th>
																{total_score}
															</th>
															<th>
																{total_grade}
															</th>
														</tr>
														<span className="hidden">
															{total_grade > 0
																? (total_grade = 0)
																: (total_grade = 0)}
															{total_grade > 0
																? (total_grade = 0)
																: (total_grade = 0)}
														</span>
													</tbody>
												</table>
												<div className="mr-3 my-4 text-[11px] flex items-center font-bold text-green-800">
													<p className="flex-grow pr-3 text-left">
														AGG: 8
													</p>
													<p className="flex-grow px-3 text-left">
														CLASS POS: 4/25
													</p>
													<p className="flex-grow px-3 text-left">
														STREAM POS: 1/14
													</p>
													<p className="flex-grow px-3 text-left">
														DIV: I
													</p>
												</div>
												<p className="mr-2 mb-2 font-bold text-[11px]">
													OTHER SUBJECTS
												</p>
												<table className="bare-table mr-3 mb-3 text-gray-800 text-[11px]">
													<thead>
														<tr className="bg-[#eee]">
															<th className="w-[100px] border-[0px]">
																SUBJECT
															</th>
															<th>SCORE</th>
															<th>GRADE</th>
															<th>COMMENT</th>
														</tr>
													</thead>
													{data.map((d, index) => {
														if (index > 4)
															return (
																<tr key={index}>
																	<th align="left">
																		SUBJECT
																	</th>
																	<th>79</th>
																	<th>C4</th>
																	<th>
																		EXCELLENT
																	</th>
																</tr>
															);
													})}
												</table>
											</div>
											<div className="w-1/2 flex flex-col">
												<p className="mt-3 mb-2 mr-2 text-center font-bold text-red-400">
													{assessments[1].title?.toUpperCase()}{" "}
													ASSESSMENT
												</p>
												<table className="bare-table isolated-borders text-[#222] text-[12px] ml-3 mb-1">
													<thead>
														<tr className="bg-[#eee]">
															<th className="border-[0px] text-left">
																SUBJECT
															</th>
															<th>SCORE</th>
															<th>GRADE</th>
															<th>REMARKS</th>
															<th>INITIALS</th>
														</tr>
													</thead>
													<tbody>
														{subjects.map(
															(
																subject,
																index
															) => {
																let scores =
																	student
																		.assessments[1]
																		.scores;
																let score, grd;
																scores.map(
																	(sc) => {
																		if (
																			sc.subjectId ==
																			subject.id
																		)
																			score =
																				sc;
																	}
																);
																if (score)
																	total_score +=
																		score.score;
																if (
																	grading &&
																	score
																) {
																	grading.map(
																		(g) => {
																			if (
																				score.score >=
																					g.min &&
																				score.score <=
																					g.max &&
																				score.score !=
																					null
																			) {
																				grd =
																					g;
																				total_grade +=
																					g.value;
																			}
																		}
																	);
																}
																return (
																	<RowComponent
																		grd={
																			grd
																		}
																		score={
																			score
																		}
																		subject={
																			subject
																		}
																	/>
																);
															}
														)}
														<tr>
															<th align="left">
																TOTAL
															</th>
															<th>
																{total_score}
															</th>
															<th>
																{total_grade}
															</th>
														</tr>
													</tbody>
												</table>
												<div className="ml-3 my-4 text-[11px] flex items-center font-bold text-green-800">
													<p className="flex-grow pr-3 text-left">
														AGG: 8
													</p>
													<p className="flex-grow px-3 text-left">
														CLASS POS: 4/25
													</p>
													<p className="flex-grow px-3 text-left">
														STREAM POS: 1/14
													</p>
													<p className="flex-grow px-3 text-left">
														DIV: I
													</p>
												</div>
												<p className="ml-3 mb-2 font-bold text-[11px]">
													OTHER SUBJECTS
												</p>
												<table className="bare-table ml-3 mb-3 text-gray-800 text-[11px]">
													<thead>
														<tr className="bg-[#eee]">
															<th className="w-[100px] border-[0px]">
																SUBJECT
															</th>
															<th>SCORE</th>
															<th>GRADE</th>
															<th>COMMENT</th>
														</tr>
													</thead>
													{data.map((d, index) => {
														if (index > 4)
															return (
																<tr>
																	<th align="left">
																		SUBJECT
																	</th>
																	<th>79</th>
																	<th>C4</th>
																	<th className="text-xs">
																		EXCELLENT
																	</th>
																</tr>
															);
													})}
												</table>
											</div>
										</div>
										<div className="flex flex-wrap">
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold">
														CONDUCT
													</p>
													<p>
														Well behaved Well
														behaved Well behaved
													</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">
														ATTENDANCE
													</p>
													<p>
														Well behaved Well
														behaved Well behaved
													</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">
														SMARTNESS
													</p>
													<p>
														Well behaved Well
														behaved Well behaved
													</p>
												</div>
											</div>
											<div className="w-[25%] flex-shrink-0">
												<div className="pr-2">
													<p className="font-bold mt-2">
														PUNCTUALITY
													</p>
													<p>
														Well behaved Well
														behaved Well behaved
													</p>
												</div>
											</div>
										</div>
									</div>
									<GradingScale grading={grading} />
								</div>
							</div>
							<MainFooter student={student} cls={cls} />
						</div>
					);
				}
			})}
		</PrintPreview>
	);
}

export default PrimaryTwoSets;
