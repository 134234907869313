import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { failedAlert, loader, successAlert, warningAlert } from "../../../components/vanilla/Dialogues";

import { selectClasses } from "../../../redux/slice";
import "./css/sheet.css";
import { GlobalContext } from "../../../GlobalContext";
import MarksheetDownloadPopup from "./components/MarksheetDownloadPopup";

function Sheets() {
	const token = localStorage.getItem("token");
	const [rowLimit, setRowLimit] = useState(35);
	const { classes, setClasses, myAxios, myAccount, allTerms, schoolData } = useContext(GlobalContext);
	const [showMArksheetPopup, setShowMarksheetPopup] = useState(false);
	const [selectedClass, setSelectedClass] = useState(0);
	const [assessments, setAssessments] = useState(null);
	const [selectedAssessment, setSelectedAssessment] = useState(0);
	const [selectedStream, setSelectedStream] = useState(0);
	const [selectedYear, setSelectedYear] = useState(0);
	const [selectedTermId, setSelectedTermId] = useState(0);
	const [alteredScores, setAlteredScores] = useState([]);
	const initialFormData = {
		classId: 0,
		streamId: 0,
		assessmentId: [],
		name: "",
		termId: 0,
		year: 0,
	};
	const [formData, setFormData] = useState(initialFormData);
	const initialSheetData = {
		scores: [],
		subjects: [],
		students: [],
		totalStudents: 0,
	};
	const [sheetData, setSheetData] = useState(initialSheetData);
	const [sheetScores, setSheetScores] = useState([]);
	const [pageCount, setPageCount] = useState(1);

	const getScore = (student, subject) => {
		sheetData.scores.map((score) => {
			// console.log("Here ", student, subject, score);
			if (student.id == score.studentId && subject.id == score.subjectId) {
				console.log("Found", score);
				return score;
			}
		});
		return;
	};
	const setScoreInSheet = () => {
		// "cell-" + student.id + "-" + subject.id;
		sheetData.scores.map((obj) => {
			setSheetScores((prev) => ({
				...prev,
				[obj.subject_unit ? "cell_" + obj.studentId + "_" + obj.subjectId + "_" + obj.subject_unit.id : "cell_" + obj.studentId + "_" + obj.subjectId]: obj.score ? obj.score : "-",
			}));
		});
		// console.log(sheetScores);
	};
	const handleSheetUpdate = (event) => {
		setSheetScores((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};
	useEffect(() => {
		if (assessments) setScoreInSheet();
	}, [sheetData]);

	let mySubjectIds = [];
	myAccount?.teacher_subjects?.map((sb) => mySubjectIds.push(sb.subjectId));
	let allSubjects = sheetData.subjects;
	let excluded = [];
	sheetData.subjects = [];
	allSubjects.map((sb, index) => {
		if (mySubjectIds.includes(parseInt(sb.id))) {
			excluded.push(sb.subjectId);
			sheetData.subjects.push(sb);
		}
	});
	allSubjects.map((sb) => {
		if (!mySubjectIds.includes(parseInt(sb.id))) sheetData.subjects.push(sb);
	});

	const getMarkSheet = (event) => {
		event && event.preventDefault();
		if (parseInt(formData.year) == 0) {
			failedAlert("Please select year on the status bar");
			return;
		}
		if (parseInt(formData.termId) == 0) {
			failedAlert("Please select term as well");
			return;
		}
		setAlteredScores([]);
		console.log(formData);
		if (formData.assessmentId.length === 0) failedAlert("Please select assessment");
		else if (isNaN(formData.classId) || parseInt(formData.classId) == 0) failedAlert("Please select class");
		else if (formData.classId === 0) failedAlert("Please select class");
		else {
			loader(1);
			// const url = `${process.env.REACT_APP_API_URL}`;
			myAxios
				.post("/sheet/getSheet", {
					...formData,
					studentName: formData["name"],
					classId: selectedClass,
					rowLimit,
					pageCount,
				})
				.then((result) => {
					let response = result.data;
					console.log(response);
					setSheetData(response);

					// if (response.students.length == 0) setPageCount(0);
					// else setPageCount(1);

					loader();
				})
				.catch((error) => {
					failedAlert("A problem occured.");
					// console.log("error");
					loader();
				});
		}
	};
	const formHandler = (event) => {
		setFormData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};

	useEffect(() => {
		loader(1);
		// const url = `${process.env.REACT_APP_API_URL}`;
		myAxios
			.post("/findByPK")
			.then((response) => {
				let data = response.data;
				loader();
				// console.log("response -- ", response);
				console.log("Initial assessment: ", data[0].id);

				setAssessments(data);
				setFormData((prev) => ({
					...prev,
					assessmentId: [data[0].id],
				}));
				setSelectedAssessment(data[0].id);
			})
			.catch((error) => {
				loader();
				console.log(error);
				failedAlert(`Request failed. Please try again.`);
			});
		if (classes.length) {
			setSelectedClass(classes[0].id);
			setSelectedAssessment(allTerms[0].year);
			setFormData((prev) => ({
				...prev,
				year: schoolData.term.year.toString(),
				classId: classes[0].id,
				termId: schoolData.term.id,
			}));
			setSelectedYear(schoolData.term.year.toString());
			setSelectedTermId(schoolData.term.id);
			console.log("The year is: ", allTerms[0].year);
			console.log("School data: ", schoolData);
		}
		// console.log(classes);
	}, []);

	useEffect(() => {
		console.log("Row Limit ", rowLimit);
		console.log(alteredScores, sheetData);
	}, [alteredScores, sheetData, rowLimit]);

	useEffect(() => {
		console.log(formData, allTerms);
	}, [formData]);

	const saveScore = (studentId, subjectId, subjectUnitId, score, streamId, classId) => {
		let f = -1;
		let x = alteredScores.map((sc, index) => {
			if (sc.studentId == studentId && sc.subjectId == subjectId && sc.classId == classId) {
				console.log("Found ", index);
				f = index;
				let obj = {
					studentId,
					subjectId,
					score,
					classId,
					selectedAssessment,
				};
				// let x = [...alteredScores];
				let ar = alteredScores.splice(index, 1);
				setAlteredScores((prev) => [...alteredScores, obj]);
			}
		});
		if (f == -1) {
			setAlteredScores((prev) => {
				return [
					...prev,
					{
						studentId,
						subjectId,
						score,
						classId,
						selectedAssessment,
					},
				];
			});
		}
	};
	const showActiveCell = (title) => {
		document.getElementById("cell-identifier").innerText = title;
	};

	useEffect(() => {
		return;
		sheetData.students.map((student) => {
			sheetData.subjects.map((subject) => {
				setSheetScores((prev) => ({
					...prev,
					["cell_" + student.id + "_" + subject.id]: Math.floor(Math.random() * (100 - 39 + 1) + 39),
				}));
				saveScore(student.id, subject.id, Math.floor(Math.random() * (100 - 39 + 1) + 39), student.stream.id === undefined ? "NULL" : student.stream.id, student.classId, selectedAssessment);
			});
		});
	}, [sheetData]);
	// const randomScores = () => {
	//     let score = Math.floor(Math.random() * (100 - 39 + 1) + 39);
	// };

	const changeCell = (event) => {
		const key = event.key;

		// ArrowDown, ArrowUp, ArrowRight, ArrowLeft
		// console.log(event);
		let td = event.target.parentNode;
		let tr = td.parentNode;
		let tbody = tr.parentNode;
		let cellIndex = td.cellIndex;
		let rowIndex = tr.rowIndex;
		if (rowIndex == 1 && key === "ArrowUp") return;
		// console.log(
		//     rowIndex,
		//     tbody.children[rowIndex - 2].children[
		//         cellIndex
		//     ].getElementsByTagName("input")[0]
		// );
		try {
			if (key === "ArrowUp" && rowIndex > 1) {
				tbody.children[rowIndex - 2].children[cellIndex].getElementsByTagName("input")[0].focus();
				tbody.children[rowIndex - 2].children[cellIndex].getElementsByTagName("input")[0].select();
			} else if ((key === "ArrowDown" || key == "Enter") && rowIndex < sheetData.students.length) {
				tbody.children[rowIndex].children[cellIndex].getElementsByTagName("input")[0].focus();
				tbody.children[rowIndex].children[cellIndex].getElementsByTagName("input")[0].select();
			} else if (key === "ArrowRight" && cellIndex < sheetData.subjects.length) {
				tbody.children[rowIndex - 1].children[cellIndex + 1].getElementsByTagName("input")[0].focus();
				tbody.children[rowIndex - 1].children[cellIndex + 1].getElementsByTagName("input")[0].select();
			} else if (key === "ArrowLeft" && cellIndex > 1) {
				tbody.children[rowIndex - 1].children[cellIndex - 1].getElementsByTagName("input")[0].focus();
				tbody.children[rowIndex - 1].children[cellIndex - 1].getElementsByTagName("input")[0].select();
			}
		} catch (error) {
			console.log("Cell does not exist");
		}
	};

	const nextPage = (increment) => {
		if (increment < 1 && pageCount <= 1) return;
		else if (
			increment > 0 &&
			pageCount >= Math.ceil(sheetData.totalStudents / rowLimit)
			// pageCount >= Math.ceil(sheetData.students.length / rowLimit)
		)
			return;
		console.log(increment);
		setPageCount((prev) => prev + increment);
	};

	const uploadMarksheet = () => {
		let input = document.createElement("input");
		input.accept = ".xlsx";
		input.type = "file";
		input.onchange = function (event) {
			loader(1);
			let file = event.target.files[0];
			console.log(file);
			let formData = new FormData();
			formData.append("marksheet", file);
			myAxios
				.post("/students/uploadMarksheet", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					// console.log("data = ", response.data.returnData);
					console.log(response);
					successAlert("Your marksheet has been uploaded successfully.", () => {
						if (sheetData.scores.length > 0) getMarkSheet();
						else {
							setSheetData(initialSheetData);
							setSheetScores([]);
						}
					});
				})
				.catch((error) => {
					console.log("Error ", error);
				})
				.finally((f) => {
					loader(0);
				});
		};
		input.click();
	};

	const saveAllScores = () => {
		loader(1);
		myAxios
			.post("/sheet/saveAllScores", { data: alteredScores })
			.then((response) => {
				let data = response.data;
				console.log("Response = ", data);
				loader(0);
				setAlteredScores([]);
			})
			.catch((error) => {
				loader(0);
				console.log(error);
				failedAlert("Could not save this record. Please try again.");
			});
	};

	useEffect(() => {
		if (assessments && formData.classId > 0 && formData.assessmentId.length > 0) getMarkSheet();
	}, [rowLimit, pageCount]);
	useEffect(() => {
		setPageCount(1);
	}, [formData]);

	// useEffect(() => {
	// 	if (formData.classId > 0 && formData.assessmentId.length > 0)
	// 		getMarkSheet();
	// }, [pageCount]);
	if (assessments && assessments.length > 0) {
		return (
			<div className="flex flex-col h-full relative">
				<MarksheetDownloadPopup assessments={assessments} selectedAssessment={selectedAssessment} showMArksheetPopup={showMArksheetPopup} setShowMarksheetPopup={setShowMarksheetPopup} />
				<form
					style={{
						borderBottom: "1px solid #ccc",
						backgroundColor: "",
					}}
					className="flex items-center"
					onSubmit={(event) => {
						event.preventDefault();
						setPageCount(1);
						getMarkSheet();
					}}
				>
					<select
						className="px-4 py-3 cursor-pointer bg-transparent"
						onChange={(event) => {
							setSheetData(initialSheetData);
							setSheetScores([]);
							setSelectedClass(isNaN(event.target.value) ? 0 : event.target.value);
							formHandler(event);
						}}
						name="classId"
					>
						<option>CLASS</option>
						{classes.map((obj, index) => (
							<option
								key={"class" + obj.id}
								{...(selectedClass === obj.id && {
									selected: true,
								})}
								value={obj.id}
							>
								{obj.alias}
							</option>
						))}
					</select>
					<select
						className="px-4 py-3 cursor-pointer bg-transparent"
						onChange={(event) => {
							setSheetData(initialSheetData);
							setSheetScores([]);
							setSelectedAssessment(event.target.value);
							// formHandler(event);
							if (!isNaN(event.target.value) && event.target.value > 0) {
								setFormData((prev) => ({
									...prev,
									assessmentId: [event.target.value],
								}));
							} else {
								setFormData((prev) => ({
									...prev,
									assessmentId: [],
								}));
							}
						}}
						name="assessmentId"
					>
						<option value="0">ASSESSMENT</option>
						{assessments.map((obj, index) => (
							<option
								key={"assessment-" + obj.id}
								{...(selectedAssessment === obj.id && {
									selected: true,
								})}
								value={obj.id}
							>
								{obj.title}
							</option>
						))}
					</select>
					<select
						className="px-4 py-3 cursor-pointer bg-transparent w-[100px]"
						onChange={(event) => {
							setSheetData(initialSheetData);
							setSheetScores([]);
							setSelectedStream(event.target.value);
							formHandler(event);
						}}
						name="streamId"
					>
						<option value="null">STREAM</option>
						{selectedClass > 0 ? (
							classes.map(
								(cls) =>
									cls.id == selectedClass &&
									cls.streams.map((obj, index) => {
										// console.log(obj);
										return (
											<option
												key={"stream-" + obj.id}
												{...(selectedStream === obj.id && {
													selected: true,
												})}
												value={obj.id}
											>
												{obj.stream}
											</option>
										);
									})
							)
						) : (
							<></>
						)}
					</select>
					<input className="py-3 px-4 w-[200px] bg-transparent" placeholder="Student name" name="name" onChange={formHandler} value={formData.name} />
					<button className="ml-3">
						<ArrowRightIcon className="w-8 bg-gray-800 text-white rounded-full p-2" />
					</button>
					<div className="ml-auto flex items-center">
						{selectedClass > 0 && selectedAssessment > 0 && (
							<div className="bg-green-50 text-[#555] text-xs font-semibold py-1 px-2 flex items-center cursor-pointer mr-2 rounded-md" onClick={() => setShowMarksheetPopup(true)}>
								<ArrowDownIcon className="w-5 h-5 bg-green-200 text-green-600 mr-2 p-[4px] rounded-full" />
								<span>Download sheet</span>
							</div>
						)}
						<div className="bg-green-50 text-[#555] text-xs font-semibold py-1 px-2 flex items-center cursor-pointer mr-2 rounded-md" onClick={uploadMarksheet}>
							<ArrowUpIcon className="w-5 h-5 bg-green-200 text-green-600 mr-2 p-[4px] rounded-full" />
							<span>Upload sheet</span>
						</div>
					</div>
				</form>
				<div className="flex-grow overflow-auto">
					{sheetData?.subjects?.length > 0 && (
						<table className="min-w-full list-table relative sheet-table rounded-none" cellPadding={2}>
							<thead>
								<tr className="bg-gray-200 sticky top-0 z-50">
									<th className="text-left">NAME</th>
									{sheetData.subjects.map((obj) =>
										obj.subject_units.length > 0 ? (
											obj.subject_units.map((sub_unit) =>
												sub_unit.active === "1" ? (
													<td className="cursor-pointer font-semibold text-center" title={obj.subject} key={"subject-" + obj.id + "-unit-title-" + sub_unit.id}>
														{obj.alias + " " + sub_unit.unit}
													</td>
												) : (
													<></>
												)
											)
										) : (
											<td
												className="cursor-pointer font-semibold text-center"
												title={obj.subject}
												key={"subject-title-" + obj.id}
												style={{
													top: 0,
													position: "sticky",
												}}
											>
												{myAccount.schoolType == "primary" ? obj.subject : obj.alias}
											</td>
										)
									)}
								</tr>
							</thead>
							<tbody>
								{sheetData.students.map((student, index) => {
									// console.log(index);
									let ptn = new RegExp("/(" + formData.name + ")/", "gi");
									// if (
									// 	index >= (pageCount - 1) * rowLimit &&
									// 	index <=
									// 		(pageCount - 1) * rowLimit -
									// 			1 +
									// 			rowLimit
									// ) {
									return (
										<tr className="text-left" key={"marksheet-student-" + student.id}>
											<td className="sticky left-0 font-semibold">{student.fullName}</td>
											{sheetData.subjects.map((subject) => {
												// console.log(subject);
												// let myscore = getScore(
												//     student,
												//     subject
												// );
												// console.log("My score = ", myscore);
												return (
													<td className="relative overflow-hidden" key={"score-" + student.id + "-" + subject.id}>
														<input
															type="text"
															pattern="[0-9\-.]{0,3}"
															maxLength={3}
															className={`text-center w-full h-full sheet-inputbox`}
															style={
																mySubjectIds.includes(subject.id) || myAccount.type == "admin"
																	? {
																			position: "absolute",
																			top: 0,
																			left: 0,
																	  }
																	: {
																			position: "absolute",
																			top: 0,
																			left: 0,
																			color: "#e0e0e0",
																			backgroundColor: "rgba(0,0,0,0.5)",
																			userSelect: "none",
																	  }
															}
															name={"cell_" + student.id + "_" + subject.id}
															value={sheetScores["cell_" + student.id + "_" + subject.id] || ""}
															onClick={(event) => {
																event.target.select();
															}}
															onKeyUp={changeCell}
															onChange={(event) => {
																handleSheetUpdate(event);
																saveScore(student.id, subject.id, null, event.target.value, student.stream?.id === undefined ? "NULL" : student.stream.id, formData.classId, selectedAssessment);
															}}
															{...(!mySubjectIds.includes(subject.id) &&
																myAccount.type != "admin" && {
																	disabled: true,
																})}
														/>
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
				<div className="select-none font-semibold flex items-center px-2 py-1 bg-gray-200 border-t-[1px] border-t-[#ccc]">
					{sheetData.students.length > 0 && (
						<>
							<ChevronLeftIcon onClick={() => nextPage(-1)} className="w-5 h-5 p-1 bg-black text-white rounded-full cursor-pointer" />
							<div className="mx-4">{pageCount + " / " + Math.ceil(sheetData.totalStudents / rowLimit)}</div>
							<ChevronRightIcon onClick={() => nextPage(1)} className="w-5 h-5 p-1 bg-black text-white rounded-full cursor-pointer" />
							<div className="font-semibold ml-3">
								{" "}
								Showing
								<span className="text-red-500 font-bold mx-1">{sheetData.totalStudents}</span> students
							</div>
						</>
					)}
					<div className="flex items-center ml-4 px-2 py-2">
						<p className="font-bold">Rows per page:</p>
						<select
							onChange={(event) => {
								console.log(event.target);
								setRowLimit(event.target.value);
							}}
							value={rowLimit}
							className="px-4 cursor-pointer bg-transparent"
						>
							<option>15</option>
							<option>35</option>
							<option>50</option>
							<option>100</option>
						</select>
					</div>
					<div className="flex items-center ml-4 px-2 py-2">
						<p className="font-bold">Scale:</p>
						<input type="text" className="border-[1px] py-[2px] px-[4px] w-[50px] text-center ml-1 rounded-[7px]" />
					</div>
					<div className="flex items-center ml-auto mr-3">
						<select className="px-4 cursor-pointer bg-transparent" onChange={formHandler} name="year">
							<option value={0}>YEAR</option>
							{allTerms.map((obj, index) => (
								<option
									key={"year" + obj.id}
									value={obj.year}
									{...(selectedYear == obj.year && {
										selected: true,
									})}
								>
									{obj.year}
								</option>
							))}
						</select>
						<select className="px-4 cursor-pointer bg-transparent" onChange={formHandler} name="termId">
							<option value={0}>TERM</option>
							{allTerms.map((obj, index) => {
								if (parseInt(obj.year) == parseInt(formData.year))
									return (
										<option
											key={"term" + obj.id}
											value={obj.id}
											{...(selectedTermId === obj.id && {
												selected: true,
											})}
										>
											TERM {obj.term}
										</option>
									);
							})}
						</select>
					</div>

					{alteredScores.length > 0 && (
						<button type="button" onClick={saveAllScores} className={`${alteredScores.length == 0 ? "bg-green-800" : "bg-red-800"} text-white py-2 my-1 rounded-md mr-4 ml-6 px-10`}>
							SAVE
						</button>
					)}
				</div>
			</div>
		);
	} else if (assessments && assessments.length == 0) {
		warningAlert("Before you access this part, you need to add at least one assessment for the current term.");
	}
}

export default Sheets;
