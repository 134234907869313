import React, { useContext } from "react";
import { GlobalContext } from "../../../../GlobalContext";
import { XIcon } from "@heroicons/react/outline";

function MarksheetDownloadPopup(props) {
	const {
		showMArksheetPopup,
		setShowMarksheetPopup,
		assessments,
		selectedAssessment,
	} = props;
	const { myAxios, api_route, classes, token } = useContext(GlobalContext);
	return (
		<div
			className={`bg-[#0003] fixed top-0 left-0 w-full h-full z-[999] ${
				showMArksheetPopup ? "flex" : "hidden"
			} items-center justify-center`}
		>
			<div className="bg-white rounded-md w-full max-w-[350px] max-h-[95%] overflow-hidden flex flex-col">
				<div className="flex items-center py-1 px-2 border-b-[1px] border-b-[#ccc] bg-[#eee]">
					<p className="font-semibold">
						Download
						<span className="font-bold mx-2 text-red-500">
							(
							{assessments.map((assessment) => {
								if (assessment.id == selectedAssessment)
									return assessment.title;
							})}
							)
						</span>{" "}
						marksheet
					</p>
					<XIcon
						className="bg-red-200 text-red-500 p-1 w-6 h-6 rounded-full cursor-pointer ml-auto"
						onClick={() => setShowMarksheetPopup(false)}
					/>
				</div>
				<div className="flex-grow flex items-center flex-wrap overflow-y-auto">
					<p className="text-center py-2 w-full">ENTIRE CLASS</p>
					{classes.map((cls, index) => (
						<a
							href={`${api_route}/sheet/downloadExcelMarksheet?assessmentId=${selectedAssessment}&classId=${cls.id}&token=${token}`}
							target="_blank"
							key={`class-opt-${index}`}
							className="w-[20%] flex flex-col items-center p-2"
						>
							<img src="/img/xlsx.png" className="w-8" />
							<span className="mt-1 font-semibold">
								{cls.alias}
							</span>
						</a>
					))}
					<p className="text-center py-2 w-full">DOWNLOAD STREAM</p>
					{classes.map((cls, index) =>
						cls.streams.map((stream, index) => (
							<a
								href={`${api_route}/sheet/downloadExcelMarksheet?assessmentId=${selectedAssessment}&streamId=${stream.id}&classId=${cls.id}&token=${token}`}
								target="_blank"
								key={`class-opt-${index}`}
								className="w-[20%] flex flex-col items-center p-2 text-center"
							>
								<img src="/img/xlsx.png" className="w-8" />
								{`${cls.alias} ${stream.alias}`}
							</a>
						))
					)}
				</div>
			</div>
		</div>
	);
}

export default MarksheetDownloadPopup;
