import React from "react";

function TimetablePreview() {
    const data = [
        "Mathematics",
        "English",
        "SST",
        "B",
        "Mathematics",
        "English",
        "SST",
        "L",
        "Mathematics",
        "English",
        "SST",
    ];
    const data2 = [
        "11:00 - 12:59",
        "11:00 - 12:59",
        "11:00 - 12:59",
        "",
        "11:00 - 12:59",
        "11:00 - 12:59",
        "11:00 - 12:59",
        "",
        "11:00 - 12:59",
        "11:00 - 12:59",
        "11:00 - 12:59",
    ];
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    // console.log(data);
    return (
        <div className="my-6">
            <table className="bare-table noborder isolated-borders w-full">
                <tbody>
                    <tr>
                        <th colSpan={data.length + 1} className="py-6 text-lg">
                            P.1 Timetable 2020
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        {data2.map((d, index) => (
                            <th>{d}</th>
                        ))}
                    </tr>
                    {days.map((d, index) => (
                        <tr>
                            <th className="text-left w-[100px]">{d}</th>
                            {data.map((activity, index) => (
                                <td className="text-left">
                                    {activity}
                                    {activity.length == 1 ? (
                                        ""
                                    ) : (
                                        <>
                                            <br />
                                            (George Mukwaya)
                                        </>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TimetablePreview;
