import React, { useContext, useEffect, useState } from "react";
import "./css/tables.css";
import { ArrowRightIcon, EyeIcon, EyeOffIcon, KeyIcon, PencilIcon, PlusCircleIcon, PlusIcon } from "@heroicons/react/outline";
import RegTeacher from "../../components/RegTeacher";
import { customConfirm, failedAlert, loader, successAlert } from "../../components/vanilla/Dialogues";
import { GlobalContext } from "../../GlobalContext";
import TeacherSubjects from "../../components/TeacherSubjects";
import PageNotAccessible from "../../components/PageNotAccessible";

function Teachers() {
	// const token = localStorage.getItem("token");
	const { token, myAxios, classes, myAccount } = useContext(GlobalContext);
	const [teachers, setTeachers] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [subjectSelection, setSubjectSelection] = useState([]);
	// useEffect(() => {
	//     setTeachers(teachers);
	// }, [teachers]);

	const [teacherPopup, setTeacherPopup] = useState(false);
	const [showSubjectWindow, setShowSubjectWindow] = useState(false);
	const [inputs, setInputs] = useState({ query: "", status: 1 });

	const getSelection = (teacherId) => {
		console.log("TID ", teacherId);
		if (!isNaN(teacherId) && teacherId > 0) {
			loader(1);
			myAxios
				.post("/marksheets/subjects/getTeacherSubjectSelection", {
					teacherId,
				})
				.then((response) => {
					loader(0);
					let data = response.data;
					console.log("Here is the selection", data);
					setSubjectSelection(data);
					setShowSubjectWindow(true); // now show the popup
				})
				.catch((error) => {
					loader(0);
					failedAlert("Could not retrieve records. Please try again.");
				});
		}
	};

	const handleForm = (event) => {
		setInputs((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};
	const deactivateTeacher = (event) => {
		let id = event.target.dataset.id;
		customConfirm("Are you sure you want to deactivate this record?").then(() => {
			loader(1);
			myAxios("/teacher/deactivate", {
				teacherId: id,
			})
				.then((response) => {
					let data = response.data;
					successAlert(data.msg, () => {
						findTeacher();
					});
					loader();
				})
				.catch((error) => {
					console.log(error);
					loader();
				});
		});
	};

	useEffect(() => {
		console.log(inputs);
	}, [inputs]);
	useEffect(() => {
		if (!showSubjectWindow) setSubjectSelection([]);
	}, [showSubjectWindow]);
	const findTeacher = (event) => {
		event && event.preventDefault();
		console.log("find teacher", inputs);
		loader(1);
		myAxios
			.post("/teacher/find", inputs)
			.then((response) => {
				loader();
				let data = response.data;
				console.log(data);
				if (data.error) failedAlert(data.error);
				else {
					setTeachers(data);
				}
			})
			.catch((error) => {
				loader();
				failedAlert("There was an error.");
			});
	};
	useEffect(() => {
		findTeacher();
	}, [teacherPopup]);

	// return <></>;

	if (myAccount.type != "admin") return <PageNotAccessible />;

	return (
		<div className="pb-2 overflow-hidden h-full">
			<RegTeacher showWindow={teacherPopup} setTeacherPopup={setTeacherPopup} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
			<TeacherSubjects showWindow={showSubjectWindow} setTeacherPopup={setShowSubjectWindow} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} subjectSelection={subjectSelection} />
			<div className="wd mx-auto px-2 h-full">
				<div className="flex-col flex h-full">
					<div className="flex items-xenter py-2">
						<p className="font-semibold text-lg">Teachers module</p>
						<form onSubmit={findTeacher} className="flex items-center bg-[#eee] ml-auto mr-2 rounded-full overflow-hidden">
							<input name="query" placeholder="Search record ..." className="py-2 bg-transparent px-3" style={{ width: "250px" }} value={inputs.query} onChange={handleForm} />
							<select name="status" className="py-2 px-1 cursor-pointer bg-transparent" value={inputs.status} onChange={handleForm}>
								<option value={1}>ACTIVE</option>
								<option value={0}>INACTIVE</option>
							</select>
							<button type="submit" id="search">
								<ArrowRightIcon className="bg-black text-white w-7 p-2 mr-1 rounded-full" />
							</button>
						</form>
						<div className="flex items-center cursor-pointer mr-2 text-[#1f6446] font-semibold" onClick={() => setTeacherPopup(true)}>
							<PlusCircleIcon className="text-[#188556] bg-[#d2ffeb] p-[5px] w-[30px] rounded-full mr-2" />
							{myAccount.schoolType == "university" ? "New Staff" : "New teacher"}
						</div>
					</div>
					<div className="flex-grow overflow-x-hidden overflow-y-auto">
						<table className="table-light w-full text-[12px]">
							<tbody>
								<tr className="thead text-left sticky top-0">
									<th>FIRST NAME</th>
									<th>OTHER NAMES</th>
									<th>EMAIL</th>
									<th>PHONE</th>
									<th>GENDER</th>
									<th>CLASS TEACHER</th>
									<th className="w-[15px]"></th>
									<th className="w-[15px]"></th>
									<th className="w-[15px]"></th>
								</tr>
								{teachers
									? teachers.map((obj, index) => (
											<tr key={index} data-obj={JSON.stringify(obj)}>
												<td>{obj.fname}</td>
												<td>{obj.lname}</td>
												<td>{obj.email}</td>
												<td>{obj.phone}</td>
												<td>{obj.gender}</td>
												<td>
													{obj.class !== null ? obj.class.alias : ""} {obj.stream !== null ? " - " + obj.stream.stream : ""}
												</td>
												<td className="relative">
													<PencilIcon className="text-[#1e754f] bg-[#c0f7df] rounded-full w-5 p-1 border-[1px] border-[#53c292]" />
													<div
														className="absolute inset-0"
														onClick={(event) => {
															let obj = JSON.parse(event.target.parentNode.parentNode.dataset.obj);
															console.log(obj);
															setSelectedRecord(obj);
															setTeacherPopup(true);
														}}
													></div>
												</td>
												<td className="relative">
													{obj.active == 0 ? <EyeOffIcon className="text-black rounded-full w-5 p-[2px]" /> : <EyeIcon className="text-black rounded-full w-5 p-[2px]" />}

													<div className="absolute inset-0" data-id={obj.id} onClick={deactivateTeacher}></div>
												</td>
												<td className="relative">
													<KeyIcon className="text-black w-5 p-[2px]" />
													<div
														className="absolute inset-0"
														data-id={obj.id}
														onClick={(event) => {
															let obj = JSON.parse(event.target.parentNode.parentNode.dataset.obj);
															console.log(obj);
															setSelectedRecord(obj);
															getSelection(obj.id);
														}}
													></div>
												</td>
											</tr>
									  ))
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Teachers;
