import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Routes, Route } from "react-router-dom";

import PageSummary from "./PageSummary";
import Marksheets from "./Marksheets";
import Reports from "./Reports";
import Teachers from "./Teachers";
import Students from "./Students";
import Timetable from "./Timetable/";
import Attendance from "./Attendance";
import { GlobalContext } from "../../GlobalContext";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/outline";

function StudentMan(props) {
	const [active_subpage, setActive_subpage] = useState("Students");
	const { schoolData, myAccount, setTermPopup, api_route } =
		useContext(GlobalContext);
	useEffect(() => {
		let ref = window.location.href;
		console.log(ref);
		if (ref.match(/sm\/students/gi)) setActive_subpage("Students");
		if (ref.match(/sm\/marksheets/gi)) setActive_subpage("Marksheets");
		if (ref.match(/sm\/attendence/gi)) setActive_subpage("Attendence");
		if (ref.match(/sm\/timetable/gi)) setActive_subpage("Timetable");
		if (ref.match(/sm\/teachers/gi)) setActive_subpage("Teachers");
		if (ref.match(/sm\/reports/gi)) setActive_subpage("Reports");
		if (ref.match(/sm\/summary/gi)) setActive_subpage("Home");
	}, [active_subpage]);
	const changeNav = (e) => {
		setActive_subpage(e.target.innerText);
	};
	return (
		<div className="flex flex-col h-full">
			<div className="border-b border-[#ccc]">
				<div className="mx-auto max-w-[1300px] px-4 pt-3 flex flex-col">
					<div className="flex items-center">
						{schoolData?.logo && (
							<div className="relative flex items-center justify-center bg-[#eee] w-6 h-6 mr-2 rounded-full overflow-hidden">
								<img
									src={
										schoolData?.logo == null
											? `${api_route}/images/icon-100.png`
											: `${api_route}/images/${schoolData?.logo}`
									}
									className="object-contain h-full w-full"
								/>
							</div>
						)}
						<p className="font-semibold pb-2 flex items-center">
							{`${schoolData.name} -`}
							<span className="text-green-700 font-semibold ml-2">
								({myAccount.name})
							</span>
						</p>
						{schoolData.term?.term && (
							<button
								onClick={() => setTermPopup(true)}
								className="ml-auto flex items-center bg-[#2e2e2e] rounded-sm px-3 py-[6px]"
							>
								<span className="text-[#c9c9c9] font-bold text-[11px]">
									{parseInt(schoolData.term.term) === 1
										? "TERM ONE"
										: parseInt(schoolData.term.term) === 2
										? "TERM TWO"
										: "TERM THREE"}{" "}
									{new Date().getFullYear()}
								</span>
								<ChevronDownIcon className="w-5 h-5 p-1 ml-2 bg-[#636363] rounded-full  text-white" />
							</button>
						)}
					</div>
					<nav className="text-sm flex">
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Students"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="students" onClick={changeNav}>
								Students
							</Link>
						</div>
						<div
							className={`hidden pb-1 border-b-2 ${
								active_subpage === "Home"
									? "border-[#f60]"
									: "border-transparent"
							} mx-3 px-1`}
						>
							<Link to="summary" onClick={changeNav}>
								Home
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Marksheets"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link
								to={`${
									myAccount.type == "admin"
										? "marksheets/sheets" // ! I know
										: "marksheets/sheets"
								}`}
								onClick={changeNav}
							>
								Marksheets
							</Link>
						</div>

						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Attendence"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link
								to="attendence"
								className="flex items-center"
								onClick={changeNav}
							>
								<span>Attendence</span>
								<span className="text-green-50 bg-green-900 py-0 px-1 rounded-sm ml-1 flex items-center">
									<ClockIcon className="text-green-50 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Timetable"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link
								to="timetable"
								className="flex items-center"
								onClick={changeNav}
							>
								<span>Timetable</span>
								<span className="text-green-50 bg-green-900 py-0 px-1 rounded-sm ml-1 flex items-center">
									<ClockIcon className="text-green-50 mr-1 w-3" />
									soon
								</span>
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Teachers"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="teachers" onClick={changeNav}>
								Teachers
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage === "Reports"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="reports" onClick={changeNav}>
								Reports
							</Link>
						</div>
					</nav>
				</div>
			</div>
			<div className="overflow-y-auto flex-grow flex flex-col">
				<Routes>
					<Route path="summary" element={<PageSummary />} />
					<Route path="marksheets/*" element={<Marksheets />} />
					<Route path="students" element={<Students />} />
					<Route path="teachers" element={<Teachers />} />
					<Route path="timetable/*" element={<Timetable />} />
					<Route path="attendence/*" element={<Attendance />} />
					<Route path="reports/*" element={<Reports />} />
				</Routes>
			</div>
		</div>
	);
}

export default StudentMan;
