import React, { useContext, useEffect, useState } from "react";
import {
	CameraIcon,
	CogIcon,
	PencilIcon,
	PlusCircleIcon,
} from "@heroicons/react/outline";
import {
	customConfirm,
	customPrompt,
	failedAlert,
	loader,
	successAlert,
	warningAlert,
} from "../../components/vanilla/Dialogues";
import RadioButton from "../../components/RadioButton";
import "../../components/css/custom-radio.css";
import { useSelector } from "react-redux";
import { selectSchooldata } from "../../redux/slice";
import { GlobalContext } from "../../GlobalContext";

function Settings() {
	// let token = localStorage.getItem("token");
	const [selectedTerm, setSelectedTerm] = useState(null);

	const {
		myAxios,
		schoolData,
		myAccount,
		setSchoolData,
		loadData,
		token,
		api_route,
		term,
	} = useContext(GlobalContext);
	const initialState = {
		id: 0,
		name: "",
		subtitle: "",
		topper: "",
		postal_address: "",
		motto: "",
		phonecontact: "",
		email: "",
		physical_address: "",
		contact_person_name: "",
		contact_person_phone: "",
		term: null,
		assessments: [],
		logo: null,
	};

	const [formData, setFormData] = useState(schoolData);
	const schoolLogo = schoolData?.logo;

	useEffect(() => {
		setFormData((prev) => ({ ...prev, logo: null }));
	}, [schoolData]);

	const saveData = (event) => {
		event.preventDefault();
		console.log(formData);
		// failedAlert("Form not submitted.");
		console.log(formData);
		loader(1);
		myAxios
			.post("/update", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				let data = response.data;
				console.log("saved data", data);
				loader();
				if (data.error !== undefined) failedAlert(data.error);
				else if (data.errors !== undefined)
					failedAlert(data.errors[0].msg);
				else {
					successAlert(
						"Your record has been updated successfully.",
						() => {
							loader(1);
							let url = window.location;
							window.location = url;
						}
					);
					// loadData();
					// formData();
				}
				// setStreams(response.data);
				// setFormData(initialState);
			})
			.catch((error) => {
				console.log(error);
				loader();
			});
	};
	const handleForm = (event) => {
		setFormData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};

	useEffect(() => {
		if (selectedTerm == schoolData.term?.term || !selectedTerm) return;
		console.log("Changing term", selectedTerm);
		loader(1);
		myAxios
			.post("/updateTerm", {
				term: selectedTerm,
			})
			.then((response) => {
				let rp = response.data;
				console.log("Term Response = ", rp);
				if (rp.error !== undefined) {
					loader();
					failedAlert(rp.error);
				} else {
					if (rp.msg !== undefined) {
						// localStorage.setItem("token", rp.msg);
						// token = rp.msg;
						// redo the login to include the term in the token
						myAxios
							.post("/switchAccounts", {
								userId: myAccount.id,
								type: myAccount.type,
								email: myAccount.email,
							})
							.then((response) => {
								let data = response.data;
								console.log(data);
								// localStorage.setItem("token", data.key);
								loader();
								successAlert(
									"The operation was a success.",
									() => {
										loader(1);
										window.location = "/home/settings";
									}
								);
							})
							.catch((error) => {
								console.log(error);
								failedAlert(
									"An error occured. Please try again",
									() => {
										loader(1);
										window.location = "/home/settings";
									}
								);
								loader();
							});
					} else if (rp.error !== undefined) failedAlert(rp.error);
				}
			})
			.catch((error) => {
				console.log(error);
				loader();
				// reject(false);
			});
	}, [selectedTerm]);

	useEffect(() => {
		// failedAlert("You're about to switch terms. Proceed with action?");
		setSelectedTerm(schoolData.term?.term);
		console.log("My term is = ", selectedTerm, schoolData);
	}, [schoolData]);

	// useEffect(() => {
	// 	console.log("Selected term ", selectedTerm);
	// 	// if(!selectedTerm || selectedTerm.term)
	// 	// myAxios.post('/changeTerm', {term: selectedTerm}).then();
	// }, [selectedTerm]);

	if (!schoolData) {
		return (
			<div className="bg-white min-h-screen overflow-hidden relative flex jic">
				<p className="text-2xl font-semibold text-gray-400">
					Please wait ...
				</p>
			</div>
		);
	}
	return (
		<div className="bg-[#eee] h-full overflow-hidden">
			<form
				onSubmit={saveData}
				className="wd2 mx-auto bg-white h-full overflow-y-auto"
			>
				<input
					type="hidden"
					value={formData.id}
					name="id"
					onChange={handleForm}
				/>
				<div className="flex items-center flex-col border-b-[1px] border-[#ccc] pt-6">
					<div className="relative flex items-center justify-center bg-[#eee] w-24 h-24 rounded-full overflow-hidden">
						<img
							src={
								schoolLogo == null
									? `${api_route}/images/icon-100.png`
									: `${api_route}/images/${schoolLogo}`
							}
							className="object-contain h-full w-full"
						/>
						<CameraIcon
							className="w-10 absolute p-2 text-white rounded-full"
							style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
						/>
						<input
							type="file"
							className="inset-0 absolute cursor-pointer opacity-0"
							accept="image/*"
							onChange={(event) => {
								event.target.parentNode.children[0].src =
									window.URL.createObjectURL(
										event.target.files[0]
									);
								setFormData((prev) => ({
									...prev,
									logo: event.target.files[0],
								}));
							}}
						/>
					</div>
					<p className="text-lg text-black pb-4 font-semibold pt-2">
						{formData.name}
					</p>
				</div>
				<div className="p-3 flex items-center flex-wrap">
					<p className="font-bold text-lg mb-2 w-full pl-3 text-[#555]">
						Metadata
					</p>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">School name</p>
						<input
							className="p-2 mt-1 bg-[#f1f1f1] rounded-md"
							type="text"
							value={formData.name}
							placeholder="e.g SCHOOLWARE INTERNATIONAL ACADEMY"
							name="name"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">
							Subtitle{" "}
							<span className="text-red-300">(optional)</span>
						</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.subtitle}
							placeholder="e.g DAY & BORADING SECONDARY SCHOOL"
							name="subtitle"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">
							Report Intro{" "}
							<span className="text-red-300">(optional)</span>
						</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							placeholder="Introductory text on report cards"
							value={formData.topper}
							name="topper"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">postal address</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.postal_address}
							name="postal_address"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Physical address</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.physical_address}
							name="physical_address"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Motto</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.motto}
							name="motto"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Phone</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.phonecontact}
							name="phonecontact"
							onChange={handleForm}
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Email</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.email}
							onChange={handleForm}
						/>
					</div>
					<p className="font-bold text-lg mb-2 w-full pl-3 text-[#555] border-b-[1px] border-[#eee] pb-1">
						Contact person
					</p>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Full name</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.contact_person_name}
							onChange={handleForm}
							name="contact_person_name"
						/>
					</div>
					<div className="w-1/3 flex-shrink-0 pb-2 px-3 flex flex-col mb-2">
						<p className="font-semibold">Phone number</p>
						<input
							className="p-2 mt-1 bg-[#f7f7f7] rounded-md"
							type="text"
							value={formData.contact_person_phone}
							onChange={handleForm}
							name="contact_person_phone"
						/>
					</div>
					<div className="w-full ml-3">
						<button
							type="submit"
							className="bg-[#0e83b9] text-white px-3 py-1 rounded-md"
						>
							submit
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default Settings;
