import { XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { failedAlert } from "./vanilla/Dialogues";

function MyDateRangePicker(props) {
    const [selection, setSelection] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [rangeSelected, setRangeSelected] = useState(null);
    const handleSelect = (range) => {
        // console.log(range, range.selection.startDate, range.selection.endDate);
        setRangeSelected(true);
        setSelection((prev) => ({
            ...prev,
            startDate: range.selection.startDate,
            endDate: range.selection.endDate,
        }));
        props.setStartDate(range.selection.startDate);
        props.setEndDate(range.selection.endDate);
        props.setSelectionRange(
            `${dateToString(range.selection.startDate)}:${dateToString(
                range.selection.endDate
            )}`
        );
        // setStartDate(range.startDate);
        // setEndDate(range.endDate);
    };
    const dateToString = (date) => {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        // Add leading zeros to day and month if necessary
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        // Construct the output string in the "YYYY-MM-DD" format
        var formattedDate = year + "-" + month + "-" + day;

        return formattedDate;
    };
    return (
        <div className={`popup-sm ${props.showWindow ? "show" : ""}`}>
            <div className={`relative popup-body max-w-[600px]`}>
                <XIcon
                    className="absolute top-[-10px] right-[-10px] w-6 cursor-pointer text-red-600 bg-red-200 rounded-full border-red-400 p-1"
                    onClick={() => {
                        rangeSelected
                            ? props.changeStatus(false)
                            : failedAlert("Please select a date or a range");
                    }}
                />
                <DateRangePicker
                    ranges={[selection]}
                    onChange={handleSelect}
                    rangeColors={["#FD5B61"]}
                />
            </div>
        </div>
    );
}

export default MyDateRangePicker;
