import React from "react";

import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";

function Hsc() {
    const data = [];
    for (let x = 0; x < 8; x++) {
        let d = [
            { score: 29, grade: "F9" },
            { score: 56, grade: "C6" },
            { score: 68, grade: "C3" },
            { score: 99, grade: "D1" },
        ];
        data.push(d);
    }
    return (
        <>
            <div className="mx-auto max-w-[790px] bg-white my-2 h-[842px] c-shadow-lg rounded-[4px] p-6 flex flex-col">
                <MainHeader />
                <div className="flex-grow flex items-center justify-center relative">
                    <div className="absolute h-2/3 w-2/3 flex items-center justify-center">
                        <img
                            src="/logo512.png"
                            className="object-cover opacity-[0.15] min-w-[100%] min-h-[100%] max-w-[140%]"
                            alt="school badge"
                        />
                    </div>
                    <div className="absolute inset-0 h-full w-full overflow-hidden flex flex-col pb-2">
                        <div className="flex-grow">
                            <div className="flex items-center flex-wrap">
                                <div className="w-full">
                                    <p className="text-lg text-center font-bold text-red-400">
                                        CUMMULATIVE REPORT CARD
                                    </p>
                                </div>
                                <div className="w-1/3">
                                    <p>
                                        <b>NAME: SERENA BIRUNGI</b>
                                    </p>
                                </div>
                                <div className="flex-grow text-right">
                                    <p>
                                        <b>CLASS: </b> PRIMARY ONE BRIGHT (P.1
                                        B)
                                    </p>
                                </div>

                                <table className="bare-table noborder isolated-borders table-p-7 w-full text-[11px] mt-3">
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{
                                                borderTop: "none",
                                                borderBottom: "none",
                                                borderLeft: "none",
                                            }}
                                        ></td>
                                        <th
                                            colSpan={5}
                                            className="bg-gray-200 text-[14px]"
                                        >
                                            TERM 3 2023
                                        </th>
                                    </tr>
                                    <tr className="bg-transparent">
                                        <th
                                            className="w-[150px] text-left"
                                            style={{
                                                border: "none",
                                            }}
                                            colSpan={2}
                                        ></th>
                                        <th
                                            style={{ width: 55 }}
                                            className="bg-[#eee]"
                                        >
                                            Term 1
                                        </th>
                                        <th
                                            style={{ width: 55 }}
                                            className="bg-[#eee]"
                                        >
                                            Term 2
                                        </th>
                                        <th
                                            rowSpan={19}
                                            style={{
                                                width: 5,
                                                border: "none",
                                            }}
                                            className="bg-transparent"
                                        ></th>
                                        <th className="w-[35px]">BOT</th>
                                        <th className="w-[35px]">MID</th>
                                        <th className="w-[35px]">MOCK</th>
                                        <th>Final Score</th>
                                        <th className="w-[35px]">GRADE</th>
                                        <th className="w-[50px]">Rank</th>
                                        <th
                                            className="bg-white"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Remark
                                        </th>
                                    </tr>
                                    {data.map((data, index) => (
                                        <tr
                                            key={index}
                                            className="bg-transparent"
                                        >
                                            {index % 2 == 0 ? (
                                                <th
                                                    rowSpan={2}
                                                    className="text-left"
                                                >
                                                    Biology
                                                </th>
                                            ) : null}

                                            {index == 0 ? (
                                                <th
                                                    rowSpan={17}
                                                    style={{
                                                        width: 30,
                                                        border: "none",
                                                    }}
                                                    className="bg-white"
                                                ></th>
                                            ) : (
                                                ""
                                            )}
                                            <th className="bg-[#eee] relative">
                                                86{" "}
                                                <small className="text-blue-700">
                                                    D1
                                                </small>
                                                <div className="inset-0 absolute">
                                                    <div className="h-full w-full relative flex items-center">
                                                        <div className="paper-num">
                                                            7
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-[#eee]">
                                                86{" "}
                                                <small className="text-blue-700">
                                                    D1
                                                </small>
                                            </th>
                                            <th>86</th>
                                            <th>86</th>
                                            <th>86</th>
                                            <th>
                                                86{" "}
                                                <small className="text-red-500">
                                                    D1
                                                </small>
                                            </th>
                                            {index % 2 == 0 ? (
                                                <th
                                                    rowSpan={2}
                                                    className="relative"
                                                >
                                                    <div className="text-[14px] absolute inset-0 justify-center items-center flex">
                                                        F
                                                    </div>
                                                </th>
                                            ) : null}
                                            <th>1/826</th>
                                            <th
                                                style={{ whiteSpace: "nowrap" }}
                                                className="text-left"
                                            >
                                                Very Good performance
                                                performance{" "}
                                                <small className="text-red-500">
                                                    (IN)
                                                </small>
                                            </th>
                                        </tr>
                                    ))}
                                    <tr className="bg-transparent">
                                        <th
                                            colSpan={4}
                                            style={{ border: "none" }}
                                            className="bg-transparent text-green-700"
                                        >
                                            POINTS: 17
                                        </th>
                                        <th className="report-darker-cell">
                                            86
                                        </th>
                                        <th className="report-darker-cell">
                                            86
                                        </th>
                                        <th className="report-darker-cell">
                                            86
                                        </th>
                                        <th className="report-darker-cell">
                                            86
                                        </th>
                                        <th
                                            colSpan={3}
                                            style={{ border: "none" }}
                                            className="bg-transparent text-green-700"
                                        >
                                            PRINCIPLE PASSES: 3
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <GradingScale />
                    </div>
                </div>
                <MainFooter />
            </div>
        </>
    );
}

export default Hsc;
