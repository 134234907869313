import React from "react";
import "../css/timetable.css";
import "../css/report-tables.css";
import { Link, Route, Routes } from "react-router-dom";
import TimetablePreview from "./TimetablePreview";
import NewTimetable from "./NewTimetable";
import UnderConstruction from "../../../components/UnderConstruction";

function index() {
	return <UnderConstruction />;
	return (
		<div className="h-full flex">
			<div className="flex-grow overflow-x-hidden flex flex-col h-full">
				<div className="bg-[#f5f5f5]">
					<div className="py-2 flex items-center wd2 mx-auto">
						<Link
							to="new"
							className="bg-[#eee] text-[#555] hover:bg-[#555] hover:text-white px-4 py-2 rounded-md mr-2"
						>
							Edit view
						</Link>
						<Link
							to="preview"
							className="bg-[#eee] text-[#555] hover:bg-[#555] hover:text-white px-4 py-2 rounded-md"
						>
							Timetable preview
						</Link>
					</div>
				</div>
				<div className="flex-grow overflow-y-auto px-2">
					<div className="wd2 mx-auto pt-2">
						<Routes>
							<Route path="new" element={<NewTimetable />} />
							<Route
								path="preview"
								element={<TimetablePreview />}
							/>
						</Routes>
					</div>
				</div>
			</div>
			<div className="border-l-[1px] border-gray-300 h-full w-[250px] flex-shrink-0">
				<form className="flex flex-col">
					<p className="font-bold px-3 pb-1 pt-2 text-[#777]">Year</p>
					<label className="custom-radio flex flex-row-reverse items-center font-bold px-3 py-2">
						<input type="radio" name="year-radio" />
						<p className="mr-auto">2020</p>
						<span></span>
					</label>
					<label className="custom-radio flex flex-row-reverse items-center font-bold px-3 py-2">
						<input type="radio" name="year-radio" />
						<p className="mr-auto">2021</p>
						<span></span>
					</label>
					<p className="font-bold px-3 pb-1 pt-2 text-[#777]">Term</p>
					<label className="custom-radio flex flex-row-reverse items-center font-bold px-3 py-2">
						<input type="radio" name="term-radio" />
						<p className="mr-auto">Term 1</p>
						<span></span>
					</label>
					<label className="custom-radio flex flex-row-reverse items-center font-bold px-3 py-2">
						<input type="radio" name="term-radio" />
						<p className="mr-auto">Term 2</p>
						<span></span>
					</label>
					<button
						type="button"
						className="bg-[#0079bf] hover:bg-[#333] py-2 rounded-full mx-3 text-white text-center font-bold my-2 mb-3"
					>
						New timetable
					</button>
					<div className="px-3 py-2 bg-[#eee] hover:bg-[#444] hover:text-white mx-3 mt-1 rounded-lg font-bold cursor-pointer">
						P.3 A timetable
					</div>
					<div className="px-3 py-2 bg-[#eee] hover:bg-[#444] hover:text-white mx-3 mt-1 rounded-lg font-bold cursor-pointer">
						P.3 A timetable
					</div>
				</form>
			</div>
		</div>
	);
}

export default index;
