import React, { useContext, useState } from "react";
import { useEffect } from "react";
// import env from "react-dotenv";
import { Link, BrowserRouter, useNavigate } from "react-router-dom";
import { failedAlert, loader, successAlert, warningAlert } from "../components/vanilla/Dialogues";
import { GlobalContext } from "../GlobalContext";
function Login() {
	// console.log(env);
	let navigate = useNavigate();
	const { myAxios } = useContext(GlobalContext);
	const [inputs, setInputs] = useState({ email: "", password: "" });
	const [checking, setChecking] = useState(0);
	const handleChange = (event) => {
		setInputs((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};
	// const uri = `${process.env.REACT_APP_API_URL}/login`;
	const login = (event) => {
		event.preventDefault();
		loader(1);
		myAxios
			.post("/login", inputs)
			.then((result) => {
				let response = result.data;
				loader(0);
				console.log(response);
				// console.log("Response is here", "Yooo", response);
				// console.log(response.error);
				if (response.error) {
					failedAlert(response.error);
				} else {
					// console.log("You have logged in");
					localStorage.setItem("token", response.accessToken);
					localStorage.setItem("user_id", response.user_id);
					localStorage.setItem("isAdmin", response.isAdmin);
					// setting expiry for this token
					const remainingMilliseconds = 60 * 60 * 1000;
					const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
					localStorage.setItem("expiryDate", expiryDate.toISOString());
					if (response.isAdmin == true) window.location.href = "/admin/schools";
					else window.location.href = "/home/sm/students";
					// navigate("/home/sm/students", { replace: true });
					// navigate("/", { replace: true });
				}
			})
			.catch((error) => {
				loader(0);
				console.log("There was an error", error);
				let response = error.response.data;
				// if (response.errors?.length) {
				// 	failedAlert(response.errors[0].msg);
				// } else if (response.error != undefined) {
				// 	failedAlert(response.error);
				// }
			});
	};
	useEffect(() => {
		let token = localStorage.getItem("token");
		// console.log(token);
		if (token) {
			// window.location.href = "/home/sm/students";
			setTimeout(() => {
				navigate("/home/sm/students", { replace: true });
			}, 4000);
			return;
		}
		setChecking(1);
	}, []);
	if (!checking) {
		return (
			<div className="h-screen w-full flex items-center justify-center">
				<p className="text-3xl font-semibold text-[#888]">initializing ...</p>
			</div>
		);
	}
	return (
		<div className="h-screen flex justify-center items-center flex-col">
			<div className="text-center">
				<img src="./android-chrome-512x512.png" className="mx-auto w-[100px]" />
				<p className="text-xl font-thin mb-2">Login to Schoolware</p>
				<form onSubmit={login} className="w-[280px] rounded-lg flex flex-col px-3 pt-5 text-left">
					<p className="text-xs mb-1 font-semibold">Your email address</p>
					<input type="text" className="text-xs rounded-md p-2 bg-gray-50" style={{ boxShadow: "0 2px 3px #eee" }} placeholder="e.g admin@school.com" name="email" value={inputs["email"]} onChange={handleChange} />
					<p className="mt-3 mb-1 text-xs font-semibold">Account Password</p>
					<input type="password" className="text-xs rounded-md p-2 bg-gray-50" style={{ boxShadow: "0 2px 3px #eee" }} placeholder="" name="password" value={inputs["pass"]} onChange={handleChange} />
					<button className="text-xs bg-[#000] rounded-lg text-center py-2 text-white mt-3">Login</button>
				</form>
				<div className="mt-1 rounded-md py-2 text-xs font-semibold text-blue-800 hover:underline">
					<Link to="/signup" className="hidden">
						Create account instead?
					</Link>
					<Link to="/privacy-policy">Read our privacy policy</Link>
				</div>
			</div>
		</div>
	);
}

export default Login;
