import ReactDOMServer from "react-dom/server";

// importing icons
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
	faCancel,
	faCheckCircle,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
	BadgeCheckIcon,
	CheckIcon,
	ExclamationIcon,
	XIcon,
} from "@heroicons/react/outline";

export function customPrompt(obj) {
	if (document.querySelector("#customPrompt")) return;
	return new Promise((resolve, reject) => {
		let ov = document.createElement("div");
		let div2 = document.createElement("div");
		let div3 = document.createElement("div");
		let p = document.createElement("p");
		let div4 = document.createElement("div");
		let input = document.createElement(
			obj.boxtype
				? obj.boxtype == "textarea"
					? "textarea"
					: "input"
				: "input"
		);
		let div5 = document.createElement("div");
		let button1 = document.createElement("button");
		let button2 = document.createElement("button");

		ov.id = "customPrompt";
		window.setTimeout(() => {
			ov.className = "show";
		}, 100);
		p.id = "title";
		div4.className = "flex flex-col";
		if (obj.boxtype == "input") input.type = obj.input_type;
		if (obj.value) input.value = obj.value;
		div5.className = "flex mt-2";
		button1.type = "button";
		button1.className = "ok";
		button2.type = "button";
		button2.className = "cancel";
		button1.innerText = "ok";
		button2.innerText = "cancel";
		p.innerText = obj.title;
		div3.className = "pt-4 px-4";

		div5.appendChild(button1);
		div5.appendChild(button2);
		div4.appendChild(input);
		div4.appendChild(div5);
		div3.appendChild(p);
		div3.appendChild(div4);
		div2.appendChild(div3);
		ov.appendChild(div2);
		document.body.appendChild(ov);
		input.focus();

		// event listeners
		button1.onclick = function () {
			if (input.value.replace("/ /g", "").length > 0) {
				let val = input.value;
				console.log(val);
				resolve(val);
				removeCustomPrompt();
			}
		};
		button2.onclick = function () {
			reject(false);
			removeCustomPrompt();
		};
		input.onkeyup = (event) => {
			if (event.code == "Enter") {
				button1.click();
			}
		};
	});
}
export function removeCustomPrompt() {
	document.body.removeChild(document.querySelectorAll("#customPrompt")[0]);
}

// alert box
function customAlert(text, icon, title, callback) {
	return new Promise((resolve, reject) => {
		let ov = document.createElement("div");
		let div2 = document.createElement("div");
		let div3 = document.createElement("div");
		let div4 = document.createElement("div");
		let p = document.createElement("p");
		let p1 = document.createElement("p");
		let button = document.createElement("button");

		let top_div = document.createElement("div");

		ov.id = "customAlert";
		window.setTimeout(() => {
			ov.className = "show";
		}, 100);
		p.id = "title";
		p.className = "text-sm";
		button.type = "button";
		button.className = "ok mt-2";
		button.innerText = "ok";
		p1.className =
			"text-left text-black font-semibold text-lg pb-1 flex-grow pl-2";
		// p1.innerText = "Attention please.";
		p1.innerText = title;
		p.innerHTML = text;

		div3.className = "pt-4 px-4";

		div4.className = `icon fill-orange-400 mx-auto w-5 flex-grow-0 flex-shrink-0`;
		div4.innerHTML = icon;
		div4.style.fill = "#f60";

		top_div.className = "flex items-center";
		top_div.appendChild(div4);
		top_div.appendChild(p1);

		div3.appendChild(top_div);
		// div3.appendChild(p1);
		div3.appendChild(p);
		div3.appendChild(button);
		div2.appendChild(div3);
		ov.appendChild(div2);
		document.body.appendChild(ov);

		// event listeners
		button.onclick = () => {
			if (callback) callback();
			removeCustomAlert();
			return;
		};
	});
}
export function removeCustomAlert() {
	document.body.removeChild(document.querySelectorAll("#customAlert")[0]);
}
export async function failedAlert(text, callback) {
	const icon = ReactDOMServer.renderToString(
		// <FA icon={faCancel} className="w-full text-red-600" />
		<XIcon className="w-full text-red-600 bg-red-100 rounded-full p-[5px]" />
	);
	// console.log(icon);
	await customAlert(text, icon, "System failure!", callback);
}
export async function successAlert(text, callback) {
	const icon = ReactDOMServer.renderToString(
		// <FA icon={faCheckCircle} className="w-6 h-6 text-green-700" />,
		<CheckIcon className="w-full text-green-700 bg-green-100 rounded-full p-[5px]" />
	);
	// console.log(icon);
	await customAlert(text, icon, "Successful", callback);
}
export async function warningAlert(text, callback) {
	const icon = ReactDOMServer.renderToString(
		// <FA icon={faExclamationTriangle} className="w-full text-yellow-500" />
		<ExclamationIcon className="w-full text-yellow-500" />
	);
	await customAlert(text, icon, "Warning", callback);
}

// alert box
export function customConfirm(text) {
	if (document.querySelector("#customConfirm")) return;
	return new Promise((resolve, reject) => {
		document.activeElement.blur();
		let ov = document.createElement("div");
		let div2 = document.createElement("div");
		let div3 = document.createElement("div");
		let div4 = document.createElement("div");
		let div5 = document.createElement("div");
		let p1 = document.createElement("p");
		let p2 = document.createElement("p");
		let button1 = document.createElement("button");
		let button2 = document.createElement("button");

		ov.id = "customConfirm";
		window.setTimeout(() => {
			ov.className = "show";
		}, 100);
		p1.id = "title";
		p1.className = "text-left text-black text-md mb-2";
		p2.className = "text-left text-black font-bold text-xl pb-1";
		div5.className = "mt-2 flex";
		button1.type = "button";
		button1.className = "ok mr-2 text-sm";
		button2.className = "cancel text-sm";
		button1.innerText = "Yes";
		button2.innerText = "No";
		p1.innerText = text;
		p2.innerText = "Confirm action";
		div3.className = "pt-4 px-4";

		// div3.appendChild(div4);
		div3.appendChild(p2);
		div3.appendChild(p1);
		div5.appendChild(button1);
		div5.appendChild(button2);
		div3.appendChild(div5);
		div2.appendChild(div3);
		ov.appendChild(div2);
		document.body.appendChild(ov);

		// event listeners
		button1.onclick = () => {
			resolve(true);
			removeCustomConfirm();
			return;
		};
		button2.onclick = () => {
			// reject(false);
			removeCustomConfirm();
			return;
		};
	});
}

export function removeCustomConfirm() {
	document.body.removeChild(document.querySelectorAll("#customConfirm")[0]);
}

export function loader(show = 0) {
	console.log("Here we go", show);
	const loader = document.getElementById("loader");
	if (show) {
		loader.style.display = "block";
		window.setTimeout(() => {
			loader.className = "show";
		}, 50);
		document.activeElement.blur();
	} else {
		if (!loader) return;
		loader.removeAttribute("class");
		window.setTimeout(() => {
			loader.style.display = "none";
		}, 500);
	}
}

// export { customAlert, failedAlert, warningAlert, successAlert };

// successAlert(
// 	"To begin the development, run npm start or yarn start. To create a production bundle, use npm run build or yarn build",
// );
// customPrompt({
// 	boxtype: "input",
// 	input_type: "password",
// 	title: "Enter your password to proceed",
// });
// customConfirm(
// 	"This is a dangerous move and is irreversible.\nProceed with action?",
// );
