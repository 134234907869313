import React from "react";
import GradingScale from "../GradingScale";
import MainFooter from "../ReportFooters/MainFooter";
import MainHeader from "../ReportHeaders/MainHeader";

function PrimaryCummulative(props) {
	const data = [];
	for (let x = 0; x < 9; x++) {
		let d = [
			{ score: 29, grade: "F9" },
			{ score: 56, grade: "C6" },
			{ score: 68, grade: "C3" },
			{ score: 99, grade: "D1" },
		];
		data.push(d);
	}
	return (
		<div className="mx-auto max-w-[790px] bg-white my-2 h-[842px] c-shadow-lg rounded-[4px] p-6 flex flex-col">
			<MainHeader schoolData={props.schoolData} />
			<div className="flex-grow flex items-center justify-center relative">
				<div className="absolute h-1/2 w-2/3 flex items-center justify-center">
					<img
						src="/logo512.png"
						className="object-cover opacity-[0.15] min-w-[100%] min-h-[100%] max-w-[140%]"
					/>
				</div>
				<div className="absolute inset-0 h-full w-full overflow-hidden flex flex-col pb-2">
					<div className="flex-grow flex flex-col">
						<div className="flex items-center flex-wrap">
							<div className="w-full">
								<p className="text-lg text-center font-bold my-2 text-red-400">
									CUMMULATIVE REPORT CARD
								</p>
							</div>
							<div className="w-1/3">
								<p>
									<b>NAME: SERENA BIRUNGI</b>
								</p>
							</div>
							<div className="w-1/3">
								<p>
									<b>CLASS: </b> PRIMARY ONE BRIGHT (P.1 B)
								</p>
							</div>
							<div className="w-1/3">
								<p>
									<b>TERM ONE 2023</b>
								</p>
							</div>
						</div>
						<table className="bare-table mr-2 mb-3 mt-2">
							<thead>
								<tr className="bg-[#eee]">
									<th
										rowSpan={2}
										className="w-[100px] border-[0px] text-left"
									>
										ASSESSMENTS
									</th>
									<th colSpan={2}>ENG</th>
									<th colSpan={2}>SCI</th>
									<th colSpan={2}>MTC</th>
									<th colSpan={2}>SST</th>
									<th colSpan={2}>ENG</th>
									<th colSpan={2}>SCI</th>
									<th colSpan={2}>MTC</th>
									<th colSpan={2}>SST</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										AVE
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										AGG
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										POS
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										DIV
									</th>
								</tr>
							</thead>
							{data.map((d, index) => (
								<tr key={index} className="text-[12px]">
									<th align="left">SET{index + 1}</th>
									<th>79</th>
									<td className="text-[#f00] text-center">
										2
									</td>
									<th>63</th>
									<td className="text-[#f00] text-center">
										4
									</td>
									<th>41</th>
									<td className="text-[#f00] text-center">
										8
									</td>
									<th>92</th>
									<td className="text-[#f00] text-center">
										1
									</td>
									<th>79</th>
									<td className="text-[#f00] text-center">
										2
									</td>
									<th>63</th>
									<td className="text-[#f00] text-center">
										4
									</td>
									<th>41</th>
									<td className="text-[#f00] text-center">
										8
									</td>
									<th>92</th>
									<td className="text-[#f00] text-center">
										1
									</td>
									<th className="bg-[rgba(0,139,255,0.05)]">
										83
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										9
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										{index + 1}
									</th>
									<th className="bg-[rgba(0,139,255,0.05)]">
										I
									</th>
								</tr>
							))}
						</table>
						<div className="flex flex-wrap">
							<div className="w-[25%] flex-shrink-0">
								<div className="pr-2">
									<p className="font-bold">CONDUCT</p>
									<p>
										Well behaved Well behaved Well behaved
									</p>
								</div>
							</div>
							<div className="w-[25%] flex-shrink-0">
								<div className="pr-2">
									<p className="font-bold mt-2">ATTENDANCE</p>
									<p>
										Well behaved Well behaved Well behaved
									</p>
								</div>
							</div>
							<div className="w-[25%] flex-shrink-0">
								<div className="pr-2">
									<p className="font-bold mt-2">SMARTNESS</p>
									<p>
										Well behaved Well behaved Well behaved
									</p>
								</div>
							</div>
							<div className="w-[25%] flex-shrink-0">
								<div className="pr-2">
									<p className="font-bold mt-2">
										PUNCTUALITY
									</p>
									<p>
										Well behaved Well behaved Well behaved
									</p>
								</div>
							</div>
						</div>
					</div>
					<GradingScale />
				</div>
			</div>
			<MainFooter />
		</div>
	);
}

export default PrimaryCummulative;
