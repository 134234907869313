import { faFolderOpen, faICursor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    CloudUploadIcon,
    DownloadIcon,
    FolderIcon,
    FolderOpenIcon,
    UploadIcon,
    XCircleIcon,
} from "@heroicons/react/outline";
import React, { useEffect } from "react";
import "../css/main.css";

function ContextMenu(props) {
    useEffect(() => {
        window.addEventListener("keyup", function (event) {
            if (event.key == "Escape") props.setShowWindow(false);
        });
        document
            .getElementById("contextMenuOverlay")
            .addEventListener("contextmenu", function (event) {
                event.preventDefault();
            });
        document.getElementById("ov2").onclick = function () {
            props.setShowWindow(false);
        };
        document
            .getElementById("ov2")
            .addEventListener("contextmenu", function () {
                props.setShowWindow(false);
            });
    }, []);
    return (
        <div
            id="contextMenuOverlay"
            className={`popup-sm ${props.showWindow ? "show" : ""}`}
            style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
            <div
                id="ov2"
                style={{
                    position: "fixed",
                    inset: 0,
                    backgroundColor: "rgba(255,0,0,0)",
                }}
            ></div>
            <div
                className={`fixed popup-body w-[200px] contextmenu`}
                style={{
                    borderRadius: 3,
                    top: props.y,
                    left: props.x,
                    boxShadow: "1px 1px 5px 1px #cfcfcf",
                    zIndex: 9999,
                }}
            >
                <div className="menu-item flex items-center">
                    <div className="icon">
                        <FontAwesomeIcon
                            icon={faFolderOpen}
                            className="text-black w-4"
                        />
                    </div>
                    <p>Open</p>
                </div>
                <div className="menu-item flex items-center">
                    <div className="icon">
                        <CloudUploadIcon className="text-[#f60] w-4" />
                    </div>
                    <p>Upload file</p>
                </div>

                <div className="menu-item flex items-center">
                    <div className="icon">
                        <FolderIcon className="text-[#777] w-4" />
                    </div>
                    <p>New folder</p>
                </div>
                <div className="menu-item flex items-center">
                    <div className="icon">
                        <FontAwesomeIcon
                            icon={faICursor}
                            className="text-[#777] w-4"
                        />
                    </div>
                    <p>Rename</p>
                </div>

                <div className="menu-item flex items-center">
                    <div className="icon">
                        <DownloadIcon className="text-[#227c55] w-4" />
                    </div>
                    <p>Download file</p>
                </div>

                <div
                    className="menu-item flex items-center"
                    onClick={() => props.setShowWindow(false)}
                >
                    <div className="icon">
                        <XCircleIcon className="text-[#f00] w-4" />
                    </div>
                    <p>Cancel (ESC)</p>
                </div>
            </div>
        </div>
    );
}

export default ContextMenu;
