import { ArrowRightIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { GlobalContext } from "../../../GlobalContext";

function ChooseTemplate({
	data,
	studentName,
	setStudentName,
	setLimit,
	getSheet,
	setOpenTemplates,
	api_route,
	setCountReports,
}) {
	const { chosenTemplate, setChosenTemplate, setReportSheetData } =
		useContext(GlobalContext);
	useEffect(() => {
		const closeReportCardPreview = () => {
			setReportSheetData([]);
		};
		window.addEventListener("keyup", function (event) {
			if (event.key == "Escape") closeReportCardPreview();
		});
	}, []);
	return (
		<>
			{/* {chosenTemplate && (
				<img
					className="w-[200px] mx-auto mb-5"
					src={`${api_route}/${chosenTemplate.screenshot}`}
				/>
			)} */}
			<p className="font-bold text-lg mb-3 text-gray-400">
				Choose Template below
			</p>

			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"tmp1"}
					{...(chosenTemplate == "tmp1" && {
						checked: true,
					})}
					{...(chosenTemplate != "tmp1" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log("Checked ", event.target.checked);
						if (event.target.checked) setChosenTemplate("tmp1");
						else setChosenTemplate("");
					}}
				/>
				<p className="mr-auto flex-shrink-0">One Assessment</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"tmp2"}
					{...(chosenTemplate == "tmp2" && {
						checked: true,
					})}
					{...(chosenTemplate != "tmp2" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log("Checked ", event.target.checked);
						if (event.target.checked) setChosenTemplate("tmp2");
						else setChosenTemplate("");
					}}
				/>
				<p className="mr-auto flex-shrink-0">Two Assessments</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<label
				className={`custom-radio flex flex-row-reverse items-center font-semibold text-black relative my-2`}
			>
				<input
					type="checkbox"
					name="assessmentId"
					value={"continuous-linear"}
					{...(chosenTemplate == "continuous-linear" && {
						checked: true,
					})}
					{...(chosenTemplate != "continuous-linear" && {
						checked: false,
					})}
					onChange={(event) => {
						console.log("Checked ", event.target.checked);
						if (event.target.checked)
							setChosenTemplate("continuous-linear");
						else setChosenTemplate("");
					}}
				/>
				<p className="mr-auto flex-shrink-0">
					Continous assessment (Linear)
				</p>
				<span className="flex-shrink-0">
					<CheckIcon className="w-full text-white" />
				</span>
				<div className="absolute inset-0"></div>
			</label>
			<div className="flex items-center my-2 bg-[#e3e3e3] rounded-full overflow-hidden">
				<input
					type="text"
					maxLength={20}
					placeholder="Student name"
					className="bg-transparent flex-grow px-4 py-3 font-xs"
					value={studentName}
					onChange={(event) => setStudentName(event.target.value)}
				/>
				<ArrowRightIcon
					onClick={() => {
						setLimit(10000);
						getSheet();
					}}
					className="text-[#444] w-8 p-2 h-8 bg-[#ccc] rounded-full mr-2 cursor-pointer"
				/>
			</div>
		</>
	);
}

export default ChooseTemplate;
