import React, { useEffect } from "react";

function Header2({ about, student, api_route }) {
	function fitTextToWidthTopper(title_container, fsize) {
		const p = title_container.querySelector("p");
		p.parentNode.style.transition = "0s all";
		p.style.fontSize = "0px";
		p.style.transition = "0s all";
		// let i = 0;
		// while (i <= 1000) {
		// console.log(title_container.querySelector("p").offsetWidth, title_container.offsetWidth - 10, i);
		p.style.fontSize = `${fsize}px`;
		// i += 0.5;
		const xx = Math.floor(title_container.querySelector("p").offsetWidth / title_container.offsetWidth);
		console.log("xx = ", xx);

		setTimeout(() => {
			if ((title_container.querySelector("p").offsetWidth / title_container.offsetWidth) * 100 >= 50) {
				// alert();
				p.style.textAlign = `center`;
				p.style.display = `block`;
				p.parentNode.style.width = title_container.offsetWidth + "px";
				return;
			} else fitTextToWidthTopper(title_container, fsize + 1);
		}, 1);
		// }
	}
	function fitTextToWidth(title_container, fsize) {
		const p = title_container.querySelector("p");
		p.style.fontSize = "0px";
		p.style.transition = "0s all";
		let i = 0;
		// while (i <= 1000) {
		// console.log(title_container.querySelector("p").offsetWidth, title_container.offsetWidth - 10, i);
		p.style.fontSize = `${fsize}px`;
		// i += 0.5;
		setTimeout(() => {
			if (title_container.querySelector("p").offsetWidth >= title_container.offsetWidth - 10) {
				// alert();
				p.style.textAlign = `center`;
				return;
			} else fitTextToWidth(title_container, fsize + 1);
		}, 1);
		// }
	}
	useEffect(() => {
		let title_container = document.querySelector(`.student-rp-cd-${student.studentId} .rp-header2-tt`);
		fitTextToWidth(title_container, 0);
		let title_container2 = document.querySelector(`.student-rp-cd-${student.studentId} .rp-header2-tt2`);
		fitTextToWidthTopper(title_container2, 0);

		console.log(title_container);
	}, []);
	return (
		<div className="flex items-center z-30 relative">
			<div className="w-[100px] m-[20px] mr-[10px] flex justify-center items-center overflow-hidden flex-shrink-0">
				<img src={`${api_route}/images/${about.logo ?? "icon-100.png"}`} className="object-fill" />
			</div>
			<div className="flex flex-col flex-grow text-center overflow-hidden">
				<div className="rp-header2-tt2">
					<div className="font-bold title text-left" style={{ width: "2500px" }}>
						<p className="text-[#065030]" style={{ fontSize: "12px", display: "inline-block" }}>
							{about.topper}
						</p>
					</div>
				</div>
				<div className="rp-header2-tt">
					<div className="font-bold title text-left" style={{ width: "2500px" }}>
						<p style={{ fontSize: "12px", display: "inline-block" }}>{about.name}</p>
					</div>
				</div>
				<p className="text-[17px] font-bold">{about.subtitle}</p>
				<p className="">
					TEL: {about.phonecontact}, <br></br>
					Email: {about.email}
				</p>
			</div>
			{!student?.picture ? (
				<div className="rounded-[7px] bg-[#eee] border-2 border-[#ccc] w-[100px] h-[100px] m-[20px] ml-[20px] flex flex-col justify-center font-bold text-[#757575] items-center overflow-hidden text-[10px] flex-shrink-0">
					<p>NO PHOTO</p>
					<p>AVAILABLE</p>
				</div>
			) : (
				<div className="rounded-[7px] w-[100px] border-2 border-[#ccc] h-[100px] m-[20px] ml-[20px] flex justify-center items-center overflow-hidden flex-shrink-0">
					<img src={`${api_route}/images/${student?.picture ? student.picture : "icon-100.png"}`} className="object-fill" />
				</div>
			)}
		</div>
	);
}

export default Header2;
