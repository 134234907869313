import React, { useState } from "react";
import { Link, Routes, Route } from "react-router-dom";
import Assignments from "./Assignments";
import Sessions from "./Sessions";
import Start from "./Start";
import UnderConstruction from "../../components/UnderConstruction";

function Elearning() {
	const [active_subpage, setActive_subpage] = useState("Home");
	const changeNav = (e) => {
		setActive_subpage(e.target.innerText);
	};
	return <UnderConstruction />;
	return (
		<div>
			<div className="border-b border-gray-200">
				<div className="mx-auto max-w-[1300px] px-4 pt-3 flex flex-col">
					<p className="font-semibold pb-2">
						Welcome to your E-learning module
					</p>
					<nav className="text-sm flex">
						<div
							className={`pb-1 border-b-2 ${
								active_subpage == "Get Started"
									? "border-[#f60]"
									: "border-transparent"
							} mx-3 px-1`}
						>
							<Link to="start" onClick={changeNav}>
								Get Started
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Sessions"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="sessions" onClick={changeNav}>
								Sessions
							</Link>
						</div>
						<div
							className={`pb-1 border-b-2 mx-3 px-1 ${
								active_subpage == "Assignments / Exams"
									? "border-[#f60]"
									: "border-transparent"
							}`}
						>
							<Link to="assignments" onClick={changeNav}>
								Assignments / Exams
							</Link>
						</div>
					</nav>
				</div>
			</div>
			<div className="overflow-y-auto">
				<Routes>
					<Route path="start" element={<Start />} />
					<Route path="sessions" element={<Sessions />} />
					<Route path="assignments" element={<Assignments />} />
				</Routes>
			</div>
		</div>
	);
}

export default Elearning;
