import React, { useContext, useState } from "react";
import ReportWatermark from "../ReportWatermark";
import Header2 from "../../ReportHeaders/Header2";
import MainFooter from "../../ReportFooters/MainFooter";
import { GlobalContext } from "../../../../../GlobalContext";
import GradintTable from "../../ReportFooters/GradingTable";
import SecondaryHeader from "../../ReportHeaders/SecondaryHeader";
import { removeEmptyRecords } from "../../../../../functions/helpers";
import { getDescriptor, getIdentifier } from "../../../../../components/vanilla/Helpers";

function NewCurriculum({ data }) {
	removeEmptyRecords();
	const students = data?.students;
	const term = data?.term;
	const subjects = data?.subjects;
	const initials = data?.initials;
	const grading = data?.grading;
	const assessments = data?.assessments;
	const cls = data?.class;
	let totalGrades = 0;
	let totalScores = 0;
	let allGrades = [];

	console.log("This is my grading ", grading);
	const { schoolData, api_route } = useContext(GlobalContext);
	// const [allGrades, setAllGrades] = useState([]);
	// const [totalGrades, setTotalGrades] = useState(0);

	const getScore = (subjectId) => {
		// return new Promise((resolve) => {
		// 	resolve("Finale");
		// 	return;
		// });
		return "x";
	};

	return students.map((student) => {
		let identifiers = [];
		let ave_identifier = 0;
		let averages = [];
		let total_averages = 0;

		let descriptor = "";
		let grade = "";
		return (
			<div className={`bg-white max-w-[800px] w-full mx-auto student-rp-cd-${student.studentId}`} key={student.studentId}>
				<div className="h-[1072px] m-[30px] border-[2px] border-transparent bg-white relative rounded-[5px] overflow-hidden flex flex-col">
					<ReportWatermark about={schoolData} api_route={api_route} />
					<div className="relative z-10">
						<div className="border-b-[1px] border-transparent rounded-[10px] bg-[#f5f5f5]">
							<SecondaryHeader about={schoolData} api_route={api_route} student={student} />
						</div>
						<div className="border-b-[1px] border-[#dddddd] border-dashed p-[15px]">
							<div className="flex items-center">
								<p className="px-2 flex-grow">
									<span className="font-bold">NAME:</span> {student.fullName}
								</p>
								<p className="px-2 w-[100px]">
									<span className="font-bold">CLASS:</span> {cls.alias}
								</p>
								<p className="px-2 w-[70px]">
									<span className="font-bold">TERM:</span> {term.term}
								</p>
								<p className="px-2 w-[100px]">
									<span className="font-bold">YEAR:</span> {term.year}
								</p>
								<p className="px-2 w-[100px] hidden">
									<span className="font-bold">STREAM:</span> BRIGHT
								</p>
							</div>
						</div>
						<p className="py-2 text-center font-bold text-[12px] text-[#fff] bg-[#1a2e3a] my-3">
							<u>
								TERM {term.term}, {term.year}
							</u>{" "}
							STUDENT'S PERFORMANCE REPORT CARD.
						</p>
					</div>
					<div className="z-10">
						<div className="mx-0 overflow-hidden">
							<table cellSpacing={0} className="pad6 w-full text-[11px] font-semibold report-table-black">
								<thead>
									<tr className="text-left thead" style={{ background: "#555", color: "#fff" }}>
										<th>SUBJECTS</th>
										{assessments.map((assessment, index) => (
											<th className="text-center p-3" key={`assessment-${index}`}>
												{assessment.title}
											</th>
										))}
										<th className="text-center p-3 bg-[#5cbcf91a]">AVE</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">IDENTIFIER</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">20%</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">80%</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">TOTAL</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">GRADE</th>
										<th className="text-center w-[60px] p-3 bg-[#5cbcf91a]">DESCRIPTOR</th>
										<th className="w-[50px] p-3 bg-[#5cbcf91a]">TR</th>
									</tr>
								</thead>
								<tbody>
									{subjects.map((subject) => {
										// const score = await getScore(subject.id);
										let subject_teacher = null;
										if (subject.teacher_subjects && subject.teacher_subjects?.length > 0) subject_teacher = subject.teacher_subjects[0].teacher;

										let total_score = 0;
										let ave = 0;
										let _20 = 0;
										let _80 = 0;
										let _100 = 0;
										let identifier = 0;

										descriptor = "";
										grade = "";

										{
											assessments.map((assessment, index) => {
												const scores = student.assessments[index].scores;
												const ss = scores.filter((aa) => {
													if (aa.subjectId == subject.id) {
														console.log("The score = ", aa.score, assessments.length, total_score);
														total_score += aa.score ? parseFloat(aa.score.toFixed(2)) : 0;

														// console.log(aa.subjectId, "==", subject.id);
														if (aa.score == null) return { ...aa, score: "-" };
														else return aa;
													}
												});
												// console.log("ss =", ss);

												ave = total_score > 0 ? (total_score / assessments.length).toFixed(2) : 0;
												_20 = parseInt(((ave / 3) * 20).toFixed(0));
												_80 = parseInt(((ave / 3) * 80).toFixed(0));
												_100 = _20 + _80;

												// descriptor
												if (ave <= 1.4) identifier = 1;
												else if (ave >= 1.5 && ave <= 2.4) identifier = 2;
												else identifier = 3;

												if (ave > 0) {
													averages.push(ave);
													total_averages += parseFloat(ave);
												}
											});
										}
										// console.log("Averages =", averages.length);

										total_score = 0;
										let score_array = [];

										return (
											<tr key={student.studentId + "-" + subject.id} className="text-[#000] text-[10px] sb-row">
												<th align="left">{subject.subject.length > 13 ? subject.alias : subject.subject}</th>
												{assessments.map((assessment, index) => {
													const scores = student.assessments[index].scores;
													// let ss = null;
													// for (let i = 0; i < scores.length; i++) {
													// 	const aa = scores[i];
													// 	const inex = i;
													// 	if (aa.subjectId == subject.id) {
													// 		score_array.push(parseInt(aa.score.toFixed(2)));

													// 		total_score += parseInt(aa.score.toFixed(2));
													// 		console.log("aa =", aa);

													// 		console.log("The score = ", aa.score, assessments.length, total_score);
													// 		// console.log(aa.subjectId, "==", subject.id);
													// 		ss = aa;
													// 	}
													// }
													const ss = scores.filter((aa, index) => {
														if (aa.subjectId == subject.id) {
															score_array.push(aa.score ? parseFloat(aa.score.toFixed(2)) : 0);

															total_score += aa.score ? parseFloat(aa.score.toFixed(2)) : 0;
															console.log("aa =", aa.score);

															console.log("The score = ", aa.score, assessments.length, total_score);
															console.log("Score Array ", score_array);

															// console.log(aa.subjectId, "==", subject.id);
															if (aa.score == null) return { ...aa, score: "-" };
															else return aa;
														}
													});

													ave = total_score > 0 ? (total_score / assessments.length).toFixed(2) : 0;
													_20 = parseInt(((ave / 3) * 20).toFixed(0));
													_80 = parseInt(((ave / 3) * 80).toFixed(0));
													_100 = _20 + _80;

													// console.log("ss =", ss, "ave =", ave, "total score =", total_score);

													// if (ave > 0) identifiers.push(identifier);

													// grading
													if (_100 >= 0 && _100 <= 29) grade = "G";
													else if (_100 >= 30 && _100 <= 39) grade = "F";
													else if (_100 >= 40 && _100 <= 49) grade = "E";
													else if (_100 >= 50 && _100 <= 59) grade = "D";
													else if (_100 >= 60 && _100 <= 69) grade = "C";
													else if (_100 >= 70 && _100 <= 79) grade = "B";
													else if (_100 >= 80 && _100 <= 89) grade = "A";
													else grade = "A+";

													// descriptor
													// if (identifier < 2) descriptor = "Basic";
													// if (identifier == 2) descriptor = "Moderate";
													// else descriptor = "Outstanding";

													return ss ? <th className="text-center p-3">{ss[0]?.score}</th> : <td className="text-center p-3" key={`assessment-${index}`}></td>;
												})}
												<th className="text-center p-3 bg-[#009dff2d] text-[#06472c]">{ave}</th>
												<th className="w-[60px] p-3 bg-[#009dff2d] text-[#06472c]">{getIdentifier(ave)}</th>
												<th className="text-center w-[60px] p-3 bg-[#009dff2d] text-[#06472c]">{_20}</th>
												<th className="text-center w-[60px] p-3 bg-[#009dff2d] text-[#06472c]">{_80}</th>
												<th className="text-center w-[60px] p-3 bg-[#009dff2d] text-[#06472c]">{_20 + _80}</th>
												<th className="text-center w-[60px] p-3 bg-[#009dff2d] text-[#06472c]">{grade}</th>
												<th align="left" className=" w-[60px] p-3  bg-[#009dff2d]">
													{getDescriptor(ave)}
												</th>
												<th className="initials w-[50px] p-3 bg-[#009dff2d] text-[#f00]">
													{ave > 0 ? (subject_teacher ? `${subject_teacher.fname.substring(0, 1).toUpperCase()}${subject_teacher.lname.substring(0, 1).toUpperCase()}` : "-") : ""}
												</th>
											</tr>
										);
									})}
									<tr>
										<th colSpan={1 + assessments.length}>OVERALL IDENTIFIER</th>
										<th className="overall_identifier">{(parseFloat(total_averages) / averages.length).toFixed(2)}</th>
										<th></th>
										<th colSpan={4}>OVERALL ACHIEVMENT</th>
										<th align="left">{getDescriptor((parseFloat(total_averages) / averages.length).toFixed(2))}</th>
										<th></th>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="flex items-center">
							<p className="pt-5 text-[14px] font-bold">GRADING SCALE</p>
							<p className="pt-5 text-[11px] font-bold text-right flex-grow text-[#329d64] exams_attempted"></p>
						</div>

						<table className="pad6 w-full text-[11px] px-2 black font-semibold">
							<tbody>
								<tr>
									<th>Grid</th>
									<th colSpan={3}>Formative Assessment</th>
									<th colSpan={8}>Summative Assessment</th>
								</tr>
								<tr>
									<th>Range</th>
									<th>0-9-1.4</th>
									<th>1.5-2.4</th>
									<th>2.5-3.0</th>
									<th>0-29</th>
									<th>30-39</th>
									<th>40-49</th>
									<th>50-59</th>
									<th>60-69</th>
									<th>70-79</th>
									<th>80-89</th>
									<th>90-100</th>
								</tr>
								<tr>
									<th>Indentifier</th>
									<th>1</th>
									<th>2</th>
									<th>3</th>
									<th>G</th>
									<th>F</th>
									<th>E</th>
									<th>D</th>
									<th>C</th>
									<th>B</th>
									<th>A</th>
									<th>A+</th>
								</tr>
							</tbody>
						</table>

						<table cellSpacing={0} className="pad6 w-full text-[11px] px-2 black font-semibold mt-3">
							<tbody>
								<tr>
									<th align="left">This Term Ends:</th>
									<th align="left" className="w-[120px]"></th>
									<th align="left">Next Term Begins:</th>
									<th align="left" className="w-[120px]"></th>
									<th align="left">Fees Balance:</th>
									<th align="left" className="w-[120px]"></th>
								</tr>
							</tbody>
						</table>
						<p className="text-center text-[#067857] text-2xl py-4 font-bold promotion_status promoted hidden">PROMOTED TO S.2</p>
						<p className="text-center text-[#780606] text-2xl py-4 font-bold promotion_status repeat hidden">PROMOTED TO S.2</p>
					</div>
					<div className="z-10 border-t-[1px] border-[#ddd] bg-[#fbfbfb] flex flex-col">
						<MainFooter cls={data.class} student={student} identifier={(parseFloat(total_averages) / averages.length).toFixed(2)} />
						<p className="text-center text-[#999] pb-2 pt-1 italic">This report card should carry a stamp.</p>
					</div>
				</div>
			</div>
		);
	});
}

export default NewCurriculum;
