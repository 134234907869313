import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function NewScheme() {
	const regScheme = (event) => {
		event.preventDefault();
		alert();
	};
	return (
		<div className="fixed top-0 left-0 bg-[#0009] w-full h-full flex jic">
			<form onSubmit={regScheme} className="bg-[#fff] w-full max-h-[80%] max-w-[400px] relative rounded-[10px] flex flex-col">
				<Link to={"../"} className="absolute top-[-10px] right-[-10px] bg-[#ffb9b9] w-6 h-6 flex jic rounded-full">
					<FA icon={faTimes} color="#f00" />
				</Link>
				<p className="text-center font-bold py-3 text-lg">Scheme Entry</p>
				<label className="flex flex-col px-4 mb-2">
					<p className="font-bold">Scheme title</p>
					<input placeholder="e.g Government sponsored" name="title" className="border-[1px] bg-[#f1f1f1] py-2 px-3 rounded-[5px] mt-2" />
				</label>
				<label className="flex flex-col px-4 mb-2 mt-2">
					<p className="font-bold">Amount for the above scheme</p>
					<input placeholder="e.g Government sponsored" name="amount" className="border-[1px] bg-[#f1f1f1] py-2 px-3 rounded-[5px] mt-2" />
				</label>
				<button className="bg-[#000] text-[#fff] py-3 m-3 rounded-[8px]">SAVE</button>
			</form>
		</div>
	);
}

export default NewScheme;
