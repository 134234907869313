import { CalendarIcon, CogIcon } from "@heroicons/react/outline";
import React from "react";

function UnderConstruction() {
	return (
		<div className="bg-gray-200 h-full flex flex-col justify-center items-center">
			<img className="w-[40%] opacity-5 absolute" src="/logo512.png" />
			<CogIcon className="w-16 mb-3" />
			<p className="text-2xl font-semibold text-green-600">Coming soon ...</p>
			<p className="font-semibold text-gray-600">This feature is still under Development.</p>
		</div>
	);
}

export default UnderConstruction;
